import { IFilterParams } from '../filter-params';
import { Currency } from '../pay-rate-types';
import { IApplicationRow } from './application-entity';
import { IExternalStaffingCandidateBidRow } from './external-staffing-candidate-bid-entity';
import { IListingIdentifierStatsEntity } from './listing-identifier-stats-entity';
import { IProfessionEntity } from './profession-entity';
import { IProfessionSpecialtyEntity } from './profession-specialty-entity';
import { ISubSpecialtyEntity } from './sub-specialty-entity';
import { ITag } from './tag-entity';

export interface IPrematched {
  readonly prematchProfiles: IPreMatchProfile[];
  readonly propagatePrematch: boolean;
}

export interface IBaseJobListingEntity<T extends Date | string = Date>
  extends Partial<IPrematched> {
  readonly startTime: T;
  readonly endTime: T;
}

export interface IJobListingBookingStats {
  readonly listing: number;
  readonly bookingCount: number;
  readonly applicationCount: number;
  readonly externalStaffingCandidateBidCount: number;
  readonly totalCandidateCount: number;
}

export interface IJobListingWithBookingStats extends IJobListingEntity {
  readonly bookingStats: IJobListingBookingStats;
}
export interface IJobListingEntity<
  T extends string | Date = Date,
  S extends ISubSpecialtyEntity | number = number,
  P extends IProfessionEntity | number = number,
  PS extends IProfessionSpecialtyEntity<S, P> | number = number,
> extends IBaseJobListingEntity<T> {
  readonly id?: number;
  readonly title: string;
  readonly applicationDeadline: T;
  readonly listingType: number;
  readonly professionSpecialty: PS;
  readonly site: number;
  readonly ward?: number;
  readonly roster?: number;
  readonly details: string;
  readonly detailsChange: boolean;
  readonly costCentreNumber?: string; // optional for initial
  readonly costCentreNumberChange: boolean;
  readonly reasonForVacancy: number;
  readonly reasonForVacancyChange: boolean;
  readonly availablePositions: number;
  readonly grades: IJobListingGradeEntity[];
  readonly extraEmails: IExtraEmailEntity[];
  readonly files: IJobListingFileEntity[];
  readonly extendedHours: boolean;
  readonly pensionCategory: number;
  readonly repetitionDates?: string[];
  readonly adhoc?: boolean;
  readonly published?: boolean;
  readonly remainingPositionsToFill: number;
  readonly externalJobListingId?: number;
  readonly outOfSync?: boolean;
  readonly staffingCascade?: number;
  readonly rateViolationReason?: number;
  readonly ratesLocked?: boolean;
  readonly shiftEscalated: boolean;
  readonly spiScore?: number;
  readonly createdDate?: Date;
  readonly shiftEscalatedForAgencies: boolean;
  readonly note?: string;
  readonly copyNoteAcrossRepetitionDate?: boolean;
  readonly publishingOfficer?: number;
  readonly crossCoveringProfessionSpecialties: number[];
  readonly nonResidentOnCall?: boolean;
  readonly standardRatesShift?: boolean;
  readonly listingGroupIdentifier?: string;
}

export interface IJobListingEntityWithRateDefinitions<
  T extends string | Date = Date,
  S extends ISubSpecialtyEntity | number = number,
  P extends IProfessionEntity | number = number,
  PS extends IProfessionSpecialtyEntity<S, P> | number = number,
> extends IJobListingEntity<T, S, P, PS> {
  readonly grades: IJobListingGradeEntity<IJobFragmentRatesEntity>[];
}

export interface ITimeEntity {
  readonly id?: number;
  readonly startTime: string;
  readonly endTime: string;
}
export interface IJobListingGradeEntity<T extends IJobFragmentEntity = IJobFragmentEntity> {
  readonly id?: number;
  readonly grade: number;
  readonly flatRate: IFlatRateEntity;
  readonly jobFragments: T[];
}

export interface IJobFragmentEntity<T extends ITimeFragmentEntity = ITimeFragmentEntity> {
  readonly id?: number;
  readonly timeFragment: T;
  readonly payRate: IPayRateEntity;
}
export interface IJobFragmentRatesEntity
  extends IJobFragmentEntity<ITimeFragmentEntityWithRateDefinitions> {
  agencyFee?: string;
  nonResidentCalloutAgencyFee?: string;
  flatRate?: number;
  approvedRate: number | null;
}
export interface IPayRateEntity {
  readonly id?: number;
  readonly rate: string;
  readonly rateCurrency: Currency;
  readonly nonResidentCalloutRate: string | null;
  readonly nonResidentCalloutRateCurrency: Currency | null;
  readonly payRateType: number;
}

export interface ITimeFragmentEntity {
  readonly id?: number | string;
  readonly fromTime: Date;
  readonly toTime: Date;
}
export interface IIdentifiedTimeFragmentEntity extends ITimeFragmentEntity {
  readonly id: number | string;
}
export interface ITimeFragmentEntityWithRateDefinitions extends ITimeFragmentEntity {
  readonly rateDefinition: number | null;
}

export interface IFlatRateEntity {
  readonly id?: number;
  readonly rate: string | number;
  readonly rateCurrency: Currency;
}

export interface IExtraEmailEntity {
  readonly id?: number;
  readonly name: string;
  readonly extraEmail: string;
}

export interface IJobListingFileEntity {
  readonly id?: number;
  readonly title: string;
  readonly file: string;
}

export type StaffingCascadeStatusCode = 'AUTHORIZED' | 'REJECTED' | 'PENDING' | 'CANCELED';

export interface IJobListingRow {
  readonly id: number;
  readonly startTime: Date;
  readonly endTime: Date;
  readonly title: string;
  readonly specialty: string;
  readonly grade: string;
  readonly positionsCount: number;
  readonly applicationsCount: number;
  readonly remainingPositionsToFill: number;
  readonly selected: boolean;
  readonly expanded: boolean;
  readonly staffingCascade: number;
  readonly ratesLocked: boolean;
  readonly cascadeNotes?: string;
  readonly tierNo?: number;
  readonly staffingCascadeStatusCode?: StaffingCascadeStatusCode;
  readonly externalJobListingId?: number;
  readonly outOfSync?: boolean;
  readonly shiftEscalated: boolean;
  readonly spiScore: number;
  readonly shiftEscalatedForAgencies: boolean;
  readonly employmentPeriodInPast: boolean;
  readonly tags: ITag[];
  readonly blockDetails: IListingIdentifierStatsEntity | null;
  readonly profession: string;
  readonly hospital: string;
  readonly site: string;
}

export interface IJobListingListing {
  readonly jobListingRows: IJobListingRow[];
  readonly totalCount: number;
  readonly totalPages: number;
}

export interface IJobListingParams extends IFilterParams {
  search?: string;
  start_time__gte?: string;
  end_time__lt?: string;
  adhoc?: string;
  grades__grade?: number;
  applications__status?: number;
  specialty__category?: number;
  published?: {};
  has_pending_applications?: boolean;
  cascaded?: {};
  job_status?: {};
  type?: string;
  id?: number[];
  ordering?: string;
  listing_type?: number;
  rates_locked?: {};
  shift_escalated?: {};
}

export interface IJobListingSubmitButton {
  id: number;
  text: string;
  type: 'back' | 'accept' | 'decline';
  code: string;
}

export interface IPreMatchProfile {
  id: number;
  userId: number;
  profile: string;
  registrationNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  left: boolean;
  invitationMessage?: string;
}

export interface IJobListingRowPayload {
  listing: number;
  staffingCascadeId: number;
}

export type IJobListingDetailRow = IApplicationRow | IExternalStaffingCandidateBidRow;

export enum IRowEnum {
  APPLICATION = 'APPLICATION',
  BID = 'BID',
}

export interface IGradeDetails {
  title: string;
  details: string[];
  totalPayout?: string;
  totalPayoutBreakdown?: string;
  totalAgency?: string;
  totalCandidate?: string;
}
export interface IBaseNote {
  readonly noteText: string;
}
export interface INote extends IBaseNote {
  readonly noteAuthorFirstName: string;
  readonly noteAuthorLastName: string;
  readonly noteAuthorEmail: string;
  readonly createdBy: number;
}
export interface IBaseJobListingNote<N = INote> {
  readonly note: N;
  readonly jobListing: number;
}

export interface IJobListingNote<N = INote> extends IBaseJobListingNote<N> {
  readonly id: number;
  readonly createdAt: string;
  readonly lastUpdate: string;
}
export type JobListingNotePostPayload = IBaseJobListingNote<IBaseNote>;

export type BulkUnpublishJobListingPayload =
  | IBaseJobListingNote<IBaseNote>
  | Omit<IBaseJobListingNote<IBaseNote>, 'note'>;

export interface ViolationRateWarnings {
  rate: number;
  fromTime: Date;
  toTime: Date;
  calloutWarning: boolean;
  flatRate: boolean;
}

export interface IOriginalJobListing {
  readonly listing: number;
  readonly title: string;
  readonly hospitalName: string;
  readonly hospitalId: number;
  readonly description: string;
  readonly startDate: string;
  readonly startTime: string;
  readonly endDate: string;
  readonly endTime: string;
  readonly department: string;
  readonly gradesDetails: IGradeDetails[];
  readonly gradesTitle: string;
  readonly agencyEscalated: boolean;
  readonly profession: string;
  readonly site: string;
}
