/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { Injectable } from '@angular/core';
import { createFeatureSelector, Store } from '@ngrx/store';

import { createWizardAdapter, IWizardState } from '@locumsnest/components/src';
import { IBaseWizardService } from '@locumsnest/components/src/lib/molecules/card-wizard/+state/adapter';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core';

export const messageableFactory =
  MessageableFactory.forFeature<'JobListing Wizard'>('JobListing Wizard');
export const signalableFactory =
  SignalableFactory.forFeature<'JobListing Wizard'>('JobListing Wizard');
export const wizardAdapter = createWizardAdapter(signalableFactory, messageableFactory);
export const reducer = wizardAdapter.createReducer();
export const selectJobListingWizardState =
  createFeatureSelector<IWizardState>('jobListings.wizard');
export const selectors = wizardAdapter.getSelectors(selectJobListingWizardState);

export interface JobListingWizardService extends IBaseWizardService<'JobListing Wizard'> {}
@Injectable({
  providedIn: 'root',
})
@wizardAdapter.wizardService(selectJobListingWizardState)
export class JobListingWizardService {
  constructor(public store: Store) {}
}
