import { TabId } from '@locumsnest/components/src';
import { IArrowDirectionsEnum } from '@locumsnest/core/src';

import { IJobListingRowPayload } from '../../../interfaces/api/job-listing-entity';
import { signalableFactory as actionableFactory } from './search-filter-form.adapter';

export class InitializeJobListingSearchFilterFormSignal extends actionableFactory.create<
  'Initialize Job Listing Search Filter Form',
  { namespace: string; page: string }
>('Initialize Job Listing Search Filter Form') {}

export class InitializeCascadedJobListingSearchFilterFormSignal extends actionableFactory.create<
  'Initialize Bid Job Listing Search Filter Form',
  { namespace: string; page: string }
>('Initialize Bid Job Listing Search Filter Form') {}
export class UpdateSelectedPageUsingArrowsSignal extends actionableFactory.create<
  'Update Selected Page Using Arrows Signal',
  { direction: IArrowDirectionsEnum; namespace: string }
>('Update Selected Page Using Arrows Signal') {}

export class AddSelectedJobListingSignal extends actionableFactory.create<
  'Add Selected Job Listing Signal',
  { id: number }
>('Add Selected Job Listing Signal') {}

export class RemoveSelectedJobListingSignal extends actionableFactory.create<
  'Remove Selected Job Listing Signal',
  { id: number }
>('Remove Selected Job Listing Signal') {}

export class SelectAllInCurrentPageSignal extends actionableFactory.create<
  'Select All In Current Page Signal',
  { namespace: string }
>('Select All In Current Page Signal') {}

export class UnselectAllInCurrentPageSignal extends actionableFactory.create<
  'Unselect All In Current Page Signal',
  { namespace: string }
>('Unselect All In Current Page Signal') {}

export class SubmitSearchFilterFormSignal extends actionableFactory.create<
  'Submit Search Filter Form Signal',
  { namespace: string }
>('Submit Search Filter Form Signal') {}

export class ExportJobListingListSignal extends actionableFactory.create<
  'Export Job Listing List Signal',
  Record<string, never>
>('Export Job Listing List Signal') {}

export class SubmitForAuthorisationJobListingListSignal extends actionableFactory.create<
  'Submit For Authorization Job Listing List Signal',
  Record<string, never>
>('Submit For Authorization Job Listing List Signal') {}

export class AuthorizeCascadeJobListingListSignal extends actionableFactory.create<
  'Authorize Cascade Job Listing List Signal',
  { namespace: string }
>('Authorize Cascade Job Listing List Signal') {}

export class RejectCascadeJobListingListSignal extends actionableFactory.create<
  'Reject Cascade Job Listing List Signal',
  { namespace: string }
>('Reject Cascade Job Listing List Signal') {}

export class ReverseCascadeJobListingListSignal extends actionableFactory.create<
  'Reverse Cascade Job Listing List Signal',
  { namespace: string }
>('Reverse Cascade Job Listing List Signal') {}

export class BulkCascadeJobListingListSignal extends actionableFactory.create<
  'Bulk Cascade Job Listing List Signal',
  { namespace: string }
>('Bulk Cascade Job Listing List Signal') {}

export class BulkAddNoteSignal extends actionableFactory.create<
  'Bulk Add Note',
  Record<string, never>
>('Bulk Add Note') {}

export class PublishJobListingListSignal extends actionableFactory.create<
  'Publish Job Listing List Signal',
  { namespace: string }
>('Publish Job Listing List Signal') {}

export class UnPublishJobListingListSignal extends actionableFactory.create<
  'UnPublish Job Listing List Signal',
  { namespace: string }
>('UnPublish Job Listing List Signal') {}

export class EscalateJobListingListSignal extends actionableFactory.create<
  'Escalate Job Listing List Signal',
  { namespace: string; forAgencies: boolean }
>('Escalate Job Listing List Signal') {}

export class RemoveEscalationJobListingListSignal extends actionableFactory.create<
  'Remove Escalation Job Listing List Signal',
  { namespace: string; forAgencies: boolean }
>('Remove Escalation Job Listing List Signal') {}
export class UpdateSelectedPageUsingInputSignal extends actionableFactory.create<
  'Update Selected Page Using Input Signal',
  { newSelectedPage: number; namespace: string }
>('Update Selected Page Using Input Signal') {}

export class AddRemoveExpandedJobListingSignal extends actionableFactory.create<
  'Add Remove Expanded Job Listing Signal',
  { jobListingRowPayload: IJobListingRowPayload; agencyPlatform?: boolean }
>('Add Remove Expanded Job Listing Signal') {}

export class UpdateSortedFieldSignal extends actionableFactory.create<
  'Update Sorted Field Signal',
  { columnName: string; namespace: string }
>('Update Sorted Field Signal') {}

export class ResetQuickActionsFormSignal extends actionableFactory.create<
  'Reset Quick Actions Form',
  Record<string, never>
>('Reset Quick Actions Form') {}

export class LockShiftJobListingListSignal extends actionableFactory.create<
  'Lock Shift Job Listing List Signal',
  { namespace: string }
>('Lock Shift Job Listing List Signal') {}

export class UnLockShiftJobListingListSignal extends actionableFactory.create<
  'Unlock Shift Job Listing List Signal',
  { namespace: string }
>('Unlock Shift Job Listing List Signal') {}

export class AddActiveTabSignal extends actionableFactory.create<
  'Add Active Tab',
  { page: string }
>('Add Active Tab') {}

export class AddHomeTabSignal extends actionableFactory.create<
  'Add Home Tab',
  { namespace: string; page: string }
>('Add Home Tab') {}

export class UpdateTabNameSignal extends actionableFactory.create<
  'Update Tab Name',
  { id: TabId; name: string }
>('Update Tab Name') {}

export class SaveActiveTabFiltersSignal extends actionableFactory.create<
  'Save Active Tab Filters',
  { page: string }
>('Save Active Tab Filters') {}

export class UpdateActiveTabSignal extends actionableFactory.create<
  'Update Active Tab',
  { id: TabId; namespace: string }
>('Update Active Tab') {}

export class RemoveTabSignal extends actionableFactory.create<
  'Remove Tab',
  { id: TabId; namespace: string }
>('Remove Tab') {}

export class RevertToDefaultsHomeFiltersSignal extends actionableFactory.create<
  'Revert To Defaults Home Filters',
  { namespace: string; page: string }
>('Revert To Defaults Home Filters') {}

export class UpdateListingGroupIdentifierSignal extends actionableFactory.create<
  'Update Listing Group Identifier Signal',
  { namespace: string; listingGroupIdentifier: string }
>('Update Listing Group Identifier Signal') {}
