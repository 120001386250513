import { UserDefinedPropertyConstants } from '@locumsnest/core/src/lib/ngrx';

import { IJobListingEntityWithRateDefinitions } from '../../../interfaces/api/job-listing-entity';

export interface IJobListingFormUserDefinedProperties {
  useRateCardRates: boolean;
  rateEditingDisabled: boolean;
  rateCardRateListings: IJobListingEntityWithRateDefinitions<Date, number, number, number>[];
  rateCardRateGrades: Set<number>;
  gradesWithDifferentBreakDownExist: boolean;
  rateCardRatesRecalculated: boolean;
}
export const FORM_UDP: UserDefinedPropertyConstants<IJobListingFormUserDefinedProperties> = {
  useRateCardRates: 'useRateCardRates',
  rateEditingDisabled: 'rateEditingDisabled',
  rateCardRateListings: 'rateCardRateListings',
  rateCardRateGrades: 'rateCardRateGrades',
  gradesWithDifferentBreakDownExist: 'gradesWithDifferentBreakDownExist',
  rateCardRatesRecalculated: 'rateCardRatesRecalculated',
};
