import { inject, Injectable } from '@angular/core';
import { EMPTY, map, switchMap } from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core/src';

import { IListingIdentifierStatsEntity } from './interfaces';
import {
  paginationMessages,
  ResetListingIdentifierStatsPaginationMessage,
  UpsertListingIdentifierStatsPageMessage,
  UpsertMultipleMessage,
} from './listing-identifier-stats.messages';
import { ListingIdentifierStatsPersistenceService } from './listing-identifier-stats.persistence.service';
import {
  listingIdentifierStatsPaginationSelectors,
  selectListingIdentifierStatsEntityState,
} from './listing-identifier-stats.selectors';

@Injectable({
  providedIn: 'root',
})
export class ListingIdentifierStatsService extends PaginatedStateService<
  IListingIdentifierStatsEntity,
  UpsertListingIdentifierStatsPageMessage,
  ResetListingIdentifierStatsPaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(ListingIdentifierStatsPersistenceService);

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return listingIdentifierStatsPaginationSelectors;
  }

  get entityStateSelector() {
    return selectListingIdentifierStatsEntityState;
  }

  loadManyIfNotExists(ids: string[]) {
    return this.getEntityDict().pipe(
      switchMap((entities) => {
        const uniqueIds = [...new Set(ids)];
        const idsToLoad: string[] = [];
        uniqueIds.forEach((id) => {
          if (!entities[id]) idsToLoad.push(id);
        });

        if (idsToLoad.length) return this.fetch(idsToLoad);
        return EMPTY;
      }),
    );
  }

  fetch(ids: string[]) {
    return this.persistenceService
      .retrieve({ groupIdentifier: ids })
      .pipe(map(({ results: entities }) => new UpsertMultipleMessage({ entities })));
  }
}
