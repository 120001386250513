/* eslint-disable no-useless-escape */

import { Actions, concatLatestFrom, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { flatMap, get, isEmpty, isNil, maxBy, minBy } from 'lodash-es';
import {
  AddArrayControlAction,
  box,
  FormGroupState,
  RemoveArrayControlAction,
  SetUserDefinedPropertyAction,
  SetValueAction,
} from 'ngrx-forms';
import {
  catchError,
  combineLatest,
  concat,
  distinctUntilChanged,
  EMPTY,
  exhaustMap,
  filter,
  first,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  pipe,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { v4 as uuid } from 'uuid';

import { SelectOption } from '@locumsnest/components/src/lib/interfaces';
import { IQueryParams, ofSignalType } from '@locumsnest/core/src';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import { BaseEffects } from '@locumsnest/core/src/lib/ngrx/effect';
import { parseArrayControlId } from '@locumsnest/core/src/lib/ngrx/helpers/ngrx-forms/util';
import { DATE_TIME_PICKER_FORMAT, URL_DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';
import { HospitalProfessionConfigurationService } from '@locumsnest/hospital-profession-configuration/src';
import { ListingConversationService } from '@locumsnest/listing-conversation/src';
import { TagService } from '@locumsnest/tag/src';
import { ClearSelectedTagsMessage } from '@locumsnest/tag/src/lib/+state/tag-form/tag-form.messages';

import { DateTime } from '../../../../../../../libs/core/src/lib/helpers/date-time';
import { ApplicationStatusService } from '../../../application-status/+state/application-status.service';
import { ApplicationService } from '../../../application/+state/application.service';
import { InitializeApplicationListStateMessage } from '../../../application/+state/list-state';
import { ApprovedRateService } from '../../../approved-rate/+state/approved-rate.service';
import { BankHolidayService } from '../../../bank-holiday/+state/bank-holiday.service';
import { BreakTimeService } from '../../../break-time/+state/break-time.service';
import { PRODUCT_CODES, SECTOR_CODES } from '../../../core/constants';
import { ConfigurationService, RouterService } from '../../../core/services';
import { CostCentreNumberService } from '../../../cost-centre-number/+state/cost-centre-number.service';
import { DeclineApplicationReasonService } from '../../../decline-application-reason/+state/decline-application-reason.service';
import { ExternalJobListingService } from '../../../external-job-listing/+state/external-job-listing.service';
import { ExternalStaffingCandidateBidStatusService } from '../../../external-staffing-candidate-bid-status/+state/external-staffing-candidate-bid-status.service';
import { ExternalStaffingCandidateBidService } from '../../../external-staffing-candidate-bid/+state/external-staffing-candidate-bid.service';
import { ExternalStaffingProviderTierService } from '../../../external-staffing-provider-tier/+state/external-staffing-provider-tier.service';
import { ExternalStaffingProviderService } from '../../../external-staffing-provider/+state/external-staffing-provider.service';
import { GradeRateService } from '../../../grade-rate/+state/grade-rate.service';
import { GradeService } from '../../../grade/+state/grade.service';
import { HospitalOfficerSiteService } from '../../../hospital-officer-site/+state/hospital-officer-site.service';
import { HospitalOfficerService } from '../../../hospital-officer/+state/hospital-officer.service';
import { HospitalVacancyReasonService } from '../../../hospital-vacancy-reason/+state/hospital-vacancy-reason.service';
import { selectAssignedHospitalSector } from '../../../hospital/+state/hospital.selectors';
import { HospitalService } from '../../../hospital/+state/hospital.services';
import { IExternalJobListingEntity } from '../../../interfaces/api/external-job-listing-entity';
import { IHospitalEntity } from '../../../interfaces/api/hospital-entity';
import {
  IJobListingEntity,
  IJobListingEntityWithRateDefinitions,
  IJobListingGradeEntity,
  IPreMatchProfile,
  ViolationRateWarnings,
} from '../../../interfaces/api/job-listing-entity';
import { IJobListingTemplateEntity } from '../../../interfaces/api/job-listing-template-entity';
import { IProfileEntity } from '../../../interfaces/api/profile-entity';
import { ISectorEntity } from '../../../interfaces/api/sector-entity';
import { IPayRateType } from '../../../interfaces/pay-rate-types';
import { InitializePreMatchFormMessage } from '../../../job-listing-conversation-profile/+state/pre-match-form/pre-match-form.messages';
import * as jobListingTemplateMessages from '../../../job-listing-templates/+state/job-listing-template.messages';
import { JobListingTemplatePersistenceService } from '../../../job-listing-templates/+state/job-listing-template.persistence.service';
import { JobListingTemplateService } from '../../../job-listing-templates/+state/job-listing-template.service';
import { PayRateTypeService } from '../../../pay-rate-type/+state/pay-rate-type.service';
import { PensionCategoryService } from '../../../pension-category/+state/pension-category.service';
import { ProfessionSpecialtyService } from '../../../profession-specialty/+state/profession-specialty.service';
import { ProfilePersistenceService } from '../../../profile/+state/profile.persistence.service';
import { ProfileService } from '../../../profile/+state/profile.service';
import { RateViolationReasonService } from '../../../rate-violation-reason/+state/rate-violation-reason.service';
import { RecentActivityShiftService } from '../../../recent-activity-shift/+state/recent-activity-shift.service';
import { SectorService } from '../../../sector/+state/sector.services';
import { ShiftMaxDurationService } from '../../../shift-max-duration/+state/shift-max-duration.service';
import { SitePersistenceService } from '../../../site/+state/site.persistence.service';
import { SiteService } from '../../../site/+state/site.service';
import { SpecialtyService } from '../../../specialty/+state/specialty.service';
import { StaffBankCollaborationGroupService } from '../../../staff-bank-collaboration-group/+state/staff-bank-collaboration-group.service';
import { StaffingCascadeStatusService } from '../../../staffing-cascade-status/+state/staffing-cascade-status.service';
import { StaffingCascadeTimeWindowService } from '../../../staffing-cascade-time-window/+state/staffing-cascade-time-window.service';
import { InitializeStaffingCascadeFormMessage } from '../../../staffing-cascade/+state/form/form.messages';
import { StaffingCascadeService } from '../../../staffing-cascade/+state/staffing-cascade.service';
import { TrustExternalStaffingProviderTierService } from '../../../trust-external-staffing-provider-tier/+state/trust-external-staffing-provider-tier.service';
import { VacancyReasonService } from '../../../vacancy-reason/+state/vacancy-reason.service';
import { WardService } from '../../../ward/+state/ward.service';
import {
  IJobListingExtendedGradeFormState,
  IJobListingFormWizardState,
  IPeriodFormState,
} from '../interfaces/job-listing-form-state';
import {
  AddMultipleMessage,
  AddOneMessage,
  SetSelectedMessage,
  UpdateOneMessage,
  UpsertOneMessage,
} from '../job-listing.actions';
import { JobListingPermissionService } from '../job-listing.permission.service';
import { JobListingPersistenceService } from '../job-listing.persistence.service';
import { JobListingService } from '../job-listing.service';
import {
  InitializeUiStateMessage,
  ListingNotFoundMessage,
  LoadingCompleteMessage,
  LoadingInProgressMessage,
  SetFormLoadedTemplateMessage,
  ShiftPublishedSuccessfullyMessage,
  SubmissionCompleteMessage,
  SubmissionInProgressMessage,
  UpdateShowShiftDetailsCardMessage,
} from '../ui/ui.messages';
import { JobListingWizardService } from '../wizard/wizard.adapter';
import { createJobListingFormMessages } from './form.messages.adapter';
import { SHIFT_SCHEDULER_FORM_ID } from './form.reducer.adapter';
import {
  getCurrentJobListingFormVersion,
  getDefaultApplicationDeadline,
  getEmploymentPeriod,
  getEntity,
  getEntityFlatRateEnabled,
  getEntityFormState,
  getEntityFormStateForTemplateJobListing,
  getHasFlatRate,
  getHasJobListingGrades,
  getHasTimeFragments,
  getJobListingGrade,
  getMaxTimeFragment,
  getMinTimeFragment,
  getTemplate,
  getTemplateFromEntity,
  getTimeFragments,
  getTimeFragmentsOutOfRange,
  transformJobListingTemplateBeforeLoad,
} from './form.selectors';
import { createJobListingFormSelectors } from './form.selectors.adapter';
import { createJobListingFormSignals } from './form.signals.adapter';
import { IJobListingAlertHandlers } from './interfaces';

export function createBaseEffects(
  selectors: ReturnType<typeof createJobListingFormSelectors>,
  signalMap: ReturnType<typeof createJobListingFormSignals>,
  messageMap: ReturnType<typeof createJobListingFormMessages>,
  FORM_ID: string,
  INITIAL_FORM_STATE: FormGroupState<IJobListingFormWizardState>,
  path,
  alertHandlers: IJobListingAlertHandlers,
) {
  const {
    InitializeJobListingFormMessage,
    ResetJobListingFormMessage,
    EnableJobListingGradeMessage,
    DisableJobListingGradeMessage,
    AddJobListingGradeMessage,
    ClearJobListingGradeMessage,
    SetDefaultApplicationDeadlineMessage,
    AddExtraEmailMessage,
    DeleteExtraEmailMessage,
    ClearExtraEmailsMessage,
    UpdateGradeJobFragmentsMessage,
    AddTimeFragmentMessage,
    DisableFlatRateMessage,
    EnableFlatRateMessage,
    RemoveTimeFragmentMessage,
    UpdateGradeFlatRatesMessage,
    ResetGradeJobFragmentsRatesMessage,
    RemoveFileMessage,
    UpdateTimeFragmentMessage,
    SetExtendedHoursMessage,
    ResetProfessionDetailsMessage,
    ResetCostCentreNumberMessage,
    DetailsChangeMessage,
    CostCentreNumberChangeMessage,
    ReasonForVacancyChangeMessage,
    SetCostCentreNumberMessage,
    ClearJobListingNotesMessage,
    UpdateJobFragmentRateMessage,
    SetRateCardRatesMessage,
  } = messageMap;
  const {
    InitializeJobListingFormSignal,
    LoadJobListingTemplateSignal,
    DeleteJobListingTemplateSignal,
    ToggleJobListingGradeSignal,
    ClearJobListingGradeSignal,
    AddCrossCoveringProfessionSpecialtySignal,
    RemoveCrossCoveringProfessionSpecialtySignal,
    ClearCrossCoveringProfessionSpecialtiesSignal,
    ResetGradeJobFragmentsRatesSignal,
    ResetCostCentreNumberSignal,
    AcceptApprovedRateViolationSignal,
    AddExtraEmailSignal,
    DeleteExtraEmailSignal,
    ClearExtraEmailsSignal,
    RemoveFileSignal,
    SetDefaultApplicationDeadlineSignal,
    ReplaceFlatRateSignal,
    AddTimeFragmentSignal,
    ReplaceTimeBasedRateSignal,
    RemoveTimeFragmentSignal,
    SubmitFormSignal,
    UpdateFormSignal,
    SaveFormAsTemplateSignal,
    UpdateExistingTemplateSignal,
    InitializeCascadeWidgetConfirmationSignal,
    ResetCascadeWidgetConfirmationSignal,
    PublishJobListingSignal,
    UnpublishJobListingSignal,
    DeclineAllAndUnpublishSignal,
    IgnoreWarningsAndMarkInSyncSignal,
    SubmitForAuthorizationJobListingSignal,
    CascadeSignal,
    InitializeReverseCascadeWidgetConfirmationSignal,
    ResetReverseCascadeWidgetConfirmationSignal,
    RevertCascadeSignal,
    DetailsChangeSignal,
    CostCentreNumberChangeSignal,
    ReasonForVacancyChangeSignal,
    SetCostCentreNumberSignal,
    PrefillCostCentreNumberSignal,
    LockShiftRatesSignal,
    UnlockShiftRatesSignal,
    ToggleRecentActivitySignal,
    AddListingNotesSignal,
    ChangeEmploymentPeriodSignal,
    InitializeRatesSignal,
  } = signalMap;

  const { alertHandler, jobListingTemplateAlertHandler, conditionalErrorHandler } = alertHandlers;

  abstract class JobListingFormBaseEffects extends BaseEffects {
    protected abstract actions$: Actions;
    protected abstract store: Store;
    protected abstract jobListingService: JobListingPersistenceService;
    protected abstract jobListingStateService: JobListingService;
    protected abstract externalJobListingService: ExternalJobListingService;
    protected abstract jobListingTemplatePersistenceService: JobListingTemplatePersistenceService;
    protected abstract jobListingTemplateService: JobListingTemplateService;
    protected abstract jobListingWizardService: JobListingWizardService;
    protected abstract siteService: SiteService;
    protected abstract sitePersistenceService: SitePersistenceService;
    protected abstract wardService: WardService;
    protected abstract specialtyService: SpecialtyService;
    protected abstract gradeRateService: GradeRateService;
    protected abstract approvedRateService: ApprovedRateService;
    protected abstract gradeService: GradeService;
    protected abstract vacancyReasonService: VacancyReasonService;
    protected abstract payRateTypeService: PayRateTypeService;
    protected abstract configurationService: ConfigurationService;
    protected abstract profilePersistenceService: ProfilePersistenceService;
    protected abstract profileService: ProfileService;
    protected abstract staffingCascadeService: StaffingCascadeService;
    protected abstract externalStaffingProviderService: ExternalStaffingProviderService;
    protected abstract externalStaffingCandidateBidService: ExternalStaffingCandidateBidService;
    protected abstract staffingCascadeTimeWindowService: StaffingCascadeTimeWindowService;
    protected abstract hospitalService: HospitalService;
    protected abstract hospitalOfficerSiteService: HospitalOfficerSiteService;
    protected abstract sectorService: SectorService;
    protected abstract pensionCategoryService: PensionCategoryService;
    protected abstract hospitalVacancyReasonService: HospitalVacancyReasonService;
    protected abstract applicationService: ApplicationService;
    protected abstract declineApplicationReasonService: DeclineApplicationReasonService;
    protected abstract applicationStatusService: ApplicationStatusService;
    protected abstract staffBankCollaborationGroupService: StaffBankCollaborationGroupService;
    protected abstract hospitalOfficerService: HospitalOfficerService;
    protected abstract externalStaffingProviderTierService: ExternalStaffingProviderTierService;
    // eslint-disable-next-line max-len
    protected abstract trustExternalStaffingProviderTierService: TrustExternalStaffingProviderTierService;
    protected abstract staffingCascadeStatusService: StaffingCascadeStatusService;
    // eslint-disable-next-line max-len
    protected abstract externalStaffingCandidateBidStatusService: ExternalStaffingCandidateBidStatusService;
    protected abstract routerService: RouterService;
    protected abstract shiftMaxDurationService: ShiftMaxDurationService;
    protected abstract breakTimeService: BreakTimeService;
    protected abstract rateViolationReasonService: RateViolationReasonService;
    protected abstract bankHolidayService: BankHolidayService;
    protected abstract professionSpecialtyService: ProfessionSpecialtyService;
    protected abstract microAppService: MicroAppService;
    protected abstract costCentreNumberService: CostCentreNumberService;
    protected hospitalProfessionConfigurationService: HospitalProfessionConfigurationService;
    protected listingConversationService: ListingConversationService;
    protected recentActivityShiftService: RecentActivityShiftService;
    protected tagService: TagService;
    protected fragmentRateControlRegex = new RegExp(
      `${FORM_ID}\.gradesSection\.grades\.[0-9]\.jobFragments\.[0-9]\.payRate\.rate`,
    );
    protected siteControlRegex = new RegExp(`${FORM_ID}\.shiftCreation\.site`);
    protected professionRegex = new RegExp(`^${FORM_ID}\.shiftCreation\.profession$`);
    protected professionSpecialtyRegex = new RegExp(
      `^${FORM_ID}\.shiftCreation\.professionSpecialty$`,
    );
    protected approvedRateChangeControlsRegex = new RegExp(
      `^${FORM_ID}\.shiftCreation\.professionSpecialty$` +
        `|^${FORM_ID}\.shiftCreation\. nonResidentOnCall$` +
        `|^${FORM_ID}\.shiftScheduler\.startTime$` +
        `|^${FORM_ID}\.shiftScheduler\.endTime$` +
        `|^${FORM_ID}\.shiftScheduler\.repetitionDates$` +
        `|^${FORM_ID}\.shiftScheduler\.consentBackdatedShifts$`,
    );
    protected currentPathRegex = new RegExp(`^${path}\/\w*`);
    protected currentPath = path;
    protected fullCurrentPath = `/${path}/`;

    // eslint-disable-next-line max-len
    protected crossCoveringProfessionSpecialtiesPath = `${FORM_ID}\.shiftCreation\.crossCoveringProfessionSpecialties`;
    protected jobListingPermissionService: JobListingPermissionService;

    /**
     * ActionSetupMethods
     */
    setupSetProfessionValue() {
      return this.actions$.pipe(
        ofType<SetValueAction<string>>(SetValueAction.TYPE),
        filter((action) => this.professionRegex.test(action.controlId)),
        map(() => new ResetProfessionDetailsMessage({})),
      );
    }

    setupSetCostCentreNumberValue() {
      return this.actions$.pipe(
        ofType<SetValueAction<string>>(SetValueAction.TYPE),
        filter(
          (action) =>
            this.professionSpecialtyRegex.test(action.controlId) ||
            this.siteControlRegex.test(action.controlId),
        ),
        map(() => new ResetCostCentreNumberMessage({})),
      );
    }

    setUpConfirmApprovedRate() {
      return this.actions$.pipe(
        ofSignalType(AcceptApprovedRateViolationSignal),
        map(
          (action) =>
            new SetUserDefinedPropertyAction(
              `${FORM_ID}.gradesSection.grades.${action.payload.gradeIndex}`,
              'ratesApproved',
              true,
            ),
        ),
      );
    }
    setupJobFragmentPayRateGradeRateReset() {
      return this.actions$.pipe(
        ofType<SetValueAction<string>>(SetValueAction.TYPE),
        filter((action) => this.fragmentRateControlRegex.test(action.controlId)),
        concatLatestFrom(() =>
          this.jobListingStateService.getViolatedApprovedRatesExistForForm(false),
        ),
        mergeMap(([_, violationsExist]) => {
          const actions: Action[] = [];
          if (!violationsExist) {
            actions.push(new SetValueAction(`${FORM_ID}.shiftCreation.rateViolationReason`, null));
          }
          return actions;
        }),
      );
    }
    setupJobFragmentPayRateApprovedReset() {
      return this.actions$.pipe(
        ofType<SetValueAction<string>>(SetValueAction.TYPE),
        filter((action) => this.fragmentRateControlRegex.test(action.controlId)),
        map(
          (action) =>
            new SetUserDefinedPropertyAction(
              action.controlId.split('.jobFragments').shift(),
              'ratesApproved',
              false,
            ),
        ),
      );
    }
    setupUpdateSite() {
      return this.actions$.pipe(
        ofType<SetValueAction<string>>(SetValueAction.TYPE),
        filter((action) => this.siteControlRegex.test(action.controlId)),
        mergeMap((action) => {
          let site = -1;
          try {
            site = parseInt(action.value, 10);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }

          return this.hospitalOfficerService.hasExtendedHoursAccessOnly(of(site)).pipe(
            map((hasExtendedHoursAccessOnly) => {
              if (hasExtendedHoursAccessOnly) {
                return new SetExtendedHoursMessage({ value: true });
              }
            }),
          );
        }),
        filter((x) => !!x),
      );
    }

    setupPrefillCostCentreNumberSignal() {
      return this.actions$.pipe(
        ofSignalType(PrefillCostCentreNumberSignal),
        concatLatestFrom(() => [
          this.store.pipe(select(selectors.selectJobListingFormCostCentreNumberValue)),
        ]),
        mergeMap(([action, costCentreNumber]) => {
          if (isNil(costCentreNumber)) {
            return of(new SetCostCentreNumberMessage({ costCentreNumber: action.payload.value }));
          }
          return EMPTY;
        }),
      );
    }

    setupSetCostCentreNumberSignal() {
      return this.actions$.pipe(
        ofSignalType(SetCostCentreNumberSignal),
        concatLatestFrom(() => [
          this.store.pipe(select(selectors.selectJobListingFormCostCentreNumberValue)),
          this.store.pipe(select(selectors.selectJobListingFormSiteValue)),
          this.store.pipe(select(selectors.selectJobListingFormProfessionValue)),
          this.store.pipe(select(selectors.selectJobListingFormPrimaryProfessionSpecialtyValue)),
          this.store
            .pipe(select(selectors.selectJobListingFormPrimaryProfessionSpecialtyValue))
            .pipe(
              mergeMap((profSpecialty) => this.professionSpecialtyService.getOne(profSpecialty)),
            ),
          this.store.pipe(select(selectors.selectJobListingFormWardValue)),
          this.store.pipe(select(selectors.selectJobListingFormRosterValue)),
          this.store.pipe(select(selectors.selectJobListingFormStartTimeValue)),
          this.hospitalService.getHospitalDetails(),
        ]),
        mergeMap(
          ([
            action,
            costCentreNumber,
            site,
            profession,
            selectedProfessionSpecialty,
            professionSpecialty,
            ward,
            roster,
            startTime,
            hospitalDetails,
          ]) => {
            if (hospitalDetails.costCodesV2) {
              if (!isNaN(startTime.getTime())) {
                const params: IQueryParams = {
                  profession,
                  specialty: professionSpecialty.specialty,
                  site,
                  ward,
                  roster,
                  deactivation_date__gt: Time.formatDBDate(startTime),
                };
                return this.costCentreNumberService.initializePagination(
                  action.payload.namespace,
                  {},
                  params,
                );
              }
            } else {
              if (isNil(costCentreNumber) || isEmpty(costCentreNumber)) {
                return this.sitePersistenceService
                  .retrieveCostCentreNumber(site, profession, selectedProfessionSpecialty)
                  .pipe(
                    map(
                      (response) =>
                        new SetCostCentreNumberMessage({
                          costCentreNumber: response.costCentreNumber,
                        }),
                    ),
                    catchError(() => EMPTY as Observable<Action>),
                  );
              } else {
                return EMPTY as Observable<Action>;
              }
            }
          },
        ),
      );
    }

    setupInitializeJobListingFormSignal() {
      return this.actions$.pipe(
        ofSignalType(InitializeJobListingFormSignal),
        switchMap(() =>
          concat(
            merge(this.approvedRateService.load(), this.bankHolidayService.load()),
            merge(
              this.siteService.loadWithPreferred(),
              this.wardService.load(),
              this.specialtyService.loadWithDependencies(),
              this.gradeRateService.load(),
              this.gradeService.load(),
              this.vacancyReasonService.loadForHospital(),
              this.payRateTypeService.load(),
              this.jobListingTemplateService.load(),
              this.hospitalOfficerSiteService.load(),
              this.sectorService.load(),
              this.pensionCategoryService.load(),
              this.staffingCascadeStatusService.load(),
              this.shiftMaxDurationService.load(),
              this.breakTimeService.load(),
              this.rateViolationReasonService.load(),
              this.hospitalProfessionConfigurationService.load(),
              this.initializeExtraFeatures(),
              this.staffBankCollaborationGroupService.load(),
              combineLatest([
                this.siteService.getPreferredSiteOptions().pipe(
                  map((siteOptions) => siteOptions.filter((option) => option.name !== 'select')),
                  map((siteOptions) => {
                    if (siteOptions.length === 1) {
                      return get(siteOptions[0], 'id');
                    }
                    return null;
                  }),
                ),
                this.routerService.getPathParam('id', true, {
                  url: this.currentPathRegex,
                }),
                this.routerService.getPathParam('date', true, {
                  url: this.currentPathRegex,
                }),
                this.hospitalVacancyReasonService.getAllVacancyReasonIdsAfterLoading(),
                this.hospitalService
                  .getAssignedHospitalSector()
                  .pipe(
                    mergeMap((sector) =>
                      this.pensionCategoryService.getDefaultPensionCategoryForSector(sector),
                    ),
                  ),
                this.hospitalService.getAssignedHospitalSector(),
                this.hospitalService.getAssigned(),
                this.shiftMaxDurationService.getShiftHardMaxDuration(),
                this.breakTimeService.getBreakTime(),
                this.breakTimeService.getMinShiftDuration(),
                this.microAppService
                  .getMicroAppCode()
                  .pipe(
                    mergeMap((code) =>
                      code === PRODUCT_CODES.LINK
                        ? this.externalJobListingService
                            .getExternalListingFormDescription()
                            .pipe(first())
                        : of(null),
                    ),
                  ),
                this.microAppService
                  .getMicroAppCode()
                  .pipe(
                    mergeMap((code) =>
                      code === PRODUCT_CODES.LINK
                        ? this.externalJobListingService
                            .getExternalListingFormCostCenterNumber()
                            .pipe(first())
                        : of(null),
                    ),
                  ),
                this.microAppService
                  .getMicroAppCode()
                  .pipe(
                    mergeMap((code) =>
                      code === PRODUCT_CODES.LINK
                        ? this.externalJobListingService
                            .getExternalListingFormReasonForVacancy()
                            .pipe(first())
                        : of(null),
                    ),
                  ),
              ]).pipe(
                distinctUntilChanged(),
                takeUntil(this.getInitializeDestroyAction()),
                mergeMap(
                  ([
                    site,
                    paramId,
                    paramDate,
                    hospitalVacancyReasons,
                    pensionCategory,
                    sector,
                    hospital,
                    shiftHardMaxDuration,
                    breakTime,
                    minShiftDuration,
                    externalListingFormDescription,
                    externalListingFormCostCenterNumber,
                    externalListingFormReasonForVacancy,
                  ]) => {
                    if (paramId) {
                      const jobListingId = +paramId;

                      return merge(
                        of(new LoadingInProgressMessage({})),
                        this.jobListingStateService.loadCurrentListingCascadeStats(),
                        this.retrieveInitialJobListingById(jobListingId)
                          .pipe(
                            mergeMap((entity) => {
                              const observables$ = [];
                              const professionSpecialtyObservable$ = combineLatest([
                                this.jobListingStateService.getEntityGradeProfessions(entity),
                                this.getCurrentEntityFormStateForEntity(entity),
                                this.jobListingStateService.getFormUserDefinedPropertiesUpdateState(
                                  entity,
                                ),
                              ]).pipe(
                                first(),
                                mergeMap(
                                  ([
                                    listingGradeProfessions,
                                    jobListingFormState,
                                    userDefinedProperties,
                                  ]) => {
                                    const actions: Action[] = !getEntityFlatRateEnabled(
                                      jobListingFormState,
                                    )
                                      ? [new DisableFlatRateMessage({})]
                                      : [];

                                    actions.unshift(
                                      new InitializeJobListingFormMessage({
                                        jobListingFormState,
                                        fromTemplate: false,
                                        hospitalSector: sector,
                                        globalDescription: externalListingFormDescription,
                                        globalCostCentreNumber: externalListingFormCostCenterNumber,
                                        globalReasonForVacancy: externalListingFormReasonForVacancy,
                                        listingGradeProfessions,
                                        userDefinedProperties,
                                      }),
                                      new SetUserDefinedPropertyAction(
                                        FORM_ID,
                                        'fromTemplate',
                                        false,
                                      ),
                                      new InitializeApplicationListStateMessage({}),
                                      new SetSelectedMessage({ id: entity.id }),
                                      new SetUserDefinedPropertyAction(
                                        SHIFT_SCHEDULER_FORM_ID,
                                        'shiftHardMaxDuration',
                                        shiftHardMaxDuration,
                                      ),
                                      new SetUserDefinedPropertyAction(
                                        FORM_ID,
                                        'recentActivityExpanded',
                                        false,
                                      ),
                                      new InitializePreMatchFormMessage({}),
                                    );
                                    return concat(
                                      merge(
                                        ...actions.map((action) => of(action)),
                                        ...this.getEntityFormInitializationActions(entity),
                                        of(new UpdateShowShiftDetailsCardMessage({ show: true })),
                                      ),
                                      of(new LoadingCompleteMessage({})),
                                    );
                                  },
                                ),
                              );
                              observables$.push(professionSpecialtyObservable$);
                              if (entity.externalJobListingId) {
                                const externalJobListingObservable$ = this.externalJobListingService
                                  .loadOne(entity.externalJobListingId)
                                  .pipe(
                                    mergeMap((externalJobListing) =>
                                      of(
                                        new SetUserDefinedPropertyAction(
                                          FORM_ID,
                                          'externalJobListing',
                                          externalJobListing,
                                        ),
                                      ),
                                    ),
                                    catchError(() => EMPTY),
                                  );
                                observables$.push(externalJobListingObservable$);
                              }
                              return merge(...observables$);
                            }),
                            catchError(() => of(new ListingNotFoundMessage({}))),
                          )
                          .pipe(tap(() => this.jobListingWizardService.updateActiveStep(1))),
                        ...this.getRelatedEntityWidgetInitializationActions(jobListingId),
                      );
                    } else {
                      let startTime = INITIAL_FORM_STATE.value.shiftScheduler.startTime;
                      let endTime = INITIAL_FORM_STATE.value.shiftScheduler.endTime;

                      if (paramDate) {
                        startTime =
                          Time.getMoment(paramDate, URL_DATE_FORMAT).format(
                            DATE_TIME_PICKER_FORMAT,
                          ) + 'T';
                        endTime =
                          Time.getMoment(paramDate, URL_DATE_FORMAT).format(
                            DATE_TIME_PICKER_FORMAT,
                          ) + 'T';
                      }

                      let reasonForVacancy: number =
                        INITIAL_FORM_STATE.value.shiftCreation.reasonForVacancy;

                      if (hospitalVacancyReasons.length === 1)
                        reasonForVacancy = hospitalVacancyReasons[0];

                      let costCentreNumber =
                        INITIAL_FORM_STATE.value.shiftScheduler.costCentreNumber;

                      if (
                        sector &&
                        hospital &&
                        ((sector as ISectorEntity).code === SECTOR_CODES.PRIMARY_CARE ||
                          (sector as ISectorEntity).code === SECTOR_CODES.VET)
                      ) {
                        costCentreNumber = (hospital as IHospitalEntity).name;
                      }
                      return concat(
                        this.jobListingStateService.getFormUserDefinedPropertiesInitialState().pipe(
                          first(),
                          map(
                            (userDefinedProperties) =>
                              new InitializeJobListingFormMessage({
                                jobListingFormState: {
                                  ...INITIAL_FORM_STATE.value,
                                  shiftCreation: {
                                    ...INITIAL_FORM_STATE.value.shiftCreation,
                                    pensionCategory,
                                    reasonForVacancy,
                                  },
                                  shiftScheduler: {
                                    ...INITIAL_FORM_STATE.value.shiftScheduler,
                                    costCentreNumber,
                                    startTime,
                                    endTime,
                                  },
                                },
                                fromTemplate: false,
                                hospitalSector: sector,
                                listingGradeProfessions: [],
                                userDefinedProperties,
                              }),
                          ),
                        ),
                        of(new InitializePreMatchFormMessage({})),
                        of(new SetUserDefinedPropertyAction(FORM_ID, 'fromTemplate', false)),
                        of(new SetValueAction(`${FORM_ID}.site`, site)),
                        of(
                          new SetUserDefinedPropertyAction(
                            SHIFT_SCHEDULER_FORM_ID,
                            'shiftHardMaxDuration',
                            shiftHardMaxDuration,
                          ),
                        ),
                        of(new SetUserDefinedPropertyAction(FORM_ID, 'breakTime', breakTime)),
                        of(
                          new SetUserDefinedPropertyAction(
                            FORM_ID,
                            'minShiftDuration',
                            minShiftDuration,
                          ),
                        ),
                        of(new InitializeUiStateMessage({})),
                        of(new InitializeApplicationListStateMessage({})).pipe(
                          tap(() => this.jobListingWizardService.updateActiveStep(1)),
                        ),
                        of(new LoadingCompleteMessage({})),
                      );
                    }
                  },
                ),
                takeUntil(this.getInitializeDestroyAction()),
              ),
            ),
          ),
        ),
      ) as Observable<Action>;
    }

    setupLoadJobListingTemplateSignal() {
      return this.actions$.pipe(
        ofSignalType(LoadJobListingTemplateSignal),
        concatLatestFrom(() => this.jobListingTemplateService.getAll()),
        mergeMap(([action, jobListings]) =>
          combineLatest([
            this.store.pipe(select(selectAssignedHospitalSector)),
            this.sectorService.getAll(),
            this.vacancyReasonService.getAll(),
            this.gradeService.getHospitalGrades(),
            this.professionSpecialtyService.getAllAfterLoading(),
            this.shiftMaxDurationService.getShiftHardMaxDuration(),
            this.hospitalService.getHospitalDetails(),
            this.jobListingPermissionService.hasCreateNonResidentShiftPermission(),
            this.jobListingStateService.getFormUserDefinedPropertiesInitialState(),
          ]).pipe(
            first(),
            distinctUntilChanged(),
            mergeMap(
              ([
                assignedHospitalSector,
                sectors,
                vacancyForReasons,
                hospitalGrades,
                professionSpecialties,
                shiftHardMaxDuration,
                hospitalDetails,
                hasCreateNonResidentShiftPermission,
                userDefinedProperties,
              ]) => {
                const sector = sectors.find((x) => x.id === assignedHospitalSector);
                const selectedJobListingTemplateId = action.payload.templateId;
                let template = getTemplate(jobListings, selectedJobListingTemplateId);

                if (isNil(template.jobListing)) {
                  return from([]);
                }

                // TODO fix load template grades
                //filter available grades
                const filteredGrades: IJobListingGradeEntity[] = template.jobListing.grades.filter(
                  (grade) => !!hospitalGrades.find((g) => g.id === grade.grade),
                );

                template = {
                  ...template,
                  jobListing: { ...template.jobListing, grades: filteredGrades },
                };

                const transformedTemplateJobListing =
                  transformJobListingTemplateBeforeLoad(template);

                const professionSpecialtySelected = professionSpecialties.find(
                  (professionSpecialty) =>
                    professionSpecialty.specialty &&
                    professionSpecialty.profession === transformedTemplateJobListing.profession &&
                    professionSpecialty.specialty === transformedTemplateJobListing.specialty,
                );

                let jobListingFormState = {
                  ...getEntityFormStateForTemplateJobListing(
                    transformedTemplateJobListing,
                    professionSpecialtySelected?.id,
                    professionSpecialties,
                    hospitalDetails.costCodesV2,
                  ),
                };

                if (sector != null && sector.code === SECTOR_CODES.PRIMARY_CARE) {
                  const vacancyOtherReason = vacancyForReasons.find(
                    (reason) => reason.code === 'OTHER',
                  );

                  if (vacancyOtherReason) {
                    jobListingFormState = {
                      ...jobListingFormState,
                      shiftCreation: {
                        ...jobListingFormState.shiftCreation,
                        reasonForVacancy: vacancyOtherReason.id,
                      },
                    };
                  }
                }

                jobListingFormState = {
                  ...jobListingFormState,
                  shiftCreation: {
                    ...jobListingFormState.shiftCreation,
                    nonResidentOnCall: hasCreateNonResidentShiftPermission
                      ? jobListingFormState.shiftCreation.nonResidentOnCall
                      : false,
                    templateId: template.id,
                  },
                };

                const actions: Action[] = !getEntityFlatRateEnabled(jobListingFormState)
                  ? [new DisableFlatRateMessage({})]
                  : [];

                actions.unshift(
                  new InitializeJobListingFormMessage({
                    jobListingFormState,
                    fromTemplate: true,
                    hospitalSector: sector,
                    listingGradeProfessions: filteredGrades.map(
                      (grade) => hospitalGrades.find((g) => g.id === grade.grade).profession,
                    ),
                    userDefinedProperties,
                  }),

                  new SetUserDefinedPropertyAction(FORM_ID, 'fromTemplate', true),
                  new SetUserDefinedPropertyAction(
                    SHIFT_SCHEDULER_FORM_ID,
                    'shiftHardMaxDuration',
                    shiftHardMaxDuration,
                  ),
                  new InitializeStaffingCascadeFormMessage({}),
                );
                actions.push(
                  new SetFormLoadedTemplateMessage({
                    name: template.name,
                    id: selectedJobListingTemplateId,
                    isTemplateLoaded: true,
                  }),
                );

                actions.push(this.jobListingTemplateService.selectJobListingTemplate(template.id));
                return concat(actions);
              },
            ),
          ),
        ),
      );
    }

    setupResetGradeJobFragmentsRatesSignal() {
      return this.actions$.pipe(
        ofSignalType(ResetGradeJobFragmentsRatesSignal),
        map(
          (action) =>
            new ResetGradeJobFragmentsRatesMessage({
              grade: action.payload.grade,
              ceiling: action.payload.ceiling,
            }),
        ),
      );
    }

    setupResetCostCentreNumberSignal() {
      return this.actions$.pipe(
        ofSignalType(ResetCostCentreNumberSignal),
        concatLatestFrom(() => this.hospitalService.getHospitalUsesCostCodesV2()),
        mergeMap(([_, useCostCodesV2]) => {
          if (useCostCodesV2) {
            return of(new ResetCostCentreNumberMessage({}));
          }
          return EMPTY;
        }),
      );
    }

    setupDeleteJobListingTemplateSignal() {
      return this.actions$.pipe(
        ofSignalType(DeleteJobListingTemplateSignal),
        mergeMap((action) =>
          this.jobListingTemplatePersistenceService.delete(action.payload.templateId).pipe(
            map(
              (response: IJobListingTemplateEntity) =>
                new jobListingTemplateMessages.DeleteOneMessage({
                  id: response.id.toString(),
                }),
            ),
            catchError(conditionalErrorHandler()),
          ),
        ),
      );
    }

    setupToggleJobListingGradeSignal() {
      return this.actions$.pipe(
        ofSignalType(ToggleJobListingGradeSignal),
        concatLatestFrom(() => [
          this.store.pipe(select(selectors.selectExtendedJobListingFormWizardState)),
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getEmploymentPeriod),
          ),
          this.store
            .pipe(select(selectors.selectExtendedJobListingFormWizardState))
            .pipe(map(getHasFlatRate)),
          this.store
            .pipe(select(selectors.selectExtendedJobListingFormWizardState))
            .pipe(map(getHasTimeFragments)),
          this.store
            .pipe(select(selectors.selectExtendedJobListingFormWizardState))
            .pipe(map(getHasJobListingGrades)),
          this.payRateTypeService.getDefaultTimeBasedPayRateTypeOption(),
          this.configurationService.defaultsToFlatRate(),
        ]),
        map(
          ([
            action,
            formState,
            employmentPeriod,
            hasFlatRate,
            hasTimeFragments,
            hasJobListingGrades,
            defaultTimeBasedPayRateType,
            defaultsToFlatRate,
          ]) => {
            const gradeGroup = getJobListingGrade(action.payload.grade)(formState);
            const addJobFragment = !hasJobListingGrades && !hasTimeFragments && !defaultsToFlatRate;
            return [
              action,
              gradeGroup,
              employmentPeriod,
              hasFlatRate || (!hasJobListingGrades && defaultsToFlatRate),
              defaultTimeBasedPayRateType,
              addJobFragment,
            ] as [
              InstanceType<typeof ToggleJobListingGradeSignal>,
              FormGroupState<IJobListingExtendedGradeFormState>,
              IPeriodFormState,
              boolean,
              SelectOption<IPayRateType>,
              boolean,
            ];
          },
        ),
        mergeMap(
          ([
            action,
            gradeGroup,
            employmentPeriod,
            hasFlatRate,
            defaultTimeBasedPayRateType,
            addJobFragment,
          ]) => {
            const actions: Action[] = [];
            if (gradeGroup) {
              if (gradeGroup.controls.grade.isEnabled) {
                actions.unshift(new DisableJobListingGradeMessage(action.payload));
                return actions;
              }
              actions.unshift(
                new EnableJobListingGradeMessage({ ...action.payload, skipFlatRate: !hasFlatRate }),
              );
              return actions;
            }
            return this.addGrade(
              action.payload.grade,
              action.payload.profession,
              employmentPeriod,
              defaultTimeBasedPayRateType,
              addJobFragment,
            ).concat(actions);
          },
        ),
      );
    }

    setupClearCrossCoveringProfessionSpecialtiesSignal() {
      return this.actions$.pipe(
        ofSignalType(ClearCrossCoveringProfessionSpecialtiesSignal),
        map(() => new SetValueAction(this.crossCoveringProfessionSpecialtiesPath, [])),
      );
    }

    setupAddCrossCoveringProfessionSpecialtySignal() {
      return this.actions$.pipe(
        ofSignalType(AddCrossCoveringProfessionSpecialtySignal),
        map(
          () =>
            new AddArrayControlAction(this.crossCoveringProfessionSpecialtiesPath, {
              profession: null,
              professionSpecialties: box([]),
            }),
        ),
      );
    }
    setupRemoveCrossCoveringProfessionSpecialtySignal() {
      return this.actions$.pipe(
        ofSignalType(RemoveCrossCoveringProfessionSpecialtySignal),
        map((action) => new RemoveArrayControlAction(...parseArrayControlId(action.payload))),
      );
    }

    setupClearJobListingGradeSignal() {
      return this.actions$.pipe(
        ofSignalType(ClearJobListingGradeSignal),
        map((action) => new ClearJobListingGradeMessage({ grade: action.payload.grade })),
      );
    }

    setupAddExternalEmailSignal() {
      return this.actions$.pipe(
        ofSignalType(AddExtraEmailSignal),
        map(() => new AddExtraEmailMessage({})),
      );
    }

    setupDeleteExtraEmailSignal() {
      return this.actions$.pipe(
        ofSignalType(DeleteExtraEmailSignal),
        map((action) => new DeleteExtraEmailMessage(action.payload)),
      );
    }

    setupClearExtraEmailsSignal() {
      return this.actions$.pipe(
        ofType(ClearExtraEmailsSignal.TYPE),
        map(() => new ClearExtraEmailsMessage({})),
      );
    }

    setupRemoveFileSignal() {
      return this.actions$.pipe(
        ofSignalType(RemoveFileSignal),
        map((action) => new RemoveFileMessage(action.payload)),
      );
    }

    setupDefaultApplicationDeadlineSignal() {
      return this.actions$.pipe(
        ofSignalType(SetDefaultApplicationDeadlineSignal),
        concatLatestFrom(() => [
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getDefaultApplicationDeadline),
          ),
          this.store.pipe(select(selectors.selectExtendedJobListingFormWizardState)),
        ]),
        mergeMap(([_, defaultApplicationDeadline, state]) =>
          of(
            new SetDefaultApplicationDeadlineMessage({
              defaultApplicationDeadline,
              customApplicationDeadlineChecked:
                state.value.shiftScheduler.hasCustomApplicationDeadLine,
            }),
          ),
        ),
      );
    }

    setupChangeEmploymentPeriodSignal() {
      return this.actions$.pipe(
        ofSignalType(ChangeEmploymentPeriodSignal),
        concatLatestFrom(() => [
          this.jobListingTemplateService.getSelectedJobListingTemplate(),
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getTimeFragmentsOutOfRange),
          ),
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getTimeFragments),
            map(getMinTimeFragment),
          ),
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getTimeFragments),
          ),
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getEmploymentPeriod),
          ),
          this.payRateTypeService.getDefaultTimeBasedPayRateTypeOption(),
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getDefaultApplicationDeadline),
          ),
          this.store.pipe(select(selectors.selectExtendedJobListingFormWizardState)),
        ]),
        mergeMap(
          ([
            ,
            jobListingTemplate,
            timeFragmentsOutOfRange,
            minTimeFragment,
            timeFragments,
            employmentPeriod,
            defaultTimeBasedPayRateType,
            defaultApplicationDeadline,
            state,
          ]) => {
            const startTime = Time.getMoment(employmentPeriod.startTime.value);
            const endTime = Time.getMoment(employmentPeriod.endTime.value);

            const startTimeIsValid = startTime.isValid();
            const endTimeIsValid = endTime.isValid();

            const initializeTemplateListing =
              (startTimeIsValid && !endTimeIsValid) || (!startTimeIsValid && endTimeIsValid);

            if (!startTimeIsValid && !endTimeIsValid) {
              return of();
            }

            if (
              !initializeTemplateListing &&
              Time.getDurationAsSeconds(+startTime, +endTime) < -1
            ) {
              return of();
            }

            let timeFragmentReshufflingMessages: Action[] = [
              new SetDefaultApplicationDeadlineMessage({
                defaultApplicationDeadline,
                customApplicationDeadlineChecked:
                  state.value.shiftScheduler.hasCustomApplicationDeadLine,
              }),
            ];

            if (!minTimeFragment) {
              return timeFragmentReshufflingMessages;
            }

            if (jobListingTemplate && initializeTemplateListing) {
              const transformedTemplateJobListing =
                transformJobListingTemplateBeforeLoad(jobListingTemplate);

              const templateTimeFragments =
                transformedTemplateJobListing.grades[0].jobFragments.map((jf) => jf.timeFragment);
              const templateStartTime = +minBy(templateTimeFragments, 'fromTime').fromTime;
              const templateEndTime = +maxBy(templateTimeFragments, 'toTime').toTime;

              const templateDuration = templateEndTime - templateStartTime;
              let controlUpdate: Action;
              let timeDifference: number;
              let shiftStartTime: number;
              let shiftEndTime: number;

              if (startTimeIsValid) {
                shiftStartTime = +startTime;
                shiftEndTime = shiftStartTime + templateDuration;
                controlUpdate = new SetValueAction(
                  `${FORM_ID}.shiftScheduler.endTime`,
                  Time.formatISODate(shiftEndTime),
                );
                timeDifference = shiftStartTime - templateStartTime;
              } else {
                shiftEndTime = +endTime;
                shiftStartTime = shiftEndTime - templateDuration;
                controlUpdate = new SetValueAction(
                  `${FORM_ID}.shiftScheduler.startTime`,
                  Time.formatISODate(shiftStartTime),
                );
                timeDifference = shiftEndTime - templateEndTime;
              }
              const timeFragmentRemovalMessages = Object.keys(timeFragments.controls).map(
                (id) => new RemoveTimeFragmentMessage({ id }),
              );
              const updateTimeFragmentRemovalMessage = new UpdateGradeJobFragmentsMessage({
                payRateType: defaultTimeBasedPayRateType.id,
              });
              const timeFragmentAdditionMessages = templateTimeFragments.map(
                (timeFragment) =>
                  new AddTimeFragmentMessage({
                    id: timeFragment.id,
                    fromTime: +timeFragment.fromTime + timeDifference,
                  }),
              );

              const updateTimeFragmentAdditionMessage = new UpdateGradeJobFragmentsMessage({
                payRateType: defaultTimeBasedPayRateType.id,
              });

              const { nonResidentOnCall } = state.value.shiftCreation;

              const updateJobFragmentRateMessages = flatMap(
                transformedTemplateJobListing.grades.map((jobListingGrade) =>
                  jobListingGrade.jobFragments.map(
                    (jobFragment) =>
                      new UpdateJobFragmentRateMessage({
                        gradeId: jobListingGrade.grade,
                        timeFragmentId: jobFragment.timeFragment.id,
                        rate: jobFragment.payRate.rate,
                        nonResidentCalloutRate:
                          +jobFragment.payRate.nonResidentCalloutRate && nonResidentOnCall
                            ? jobFragment.payRate.nonResidentCalloutRate
                            : null,
                        nonResidentCalloutRateCurrency:
                          jobFragment.payRate.nonResidentCalloutRateCurrency && nonResidentOnCall
                            ? jobFragment.payRate.nonResidentCalloutRateCurrency
                            : null,
                      }),
                  ),
                ),
              );

              timeFragmentReshufflingMessages = [
                ...timeFragmentRemovalMessages,
                updateTimeFragmentRemovalMessage,
                ...timeFragmentAdditionMessages,
                updateTimeFragmentAdditionMessage,
                ...updateJobFragmentRateMessages,
                controlUpdate,
                ...timeFragmentReshufflingMessages,
              ];
            } else {
              const timeFragmentsOutOfRangeMessages = timeFragmentsOutOfRange
                .filter((t) => String(t) !== String(minTimeFragment))
                .map((timeFragment) => new RemoveTimeFragmentMessage({ id: timeFragment }));

              timeFragmentReshufflingMessages = [
                ...timeFragmentsOutOfRangeMessages,
                ...timeFragmentReshufflingMessages,
              ];

              if (timeFragmentsOutOfRangeMessages.length) {
                timeFragmentReshufflingMessages.push(
                  new UpdateGradeJobFragmentsMessage({
                    payRateType: defaultTimeBasedPayRateType.id,
                  }),
                );
              }

              timeFragmentReshufflingMessages.unshift(
                new UpdateTimeFragmentMessage({
                  id: minTimeFragment,
                  fromTime: +Time.getMoment(employmentPeriod.startTime.value),
                }),
              );
            }

            return from(timeFragmentReshufflingMessages);
          },
        ),
        filter((action) => !isNil(action)),
      );
    }

    setupAddTimeFragmentSignal() {
      return this.actions$.pipe(
        ofSignalType(AddTimeFragmentSignal),
        concatLatestFrom(() => [
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getTimeFragments),
            map(getMaxTimeFragment),
          ),
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getEmploymentPeriod),
          ),
          this.payRateTypeService.getDefaultTimeBasedPayRateTypeOption(),
        ]),
        mergeMap(([_, maxTimeFragment, employmentPeriod, payRateType]) => {
          const startTime = Time.getMoment(get(maxTimeFragment, 'fromTime'));
          const endTime = Time.getMoment(employmentPeriod.endTime.value);
          // @todo load minimum time fragment period from service
          const minimumTimeFragmentPeriod = 15;
          const fromTime = maxTimeFragment
            ? DateTime.rangeForStartEnd(startTime, endTime).center()
            : Time.getMoment(employmentPeriod.startTime.value);
          // @todo emit an action to show that no more actions can be created
          if (
            fromTime.isAfter(Time.getMoment(endTime).subtract(minimumTimeFragmentPeriod, 'minutes'))
          )
            return of();
          return from([
            new AddTimeFragmentMessage({ id: uuid(), fromTime: +fromTime }),
            new UpdateGradeJobFragmentsMessage({ payRateType: payRateType.id }),
          ]);
        }),
      );
    }

    setupReplaceTimeBasedRateSignal() {
      return this.actions$.pipe(
        ofSignalType(ReplaceTimeBasedRateSignal),
        concatLatestFrom(() => this.payRateTypeService.getDefaultTimeBasedPayRateTypeOption()),
        mergeMap(([action, payRateType]) => {
          const { timeFragment } = action.payload;

          return from([
            new RemoveTimeFragmentMessage({ id: timeFragment }),
            new UpdateGradeJobFragmentsMessage({ payRateType: payRateType.id }),
            new UpdateGradeFlatRatesMessage({}),
            new EnableFlatRateMessage({}),
          ]);
        }),
      );
    }

    setupRemoveFlatRateSignal() {
      return this.actions$.pipe(
        ofSignalType(ReplaceFlatRateSignal),
        mergeMap((action) => {
          const { payRateType } = action.payload;
          return from([new DisableFlatRateMessage({}), new AddTimeFragmentSignal({ payRateType })]);
        }),
      );
    }

    setupRemoveTimeFragmentSignal() {
      return this.actions$.pipe(
        ofSignalType(RemoveTimeFragmentSignal),
        concatLatestFrom(() => this.payRateTypeService.getDefaultTimeBasedPayRateTypeOption()),
        mergeMap(([action, payRateType]) => {
          const { timeFragment } = action.payload;
          return from([
            new RemoveTimeFragmentMessage({ id: timeFragment }),
            new UpdateGradeJobFragmentsMessage({ payRateType: payRateType.id }),
          ]);
        }),
      );
    }

    setupUpdateFormSignal() {
      return this.actions$.pipe(
        ofSignalType(UpdateFormSignal),
        concatLatestFrom(() => [
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getEntity()),
          ),
          this.hospitalService.getAssignedHospitalSector(),
          this.jobListingStateService.getViolatedApprovedRatesExistForForm(false),
        ]),
        switchMap(([_, jobListing, hospitalSector, violationsExist]) => {
          if (!violationsExist) {
            jobListing = {
              ...jobListing,
              rateViolationReason: null,
            };
          }

          return this.jobListingService.update<IJobListingEntity>(jobListing.id, jobListing).pipe(
            mergeMap((response: IJobListingEntity) =>
              combineLatest([
                this.jobListingStateService.getEntityGradeProfessions(response),
                this.getCurrentEntityFormStateForEntity(response),
                this.jobListingStateService.getFormUserDefinedPropertiesUpdateState(response),
              ]).pipe(
                first(),
                mergeMap(
                  ([listingGradeProfessions, jobListingFormState, userDefinedProperties]) => {
                    const actions: Action[] = !getEntityFlatRateEnabled(jobListingFormState)
                      ? [new DisableFlatRateMessage({})]
                      : [];
                    actions.unshift(
                      new InitializeJobListingFormMessage({
                        jobListingFormState,
                        fromTemplate: false,
                        hospitalSector,
                        listingGradeProfessions,
                        userDefinedProperties,
                      }),
                      new SetUserDefinedPropertyAction(FORM_ID, 'fromTemplate', false),
                      new InitializeStaffingCascadeFormMessage({}),
                    );

                    let alertMessage = '';
                    if (isNil(response.published)) {
                      alertMessage = `${response.title} has just been updated successfully and
                submitted for authorisation`;
                    } else {
                      alertMessage = `${response.title} has just been updated successfully`;
                    }

                    const listingUpdateMessage$ = merge(
                      from(actions),
                      alertHandler({ message: alertMessage, type: 'info' }, this.actions$),
                      of(
                        new UpdateOneMessage({
                          entity: {
                            id: response.id,
                            changes: response,
                          },
                        }),
                      ),
                      of(new ShiftPublishedSuccessfullyMessage({})),
                    );
                    return concat(
                      of(new SubmissionInProgressMessage({})),
                      listingUpdateMessage$,
                      of(new SubmissionCompleteMessage({})),
                      of(new UpdateShowShiftDetailsCardMessage({ show: true })),
                    ).pipe(tap(() => this.jobListingWizardService.updateActiveStep(1)));
                  },
                ),
              ),
            ),
            catchError((error) =>
              concat(of(new SubmissionCompleteMessage({})), conditionalErrorHandler()(error)),
            ),
          );
        }),
      );
    }

    getJobListingTemplateFromForm() {
      return this.store.pipe(select(selectors.selectJobListingFromForm)).pipe(
        switchMap((joblistingEntity) => {
          const { professionSpecialty } = joblistingEntity;
          return this.professionSpecialtyService.getOne(professionSpecialty).pipe(
            map((p) => ({
              specialty: p.specialty,
              profession: p.profession,
              ...getTemplateFromEntity(joblistingEntity),
            })),
          );
        }),
      );
    }

    setupSaveFormAsTemplateSignal() {
      return this.actions$.pipe(
        ofSignalType(SaveFormAsTemplateSignal),
        concatLatestFrom(() => this.getJobListingTemplateFromForm()),
        mergeMap(([action, jobListing]) => {
          const template: IJobListingTemplateEntity = {
            name: action.payload.templateName,
            jobListing,
            version: getCurrentJobListingFormVersion,
            site: jobListing.site,
            profession: jobListing.profession,
            specialty: jobListing.specialty,
            extendedHours: jobListing.extendedHours,
            listingType: jobListing.listingType,
          };

          return this.jobListingTemplatePersistenceService.create(template).pipe(
            mergeMap((response: IJobListingTemplateEntity) =>
              merge(
                jobListingTemplateAlertHandler(
                  { message: 'Template saved!', type: 'success' },
                  this.actions$,
                ),
                of(
                  new jobListingTemplateMessages.AddOneMessage({
                    entity: response,
                  }),
                ),
              ),
            ),
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  // eslint-disable-next-line no-useless-escape
                  `An error occurred while creating the template\: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Template can’t be created. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Template can’t be created. Please try again in a few minutes',
                badRequestMessageHandlerMap: {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  non_field_errors: (k, v) => v,
                },
              }),
            ),
          );
        }),
      );
    }

    public setupUpdateExistingTemplateSignal() {
      return this.actions$.pipe(
        ofSignalType(UpdateExistingTemplateSignal),
        concatLatestFrom(() => [this.getJobListingTemplateFromForm()]),
        mergeMap(([action, jobListing]) => {
          const templateId = action.payload.templateId;
          const template: IJobListingTemplateEntity = {
            id: templateId,
            name: action.payload.templateName,
            jobListing,
            version: getCurrentJobListingFormVersion,
            profession: jobListing.profession,
            site: jobListing.site,
            specialty: jobListing.specialty,
            extendedHours: jobListing.extendedHours,
            listingType: jobListing.listingType,
          };
          return this.jobListingTemplatePersistenceService.update(templateId, template).pipe(
            mergeMap((response) =>
              merge(
                jobListingTemplateAlertHandler(
                  { message: 'Template saved!', type: 'success' },
                  this.actions$,
                ),
                of(
                  new jobListingTemplateMessages.UpdateOneMessage({
                    entity: { id: response.id, changes: response },
                  }),
                ),
              ),
            ),
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  // eslint-disable-next-line no-useless-escape
                  `An error occurred while updating the template\: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Template can’t be updated. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Template can’t be updated. Please try again in a few minutes',
                badRequestMessageHandlerMap: {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  non_field_errors: (k, v) => v,
                },
              }),
            ),
          );
        }),
      );
    }
    private prepareRepetitionDates(
      violations: Record<string, ViolationRateWarnings[]>,
      jobListing: IJobListingEntity<Date, number, number, number>,
      note: string,
      copyNote: boolean,
      tagsIds: number[],
    ) {
      const existsForToday = !!Object.values(violations).filter((x) =>
        x.some((y) => Time.formatDate(y.fromTime) === Time.formatDate(jobListing.startTime)),
      ).length;
      const extraConfiguration: {
        copyNoteAcrossRepetitionDate?: boolean;
        tags?: number[];
        note?: string;
      } = {};
      const repetitionDates = [];

      for (const date of jobListing.repetitionDates) {
        const repetitionDateObject = {};
        repetitionDateObject['date'] = date;

        const existsForDay = !!Object.values(violations).filter((x) =>
          x.some((y) => Time.formatDate(y.fromTime) === date),
        ).length;

        if (existsForDay) {
          repetitionDateObject['rate_violation_reason'] = jobListing.rateViolationReason;
        }
        repetitionDates.push(repetitionDateObject);
      }

      if (note && jobListing.repetitionDates.length)
        extraConfiguration.copyNoteAcrossRepetitionDate = copyNote;

      if (note) extraConfiguration.note = note;
      if (tagsIds.length) extraConfiguration.tags = tagsIds;

      if (note && repetitionDates.length)
        Object.assign(jobListing, { copyNoteAcrossRepetitionDate: copyNote });
      return {
        ...jobListing,
        rateViolationReason: existsForToday ? jobListing.rateViolationReason : null,
        repetitionDates,
        ...extraConfiguration,
      };
    }
    prepareApprovedRatesListings(
      violations: Record<string, ViolationRateWarnings[]>,
      jobListing: IJobListingEntity<Date, number, number, number>,
      note: string,
      copyNote: boolean,
      tagsIds: number[],
      approvedRateListings: IJobListingEntityWithRateDefinitions<Date, number, number, number>[],
    ) {
      const listingGroupIdentifier = uuid();
      const applicableGrades = new Set(jobListing.grades.map(({ grade }) => grade));
      const extraConfiguration: {
        tags?: number[];
        note?: string;
        costCentreNumber?: string;
        prematchProfiles?: IPreMatchProfile[];
        nonResidentOnCall: boolean;
      } = {
        tags: tagsIds,
        costCentreNumber: jobListing.costCentreNumber,
        prematchProfiles: jobListing.prematchProfiles,
        nonResidentOnCall: jobListing.nonResidentOnCall,
      };
      if (note && copyNote) {
        extraConfiguration.note = note;
      }
      return approvedRateListings.map(({ grades, ...jl }) => ({
        ...jl,
        listingGroupIdentifier,
        grades: grades.filter((g) => applicableGrades.has(g.grade)),
        rateViolationReason: Object.values(violations).find((x) =>
          x.some((y) => Time.formatDate(y.fromTime) === Time.formatDate(jl.startTime)),
        )
          ? jobListing.rateViolationReason
          : null,
        ...extraConfiguration,
      }));
    }

    private getCreateResponseMessage(jobListing, response: IJobListingEntity) {
      let alertMessage = '';
      if (isNil(response)) {
        return `${jobListing.repetitionDates.length + 1} shifts have just been posted successfully`;
      }
      if (isNil(response.published)) {
        if (jobListing.repetitionDates.length) {
          alertMessage = `${
            jobListing.repetitionDates.length + 1
          } shifts have been submitted for authorisation`;
        } else {
          alertMessage = `${response.title} has been submitted for authorisation`;
        }
      } else {
        if (jobListing.repetitionDates.length) {
          alertMessage = `${
            jobListing.repetitionDates.length + 1
          } shifts have just been posted successfully`;
        } else {
          alertMessage = `${response.title} has just been posted successfully`;
        }
      }
      return alertMessage;
    }
    private handleCreateResponse<T extends IJobListingEntity | IJobListingEntity[]>(
      getResponseMessage: (response: T) => string,
      getCreateActionMessage: (
        response: T,
      ) => T extends IJobListingEntity
        ? AddOneMessage
        : T extends IJobListingEntity[]
          ? AddMultipleMessage
          : never,
      prematchProfiles,
    ) {
      return pipe(
        mergeMap((response: T) =>
          merge(
            alertHandler({ message: getResponseMessage(response), type: 'success' }, this.actions$),
            of(this.jobListingTemplateService.selectJobListingTemplate(null)),
            of(getCreateActionMessage(response)),
            // of(new AddOneMessage({ entity: response })),
            // of(new AddMultipleMessage({entities: response}))
            of(new ResetJobListingFormMessage({})),
            of(new InitializePreMatchFormMessage({})).pipe(
              tap(() => this.jobListingWizardService.updateActiveStep(1)),
            ),
            of(new ClearSelectedTagsMessage({})),
            timer(300).pipe(map(() => new ShiftPublishedSuccessfullyMessage({}))),
          ),
        ),
        catchError(
          conditionalErrorHandler({
            errorDetailMessageHandler: (message, errorCode) => {
              if (typeof message === 'string') return message;
              if (errorCode === 'CANNOT_CREATE_PREMATCH') {
                const profilesWithoutAssignmentNumber = prematchProfiles.filter((profile) =>
                  message.profiles_without_assignment_number.includes(profile.id),
                );
                const names = profilesWithoutAssignmentNumber
                  .map((profile) => `${profile.firstName} ${profile.lastName}`)
                  .join(', ');
                // eslint-disable-next-line max-len
                return `Candidates do not have an assignment number for any of the grades: ${names}`;
              }
            },
          }),
        ),
      );
    }
    submitBulkCreateFlow(
      violations: Record<string, ViolationRateWarnings[]>,
      jobListing: IJobListingEntity,
      note: string,
      copyNote: boolean,
      tagsIds: number[],
      prematchProfiles: IProfileEntity[],
      rateCardListings: IJobListingEntityWithRateDefinitions<Date, number, number, number>[],
    ) {
      const postPayload = this.prepareApprovedRatesListings(
        violations,
        jobListing,
        note,
        copyNote,
        tagsIds,
        rateCardListings,
      );
      return this.jobListingService.bulkCreate(postPayload).pipe(
        this.handleCreateResponse<IJobListingEntity[]>(
          (response) =>
            this.getCreateResponseMessage(
              jobListing,
              (response && response.length && response[0]) || undefined,
            ),
          (response) => new AddMultipleMessage({ entities: response }),
          prematchProfiles,
        ),
      );
    }
    submitPostFlow(violations, jobListing, note, copyNote, tagsIds, prematchProfiles) {
      const postPayload = this.prepareRepetitionDates(
        violations,
        jobListing,
        note,
        copyNote,
        tagsIds,
      );
      return this.jobListingService.create<IJobListingEntity, IJobListingEntity>(postPayload).pipe(
        this.handleCreateResponse<IJobListingEntity>(
          (response) => this.getCreateResponseMessage(postPayload, response),
          (response) => new AddOneMessage({ entity: response }),
          prematchProfiles,
        ),
      );
    }

    setupSubmitFormSignal() {
      return this.actions$.pipe(
        ofSignalType(SubmitFormSignal),
        concatLatestFrom(() => [
          this.listingConversationService.getJobListingWithPrematchProperties(),
          this.jobListingStateService.getViolatedApprovedRatesForForm(true),
          this.listingConversationService.getPrematchProfileEntities(),
          this.jobListingStateService.getFormNotesValue(),
          this.jobListingStateService.getFormCopyNoteAcrossRepetitionDateValue(),
          this.tagService.getSelectedTagsIds(),
          this.jobListingStateService.getFormUsesRateCardRates(),
          this.jobListingStateService.getFormRateCardRateListings(),
        ]),
        exhaustMap(
          ([
            ,
            jobListing,
            violations,
            prematchProfileEntities,
            note,
            copyNote,
            tagsIds,
            useRateCardRates,
            rateCardListings,
          ]) => {
            const listingCreationMessages$ =
              useRateCardRates && jobListing.repetitionDates.length
                ? this.submitBulkCreateFlow(
                    violations,
                    jobListing,
                    note,
                    copyNote,
                    tagsIds,
                    prematchProfileEntities,
                    rateCardListings,
                  )
                : this.submitPostFlow(
                    violations,
                    jobListing,
                    note,
                    copyNote,
                    tagsIds,
                    prematchProfileEntities,
                  );
            return concat(
              of(new SubmissionInProgressMessage({})),
              listingCreationMessages$,
              of(new SubmissionCompleteMessage({})),
            );
          },
        ),
      );
    }
    setupCascadeWidgetSignal() {
      return this.actions$.pipe(
        ofSignalType(CascadeSignal),
        concatLatestFrom(() => [
          this.store.pipe(select(selectors.selectJobListingFormId)),
          this.staffingCascadeService.getNotesFromForm(),
          this.staffingCascadeService.getForCurrentListing(),
          this.externalStaffingProviderTierService.getOrderTiers(),
        ]),
        mergeMap(([_, listing, notes, currentStaffingCascade, orderTiers]) => {
          if (!isNil(currentStaffingCascade)) {
            const currentTier = orderTiers.find((tier) => tier.id === currentStaffingCascade.tier);
            const index = orderTiers.indexOf(currentTier);
            const nextItem = orderTiers[index + 1];

            return merge(
              this.staffingCascadeService
                .requestTier(currentStaffingCascade.id.toString(), notes, nextItem.id)
                .pipe(catchError(this.staffingCascadeService.handleWidgetError)),
              this.staffingCascadeService.resetWidgetDialog(),
            );
          } else {
            return merge(
              this.staffingCascadeService
                .create({ listing, notes })
                .pipe(catchError(this.staffingCascadeService.handleWidgetError)),
              this.staffingCascadeService.resetWidgetDialog(),
            );
          }
        }),
      );
    }

    setupRevertCascadeSignal() {
      return this.actions$.pipe(
        ofSignalType(RevertCascadeSignal),
        concatLatestFrom(() => this.staffingCascadeService.getCurrentCascadeLastTierAction(true)),
        switchMap(([, { id, staffingCascade }]) =>
          this.staffingCascadeService
            .cancelTier(staffingCascade, id)
            .pipe(catchError(conditionalErrorHandler())),
        ),
      );
    }

    setupInitializeCascadeWidgetConfirmationSignal() {
      return this.actions$.pipe(
        ofSignalType(InitializeCascadeWidgetConfirmationSignal),
        mergeMap(() => this.staffingCascadeService.initializeWidgetDialog()),
      );
    }

    setupResetCascadeWidgetConfirmationSignal() {
      return this.actions$.pipe(
        ofSignalType(ResetCascadeWidgetConfirmationSignal),
        mergeMap(() => this.staffingCascadeService.resetWidgetDialog()),
      );
    }

    setupInitializeReverseCascadeWidgetConfirmationSignal() {
      return this.actions$.pipe(
        ofSignalType(InitializeReverseCascadeWidgetConfirmationSignal),
        mergeMap(() => this.staffingCascadeService.initializeReverseWidgetDialog()),
      );
    }

    setupResetReverseCascadeWidgetConfirmationSignal() {
      return this.actions$.pipe(
        ofSignalType(ResetReverseCascadeWidgetConfirmationSignal),
        mergeMap(() => this.staffingCascadeService.resetReverseWidgetDialog()),
      );
    }

    setupPublishJobListingSignal() {
      return this.actions$.pipe(
        ofType(PublishJobListingSignal.TYPE),
        concatLatestFrom(() => this.store.pipe(select(selectors.selectJobListingFormId))),
        exhaustMap(([_, listing]) =>
          concat(
            this.jobListingService.publish([listing]),
            alertHandler(
              {
                message: 'Job listing published successfully!',
                type: 'success',
              },
              this.actions$,
            ),
          ).pipe(catchError(conditionalErrorHandler())),
        ),
      );
    }

    setupUnpublishJobListingSignal() {
      return this.actions$.pipe(
        ofType(UnpublishJobListingSignal.TYPE),
        concatLatestFrom(() => this.store.pipe(select(selectors.selectJobListingFormId))),
        exhaustMap(([_, listing]) =>
          concat(
            this.jobListingService.unpublish([listing]),
            alertHandler(
              {
                message: 'Job listing unpublished successfully!',
                type: 'success',
              },
              this.actions$,
            ),
          ).pipe(catchError(conditionalErrorHandler())),
        ),
      );
    }

    setupDeclineAllAndUnpublishSignal() {
      return this.actions$.pipe(
        ofType(DeclineAllAndUnpublishSignal.TYPE),
        concatLatestFrom(() => this.store.pipe(select(selectors.selectJobListingFormId))),
        mergeMap(([_, listing]) =>
          this.jobListingService.declineAllAndUnpublish(listing).pipe(
            mergeMap((response: IJobListingEntity) =>
              merge(
                alertHandler(
                  {
                    message: 'All done!',
                    type: 'success',
                  },
                  this.actions$,
                ),
                of(new UpsertOneMessage({ entity: response })),
                this.applicationService.loadByListingIds([listing]),
              ),
            ),
            catchError(conditionalErrorHandler()),
          ),
        ),
      );
    }

    setupIgnoreWarningsAndMarkInSyncSignal() {
      return this.actions$.pipe(
        ofType(IgnoreWarningsAndMarkInSyncSignal.TYPE),
        concatLatestFrom(() => this.store.pipe(select(selectors.selectJobListingFromForm))),
        mergeMap(([_, listing]) =>
          this.externalJobListingService.clearOutOfSync(listing.externalJobListingId).pipe(
            mergeMap((response: IExternalJobListingEntity) =>
              merge(
                alertHandler(
                  {
                    message: "Shift's 'out of sync' warnings removed",
                    type: 'success',
                  },
                  this.actions$,
                ),
                of(new SetUserDefinedPropertyAction(FORM_ID, 'externalJobListing', response)),
                this.applicationService.loadByListingIds([listing.id]),
              ),
            ),
            catchError(conditionalErrorHandler()),
          ),
        ),
      );
    }

    setupSubmitForAuthorizationJobListingSignal() {
      return this.actions$.pipe(
        ofType(SubmitForAuthorizationJobListingSignal.TYPE),
        concatLatestFrom(() => this.store.pipe(select(selectors.selectJobListingFormId))),
        exhaustMap(([_, listing]) =>
          concat(
            this.jobListingService.submitForAuthorization([listing]),
            alertHandler(
              {
                message: 'Job listing submitted for authorisation successfully!',
                type: 'success',
              },
              this.actions$,
            ),
          ).pipe(catchError(conditionalErrorHandler())),
        ),
      );
    }

    setupDetailsChangeSignal() {
      return this.actions$.pipe(
        ofSignalType(DetailsChangeSignal),
        mergeMap(() => from([new DetailsChangeMessage({})])),
      );
    }

    setupCostCentreNumberChangeSignal() {
      return this.actions$.pipe(
        ofSignalType(CostCentreNumberChangeSignal),
        mergeMap(() => from([new CostCentreNumberChangeMessage({})])),
      );
    }

    setupReasonForVacancyChangeSignal() {
      return this.actions$.pipe(
        ofSignalType(ReasonForVacancyChangeSignal),
        mergeMap(() => from([new ReasonForVacancyChangeMessage({})])),
      );
    }

    getEntityFormInitializationActions(entity: IJobListingEntity) {
      return [
        of(new UpsertOneMessage({ entity })),
        of(new InitializeUiStateMessage({})),
        this.staffingCascadeTimeWindowService.loadByProfessionSpecialty(entity.professionSpecialty),
      ];
    }

    getRelatedEntityWidgetInitializationActions(jobListingId) {
      return [
        this.declineApplicationReasonService.load(),
        this.applicationStatusService.load(),
        this.externalStaffingCandidateBidStatusService.load(),
        // todo: rethink of these I don't know what the initial intention was
        // I think they should be replaced by application candidate's id
        this.applicationService.loadAllPagesAndLoadDependencies(
          'jobListingApplications',
          null,
          { listingId: jobListingId },
          true,
          true,
          {
            loadStaffBank: true,
            loadStaffBankRequests: true,
            loadAssignmentNumber: true,
            loadBids: true,
          },
        ),
        this.jobListingStateService.loadOne(
          jobListingId,
          false,
          {
            loadProfile: true,
            loadStaffBanks: false,
            loadStaffBankRequests: false,
            loadProfileFlag: true,
            loadAssignmentNumbers: false,
            loadCandidateBids: true,
          },
          true,
        ),
      ];
    }

    initializeExtraFeatures(): Observable<Action> {
      return of();
    }

    loadStaffingCascade() {
      return this.routerService.getPathParam('id', false, { url: this.currentPathRegex }).pipe(
        map((id) => +id),
        mergeMap(
          (id) => this.staffingCascadeService.loadByJobListingIds([id]) as Observable<Action>,
        ),
        takeUntil(this.getDestroyAction(InitializeJobListingFormSignal.TYPE)),
      );
    }

    retrieveInitialJobListingById(id): Observable<IJobListingEntity> {
      return this.jobListingService.retrieve(id);
    }

    // getInvalidGradesForProfession(profession) {
    //   return this.gradeService.getHospitalProfessionGradeIds(profession).pipe(
    //     concatLatestFrom(() => this.jobListingStateService.getFormGradeValues()),
    //     map(([professionGrades, formGrades]) => difference(formGrades, professionGrades)),
    //   );
    // }

    addGrade = (
      gradeId: number,
      professionId,
      employmentPeriod: IPeriodFormState,
      defaultTimeBasedPayRateType: SelectOption<IPayRateType>,
      addJobFragment: boolean,
    ) => {
      const actions: Action[] = [
        new AddJobListingGradeMessage({
          grade: gradeId,
          defaultTimeBasedPayRateType: defaultTimeBasedPayRateType.id,
          profession: professionId,
        }),
      ];
      if (addJobFragment) {
        actions.unshift(
          new AddTimeFragmentMessage({
            id: uuid(),
            fromTime: +Time.getMoment(employmentPeriod.startTime.value),
          }),
          new UpdateGradeJobFragmentsMessage({
            payRateType: defaultTimeBasedPayRateType.id,
          }),
        );
      }
      return actions;
    };

    getCurrentEntityFormStateForEntity(entity: IJobListingEntity) {
      return combineLatest([
        this.professionSpecialtyService.getProfessionId(entity.professionSpecialty),
        this.professionSpecialtyService.getAllAfterLoading(),
      ]).pipe(
        first(),
        map(([professionId, professionSpecialties]) =>
          getEntityFormState(entity, professionId, professionSpecialties),
        ),
      );
    }

    getInitializeDestroyAction() {
      return this.getDestroyAction(InitializeJobListingFormSignal.TYPE, this.fullCurrentPath, true);
    }

    initializeSingleGrade = (profession): Observable<Action> =>
      this.gradeService.getHospitalProfessionGrades([profession]).pipe(
        first(),
        concatLatestFrom(() => [
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getEmploymentPeriod),
          ),
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getHasTimeFragments),
          ),
          this.store.pipe(
            select(selectors.selectExtendedJobListingFormWizardState),
            map(getHasFlatRate),
          ),
          this.store.pipe(
            select(selectors.selectJobListingFormWizardState),
            map(getHasJobListingGrades),
          ),
          this.payRateTypeService.getDefaultTimeBasedPayRateTypeOption(),
          this.configurationService.defaultsToFlatRate(),
        ]),
        map(
          ([
            grades,
            employmentPeriod,
            _hasTimeFragments,
            _hasJobListingGrades,
            _hasFlatRate,
            defaultTimeBasedPayRateType,
            defaultsToFlatRate,
          ]) => {
            const addJobFragment =
              !_hasJobListingGrades && !_hasTimeFragments && !defaultsToFlatRate;
            const bypassFlatRate = !_hasJobListingGrades && !_hasFlatRate && !defaultsToFlatRate;
            const singleGradeId = grades && grades.length === 1 ? grades[0].id : null;
            return [
              singleGradeId,
              employmentPeriod,
              defaultTimeBasedPayRateType,
              addJobFragment,
              bypassFlatRate,
            ] as [number, IPeriodFormState, SelectOption<IPayRateType>, boolean, boolean];
          },
        ),
        filter(([singleGradeId]) => !isNil(singleGradeId)),
        mergeMap(
          ([
            singleGradeID,
            employmentPeriod,
            defaultTimeBasedPayRateType,
            addJobFragment,
            bypassFlatRate,
          ]) =>
            merge(
              this.jobListingStateService.getFormGradeControl(singleGradeID).pipe(
                first(),
                filter((gradeControl) => isNil(gradeControl)),
                mergeMap(() => {
                  const actions = this.addGrade(
                    singleGradeID,
                    profession,
                    employmentPeriod,
                    defaultTimeBasedPayRateType,
                    addJobFragment,
                  );
                  return actions;
                }),
              ),
              this.jobListingStateService.getFormGradeControl(singleGradeID).pipe(
                first(),
                filter((gradeControl) => !isNil(gradeControl)),
                map(
                  () =>
                    new EnableJobListingGradeMessage({
                      grade: singleGradeID,
                      skipFlatRate: bypassFlatRate,
                    }),
                ),
              ),
            ),
        ),
      );

    setupLockShiftRatesSignal() {
      return this.actions$.pipe(
        ofType(LockShiftRatesSignal.TYPE),
        concatLatestFrom(() => this.store.pipe(select(selectors.selectJobListingFormId))),
        exhaustMap(([_, listing]) =>
          concat(
            of(new SubmissionInProgressMessage({})),
            this.jobListingService.lockShiftRates([listing]),
            of(new SubmissionCompleteMessage({})),
            alertHandler(
              {
                message: 'Shift locked successfully!',
                type: 'success',
              },
              this.actions$,
            ),
          ).pipe(
            catchError((error) =>
              concat(of(new SubmissionCompleteMessage({})), conditionalErrorHandler()(error)),
            ),
          ),
        ),
      );
    }

    setupUnlockShiftRatesSignal() {
      return this.actions$.pipe(
        ofType(UnlockShiftRatesSignal.TYPE),
        concatLatestFrom(() => this.store.pipe(select(selectors.selectJobListingFormId))),
        exhaustMap(([_, listing]) =>
          concat(
            of(new SubmissionInProgressMessage({})),
            this.jobListingService.unlockShiftRates([listing]),
            of(new SubmissionCompleteMessage({})),
            alertHandler(
              {
                message: 'Shift unlocked successfully!',
                type: 'success',
              },
              this.actions$,
            ),
          ).pipe(
            catchError((error) =>
              concat(of(new SubmissionCompleteMessage({})), conditionalErrorHandler()(error)),
            ),
          ),
        ),
      );
    }

    setupToggleRecentActivitySignal() {
      return this.actions$.pipe(
        ofSignalType(ToggleRecentActivitySignal),
        map(
          (action) =>
            new SetUserDefinedPropertyAction(
              FORM_ID,
              'recentActivityExpanded',
              action.payload.value,
            ),
        ),
      );
    }

    setupAddListingNotesSignal() {
      return this.actions$.pipe(
        ofSignalType(AddListingNotesSignal),
        concatLatestFrom(() => [
          this.store.pipe(select(selectors.selectJobListingFormId)),
          this.store.pipe(select(selectors.selectJobListingNotesControl)),
        ]),
        mergeMap(([{ payload }, formId, notesControl]) => {
          if (notesControl.value)
            return this.jobListingService.addListingNote(formId, notesControl.value).pipe(
              mergeMap(() =>
                merge(
                  of(new ClearJobListingNotesMessage({})),
                  of(new SetUserDefinedPropertyAction(FORM_ID, 'recentActivityExpanded', true)),
                  this.recentActivityShiftService.fetch(formId.toString()),
                  payload.alert
                    ? alertHandler(
                        { message: 'Job listing note added successfully!', type: 'success' },
                        this.actions$,
                      )
                    : of(),
                ),
              ),
              catchError(conditionalErrorHandler()),
            );
          return payload.alert
            ? alertHandler(
                { message: 'Notes require at least one character.', type: 'error' },
                this.actions$,
              )
            : of();
        }),
      );
    }
    setupInitializeRatesSignal() {
      return this.actions$.pipe(
        ofType<SetValueAction<string>>(SetValueAction.TYPE),
        filter((action) => this.approvedRateChangeControlsRegex.test(action.controlId)),
        concatLatestFrom(() => [this.jobListingStateService.getCanCalculateApprovedRates()]),
        switchMap(([_, canCalculate]) => {
          if (canCalculate) {
            return this.initializeRateCardGrades();
          }
          return EMPTY;
        }),
      );
    }
    proceedToStep(step: number) {
      return merge(
        of(this.jobListingWizardService.getCompleteStepMessage(step - 1)),
        of(this.jobListingWizardService.getUpdateActiveStepMessage(step)),
      );
    }
    initializeRateCardGrades() {
      return this.jobListingStateService.getEntityFromFormState().pipe(
        switchMap((listing) =>
          this.jobListingStateService.generateApprovedRateListingGroup(listing),
        ),
        first(),
        switchMap((listingGroupDetails) => of(new SetRateCardRatesMessage(listingGroupDetails))),
      );
    }
  }

  return JobListingFormBaseEffects;
}
