import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';
import { Time } from '@locumsnest/core/src/lib/helpers';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IExternalStaffingCandidateBidEntity } from '../../interfaces/api/external-staffing-candidate-bid-entity';
import { IBaseAdjacentBidEntity } from './../../interfaces/api/external-staffing-candidate-bid-entity';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingCandidateBidPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IExternalStaffingCandidateBidEntity
> {
  protected readonly endpoint = 'externalStaffingCandidateBid';

  approveBid(id: string) {
    return this.create<{}, IExternalStaffingCandidateBidEntity>(
      {},
      { pathParams: { id }, controllerResource: 'approveBid', skipSerializer: true },
    );
  }

  rejectBid(id: string, notes: string) {
    const data = {
      notes,
    };

    return this.create<{}, IExternalStaffingCandidateBidEntity>(data, {
      pathParams: { id },
      controllerResource: 'rejectBid',
      skipSerializer: true,
    });
  }

  cancelBid(id: string, notes: string) {
    const data = {
      notes,
    };

    return this.create<{}, IExternalStaffingCandidateBidEntity>(data, {
      pathParams: { id },
      controllerResource: 'cancelBid',
      skipSerializer: true,
    });
  }

  withdrawBid(id: string) {
    const data = {
      notes: '',
    };

    return this.create<{}, IExternalStaffingCandidateBidEntity>(data, {
      pathParams: { id },
      controllerResource: 'withdrawBid',
      skipSerializer: true,
    });
  }

  unlockBid(id: string) {
    return this.create<{}, IExternalStaffingCandidateBidEntity>(
      {},
      {
        pathParams: { id },
        controllerResource: 'unlockBid',
        skipSerializer: true,
      },
    );
  }

  lockBid(id: string) {
    return this.create<{}, IExternalStaffingCandidateBidEntity>(
      {},
      {
        pathParams: { id },
        controllerResource: 'lockBid',
        skipSerializer: true,
      },
    );
  }

  importBid(data: IExternalStaffingCandidateBidEntity) {
    return this.create<IExternalStaffingCandidateBidEntity, IExternalStaffingCandidateBidEntity>(
      data,
      {
        controllerResource: 'importBid',
        skipSerializer: true,
      },
    );
  }

  fetchAdjacent(profile: string, startTime: Date) {
    return this.retrieve<IBaseAdjacentBidEntity[]>(
      {
        profile,
        startTime: Time.formatISODate(startTime),
      },
      {
        controllerResource: 'adjacentBids',
        skipSerializer: true,
      },
    );
  }

  bulkCreate(bids) {
    return this.create(bids, {
      controllerResource: 'externalStaffingCandidateBidBulkCreate',
      skipSerializer: true,
    });
  }
}
