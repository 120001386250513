import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IListingIdentifierStatsFeatureState } from './interfaces';
import { paginationAdapter } from './listing-identifier-stats.adapter';
import { featureKey } from './listing-identifier-stats.reducer';

const selectListingIdentifierStatsState =
  createFeatureSelector<IListingIdentifierStatsFeatureState>(featureKey);

export const selectListingIdentifierStatsEntityState = createSelector(
  selectListingIdentifierStatsState,
  (state) => state.entityState,
);

export const listingIdentifierStatsPaginationSelectors = paginationAdapter.paginationSelectors(
  selectListingIdentifierStatsState,
  null,
  'pagination',
  'entityState',
  false,
);
