import { inject, Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginatedStateService } from '@locumsnest/core/src';

import { IBankHolidayEntity } from '../../interfaces/api/bank-holiday-entity';
import {
  bankHolidayPaginationMessages,
  ResetBankHolidayPaginationMessage,
  SetCollectionMessage,
  UpsertBankHolidayPageMessage,
  UpsertMultipleMessage,
} from './bank-holiday.messages';
import { BankHolidayPersistenceService } from './bank-holiday.persistence.service';
import {
  bankHolidayPaginationSelectors,
  selectAllBankHolidays,
  selectBankHoliday,
  selectBankHolidayEntityState,
} from './bank-holiday.selectors';

@Injectable({
  providedIn: 'root',
})
export class BankHolidayService extends PaginatedStateService<
  IBankHolidayEntity,
  UpsertBankHolidayPageMessage,
  ResetBankHolidayPaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(BankHolidayPersistenceService);

  get paginationMessages() {
    return bankHolidayPaginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return bankHolidayPaginationSelectors;
  }

  get entityStateSelector() {
    return selectBankHolidayEntityState;
  }

  getAll(): Observable<IBankHolidayEntity[]> {
    return this.store.pipe(select(selectAllBankHolidays));
  }

  getOne(id: number) {
    return this.store.pipe(select(selectBankHoliday(id)));
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }
}
