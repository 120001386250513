import { cloneDeep, isNil } from 'lodash-es';
import {
  addArrayControl,
  AddArrayControlAction,
  addGroupControl,
  box,
  Boxed,
  clearAsyncError,
  createFormArrayState,
  createFormGroupState,
  createFormStateReducerWithUpdate,
  disable,
  DisableAction,
  enable,
  EnableAction,
  FormControlState,
  FormGroupState,
  markAsDirty,
  markAsPristine,
  removeArrayControl,
  RemoveArrayControlAction,
  removeGroupControl,
  setAsyncError,
  setUserDefinedProperty,
  SetUserDefinedPropertyAction,
  setValue,
  SetValueAction,
  unbox,
  updateArray,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { maxLength, minLength, pattern, required } from 'ngrx-forms/validation';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { parseMoney } from '@locumsnest/core/src/lib/helpers/util';
import {
  getControl,
  getLastArrayControl,
  setUserDefinedProperties,
} from '@locumsnest/core/src/lib/ngrx/helpers/ngrx-forms/util';
import {
  maxArrayLength,
  minArrayLength,
  minTrimmedLength,
  trimmedRequired,
} from '@locumsnest/core/src/lib/ngrx/validator';
import {
  CURRENCY_REGEX,
  CURRENCY_REGEX_FROM_ZERO,
  DATE_TIME_PICKER_FORMAT,
  EMAIL_REGEX,
} from '@locumsnest/core/src/lib/types/constants';

import { IJobListingEntityWithRateDefinitions } from '../../../interfaces/api/job-listing-entity';
import {
  Currency,
  IFlatRatePayRateType,
  IPayRate,
  ITimeBasedPayRateType,
} from '../../../interfaces/pay-rate-types';
import {
  IEmailFormState,
  IFlatRateFormState,
  IGradesSectionFormState,
  IJobFragmentFormState,
  IJobListingFormWizardState,
  IJobListingGradeFormState,
  IProfessionSpecialtyFormState,
  IShiftCreationFormState,
  IShiftSchedulerFormState,
  ITimeFragmentFormState,
} from '../interfaces';
import { createJobListingFormMessages } from './form.messages.adapter';
import {
  generateGradeTimeFragmentIds,
  generateNewGradeState,
  getEntityGrades,
} from './form.selectors';
import { FORM_UDP, IJobListingFormUserDefinedProperties } from './form.user-defined-properties';
import { copyIdsToNewState } from './form.util';
import {
  hasValidApplicationDeadline,
  hasValidEmploymentPeriod,
  isRequiredDropdownOption,
  isValidDate,
} from './form.validators';

export function getFormId(feature) {
  return (feature + 'Form').toUpperCase().replace(/ /g, '_');
}
const INITIAL_FORM_STATE_USER_DEFINED_PROPERTY = 'initialFormState';
export const FORM_ID = 'JOBLISTINGFORM';
export const SHIFT_SCHEDULER_FORM_ID = `${FORM_ID}.shiftScheduler`;
export const SHIFT_CREATION_FORM_ID = `${FORM_ID}.shiftCreation`;
export const GRADES_FORM_ID = `${FORM_ID}.gradesSection`;
export const PROFESSION_SPECIALTY_CONTROL_ID = `${SHIFT_CREATION_FORM_ID}.professionSpecialty`;
export const NON_RESIDENT_ON_CALL_CONTROL_ID = `${SHIFT_CREATION_FORM_ID}.nonResidentOnCall`;

// eslint-disable-next-line max-len
export const CROSS_COVERING_PROFESSION_SPECIALTIES_FROM_ID = `${SHIFT_CREATION_FORM_ID}.crossCoveringProfessionSpecialties`;

export const MAX_REPETITION_DATES = 62;

const CROSS_COVERING_PROFESSION_CONTROL_ID_REGEX = new RegExp(
  `^${CROSS_COVERING_PROFESSION_SPECIALTIES_FROM_ID}.\\d+.profession$`.replace(/\./g, '\\.'),
);
const CROSS_COVERING_PROFESSION_SPECIALTY_ARRAY_CONTROL_ID_REGEX = new RegExp(
  `^${CROSS_COVERING_PROFESSION_SPECIALTIES_FROM_ID}.\\d+.professionSpecialties$`.replace(
    /\./g,
    '\\.',
  ),
);
const setApprovedRateListings = (
  state: FormGroupState<IJobListingFormWizardState>,
  payload: {
    approvedRateListings: IJobListingEntityWithRateDefinitions<Date, number, number, number>[];
  },
) => {
  const initialFormState: IJobListingFormWizardState =
    state.userDefinedProperties['initialFormState'];
  if (payload.approvedRateListings.length) {
    const [baseListing, ...repetitions] = payload.approvedRateListings;
    const approvedRateListings = [copyIdsToNewState(baseListing, initialFormState), ...repetitions];
    return setUserDefinedProperty(FORM_UDP.rateCardRateListings, approvedRateListings)(state);
  }
  return setUserDefinedProperty(FORM_UDP.rateCardRateListings, payload.approvedRateListings)(state);
};

export const getInitialFormState = (formId) =>
  createFormGroupState<IJobListingFormWizardState>(formId, {
    shiftCreation: {
      id: null,
      templateId: null,
      title: '',
      listingType: 0,
      profession: null,
      professionSpecialty: null,
      site: null,
      details: '',
      detailsChange: false,
      reasonForVacancy: null,
      extraEmails: [],
      file: '',
      pensionCategory: null,
      externalJobListingId: null,
      reasonForVacancyChange: false,
      jobListingNotes: null,
      copyNoteAcrossRepetitionDate: true,
      crossCoveringProfessionSpecialties: [],
      nonResidentOnCall: false,
    },
    shiftScheduler: {
      costCentreNumber: null,
      costCentreNumberChange: false,
      startTime:
        Time.getMoment().startOf('date').format(DATE_TIME_PICKER_FORMAT).split('T')[0] + 'T',
      endTime: Time.getMoment().startOf('date').format(DATE_TIME_PICKER_FORMAT).split('T')[0] + 'T',
      hasCustomApplicationDeadLine: false,
      timeFragments: {},
      availablePositions: 1,
      repetitionDates: box([]),
      remainingPositionsToFill: null,
      applicationDeadline:
        Time.getMoment().startOf('date').format(DATE_TIME_PICKER_FORMAT).split('T')[0] + 'T',
      isRepeating: false,
      extendedHours: false,
      consentBackdatedShifts: false,
    },
    gradesSection: {
      rateViolationReason: null,
      shiftEscalated: false,
      shiftEscalatedForAgencies: false,
      grades: [],
    },
  });

const INITIAL_FLAT_RATE: IFlatRateFormState = { rate: null, rateCurrency: 'GBP' };
const getInitialJobFragmentState = (timeFragmentId, payRateType) => ({
  timeFragment: timeFragmentId,
  payRate: {
    rate: null,
    rateCurrency: 'GBP' as Currency,
    nonResidentCalloutRate: null,
    nonResidentCalloutRateCurrency: null,
    payRateType,
  },
});
const getUSerDefinedProperties = (formState: FormGroupState<IJobListingFormWizardState>) =>
  formState.userDefinedProperties as IJobListingFormUserDefinedProperties;
const getRateCardListing = (formState: FormGroupState<IJobListingFormWizardState>) => {
  const { rateCardRateListings } = getUSerDefinedProperties(formState);
  if (!(rateCardRateListings && rateCardRateListings.length)) {
    return;
  }
  return rateCardRateListings[0];
};
const getRateCardListingGrade = (
  formState: FormGroupState<IJobListingFormWizardState>,
  gradeId: number,
) => {
  const listing = getRateCardListing(formState);
  if (!listing) {
    return;
  }
  return listing.grades.find(({ grade }) => grade === gradeId);
};
const updateGradeJobFragments = (
  jobFragments: IJobFragmentFormState[],
  timeFragments: { [id: string]: ITimeFragmentFormState },
  payRateType: number,
): IJobFragmentFormState[] =>
  Object.keys(timeFragments)
    .map((key) => {
      const results = jobFragments.filter(
        (jf) => jf.timeFragment === key || jf.timeFragment === parseInt(key, 10),
      );
      if (!results.length) return getInitialJobFragmentState(key, payRateType);
      return results[0];
    })
    .filter((jf) => timeFragments[jf.timeFragment]);

const getInitialGradeState = (
  grade: number,
  timeFragments: { [id: string]: ITimeFragmentFormState },
  defaultPayRateType: number,
): IJobListingGradeFormState => ({
  grade,
  flatRate: INITIAL_FLAT_RATE,
  jobFragments: updateGradeJobFragments([], timeFragments, defaultPayRateType),
});
const removeInsufficientRateConfigurationError = <T>(state: FormGroupState<T>) =>
  clearAsyncError('insufficientRateCardConfiguration')(state);

const addInsufficientRateConfigurationError = <T>(state: FormGroupState<T>) =>
  setAsyncError('insufficientRateCardConfiguration', true)(state);

const getNewExtraEmail = (): IEmailFormState => ({
  name: '',
  email: '',
});

const validatePayRateGroupReducer = (
  allowZeroRates: boolean,
  requireNonResidentCalloutRate: boolean,
) =>
  updateGroup<IPayRate<ITimeBasedPayRateType>>({
    payRateType: validate<ITimeBasedPayRateType | number>([required]),
    rate: validate<string>([
      required,
      pattern(allowZeroRates ? CURRENCY_REGEX_FROM_ZERO : CURRENCY_REGEX),
    ]),
    rateCurrency: validate<string>([required]),
    nonResidentCalloutRate: validate<string>([
      pattern(CURRENCY_REGEX),
      ...(requireNonResidentCalloutRate ? [required, pattern(CURRENCY_REGEX)] : []),
    ]),
    nonResidentCalloutRateCurrency: validate<string>([]),
  });

const validateJobFragmentGroupReducer = (
  allowZeroRates: boolean,
  requireNonResidentCalloutRate: boolean,
) =>
  updateGroup<IJobFragmentFormState>(
    {
      timeFragment: validate<string | number>([required]),
      payRate: validatePayRateGroupReducer(allowZeroRates, requireNonResidentCalloutRate),
    },
    {
      payRate: (state) =>
        updateGroup<IPayRate<ITimeBasedPayRateType>>({
          nonResidentCalloutRateCurrency: (nonResidentCalloutRateCurrencyControl) =>
            state.value.nonResidentCalloutRate
              ? setValue('GBP')(nonResidentCalloutRateCurrencyControl)
              : setValue(null as string)(nonResidentCalloutRateCurrencyControl),
        })(state),
    },
  );

const validateFlatRateGroupReducer = updateGroup<IPayRate<IFlatRatePayRateType>>({
  payRateType: validate<IFlatRatePayRateType | number>([required]),
  rate: validate<string>([required, pattern(CURRENCY_REGEX)]),
  rateCurrency: validate<string>([required]),
  nonResidentCalloutRate: validate<string>([pattern(CURRENCY_REGEX)]),
});

const validateGradeGroupReducer =
  (requireNonResidentCalloutRate: boolean) => (state: FormGroupState<IJobListingGradeFormState>) =>
    updateGroup<IJobListingGradeFormState>({
      grade: validate<number>([required]),
      flatRate: validateFlatRateGroupReducer,
      jobFragments: updateArray<IJobFragmentFormState>(
        validateJobFragmentGroupReducer(
          state.value.flatRate?.rate &&
            (state.value.jobFragments.some((jf) => parseMoney(jf.payRate.rate)) ||
              requireNonResidentCalloutRate),
          requireNonResidentCalloutRate,
        ),
      ),
    })(state);

const notificationEmailGroupReducer = updateGroup<IEmailFormState>({
  name: validate<string>([]),
  email: validate<string>([pattern(EMAIL_REGEX)]),
});

const crossCoveringProfessionSpecialtiesReducer = updateGroup<IProfessionSpecialtyFormState>({
  profession: validate<number>([]),
  professionSpecialties: validate<Boxed<number[]>>([minArrayLength(1, 'Profession Specialties')]),
});
const validateShiftCreation = updateGroup<IShiftCreationFormState>({
  title: validate<string>([required]),
  listingType: validate<number>([required]),
  details: validate<string>([trimmedRequired, minTrimmedLength(19)]),
  file: validate<string>([]),
  extraEmails: updateArray<IEmailFormState>([notificationEmailGroupReducer]),
  crossCoveringProfessionSpecialties: updateArray<IProfessionSpecialtyFormState>([
    crossCoveringProfessionSpecialtiesReducer,
  ]),
  reasonForVacancy: (reasonForVacancy, state) =>
    validate<number>([isRequiredDropdownOption(state, 'reasonForVacancy')])(reasonForVacancy),
  site: (site, state) => validate<number>([isRequiredDropdownOption(state, 'site')])(site),
  professionSpecialty: (professionSpecialty, state) =>
    validate<number>([isRequiredDropdownOption(state, 'professionSpecialty')])(professionSpecialty),
  jobListingNotes: validate<string>([minLength(1), maxLength(2048)]),
});

const validateShiftScheduler = updateGroup<IShiftSchedulerFormState>({
  availablePositions: validate<number>([required]),
  applicationDeadline: (state, parentState) =>
    validate(state, hasValidApplicationDeadline(parentState)),
  startTime: (startTime, state) =>
    validate<string>([isValidDate(startTime.value), hasValidEmploymentPeriod(state), required])(
      startTime,
    ),
  endTime: (endTime, state) =>
    validate<string>([isValidDate(endTime.value), hasValidEmploymentPeriod(state), required])(
      endTime,
    ),
  repetitionDates: (repetitionDates, state) => {
    if (state.value.isRepeating) {
      return validate<Boxed<string[]>>([
        maxArrayLength(MAX_REPETITION_DATES, 'selections'),
        required,
      ])(repetitionDates);
    }
    return validate<Boxed<string[]>>([])(repetitionDates);
  },
  costCentreNumber: validate<string>([required]),
});

const validateGradesSection = (requireNonResidentCalloutRate: boolean) =>
  updateGroup<IGradesSectionFormState>(
    {
      grades: updateArray<IJobListingGradeFormState>([
        validateGradeGroupReducer(requireNonResidentCalloutRate),
      ]),
      rateViolationReason: (state, parentState) =>
        validate(state, () => {
          if (parentState.userDefinedProperties.gradesApprovalRatesViolation && !state.value)
            return { gradesApprovalRatesViolation: true };
          return null;
        }),
    },
    // {
    //   grades: (state) => {
    //     const calloutRates = flatMap(state.value, (grade) =>
    //       grade.jobFragments.map((jf) => jf?.payRate?.nonResidentCalloutRate),
    //     );
    //     return updateArray<IJobListingGradeFormState>([
    //       updateGroup<IJobListingGradeFormState>({
    //         jobFragments: updateArray<IJobFragmentFormState>(
    //           updateGroup<IJobFragmentFormState>({
    //             payRate: updateGroup<IPayRate<ITimeBasedPayRateType>>({
    //               nonResidentCalloutRate: validate(
    //                 calloutRates.some((cr) => cr !== null && cr !== '')
    //                   ? [required, pattern(CURRENCY_REGEX)]
    //                   : [],
    //               ),
    //             }),
    //           }),
    //         ),
    //       }),
    //     ])(state);
    //   },
    // },
  );

const formValidationReducer = (formState: FormGroupState<IJobListingFormWizardState>) =>
  updateGroup<IJobListingFormWizardState>({
    shiftCreation: validateShiftCreation,
    shiftScheduler: validateShiftScheduler,
    gradesSection: validateGradesSection(formState.value.shiftCreation.nonResidentOnCall),
  })(formState);

export const formStateReducer =
  createFormStateReducerWithUpdate<IJobListingFormWizardState>(formValidationReducer);

export function createReducer<T>(
  messageMap: ReturnType<typeof createJobListingFormMessages>,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  FORM_ID: string,
  INITIAL_FORM_STATE: FormGroupState<IJobListingFormWizardState>,
) {
  const {
    InitializeJobListingFormMessage,
    AddJobListingGradeMessage,
    EnableJobListingGradeMessage,
    EnableProfessionGradesMessage,
    DisableProfessionGradesMessage,
    DisableNonPrimaryProfessionGradesMessage,
    DisableJobListingGradeMessage,
    ClearJobListingGradeMessage,
    SetDefaultApplicationDeadlineMessage,
    AddExtraEmailMessage,
    DeleteExtraEmailMessage,
    ClearExtraEmailsMessage,
    DisableFlatRateMessage,
    UpdateGradeJobFragmentsMessage,
    AddTimeFragmentMessage,
    EnableFlatRateMessage,
    RemoveTimeFragmentMessage,
    UpdateGradeFlatRatesMessage,
    ResetGradeJobFragmentsRatesMessage,
    AcceptWarningForGradeRateCeilingMessage,
    RemoveFileMessage,
    UpdateTimeFragmentMessage,
    SetExtendedHoursMessage,
    ChangeRemainingPositionsToFillMessage,
    ResetJobListingFormMessage,
    RemoveJobListingGradesMessage,
    ResetProfessionDetailsMessage,
    ResetCostCentreNumberMessage,
    DetailsChangeMessage,
    CostCentreNumberChangeMessage,
    ReasonForVacancyChangeMessage,
    SetCostCentreNumberMessage,
    ClearJobListingNotesMessage,
    UpdateJobFragmentRateMessage,
    SetRateCardRatesMessage,
  } = messageMap;

  const updateListingFormState = (
    state: FormGroupState<IJobListingFormWizardState>,
    jobListingFormState: IJobListingFormWizardState,
  ) => {
    const { userDefinedProperties } = state;
    state = createFormGroupState<IJobListingFormWizardState>(FORM_ID, jobListingFormState);
    return { ...state, userDefinedProperties };
  };

  /**
   * Reducer used to perform actions on grades of a given
   * profession. Can be plugged into main reducer
   * if we want to send  such messages from effects
   *
   * @param {FormGroupState<IJobListingFormWizardState>} state
   * @param {(InstanceType<typeof EnableProfessionGradesMessage>
   *       | InstanceType<typeof DisableProfessionGradesMessage>)} action
   * @return {*}
   */
  function professionGradesReducer(
    state: FormGroupState<IJobListingFormWizardState>,
    action:
      | InstanceType<typeof EnableProfessionGradesMessage>
      | InstanceType<typeof DisableProfessionGradesMessage>
      | InstanceType<typeof DisableNonPrimaryProfessionGradesMessage>,
  ) {
    switch (action.type) {
      case EnableProfessionGradesMessage.TYPE:
        {
          // eslint-disable-next-line no-console
          console.log(`Enabling profession ${action.payload.profession}`);
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) => {
                  if (action.payload.profession === grade.userDefinedProperties.profession) {
                    return updateGroup<IJobListingGradeFormState>({
                      grade: enable,
                    })(grade);
                  }
                  return grade;
                })(grades),
            }),
          })(state);
        }
        break;
      case DisableProfessionGradesMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) => {
                  if (action.payload.profession === grade.userDefinedProperties.profession) {
                    return updateGroup<IJobListingGradeFormState>({
                      grade: disable,
                    })(grade);
                  }
                  return grade;
                })(grades),
            }),
          })(state);
        }
        break;
      case DisableNonPrimaryProfessionGradesMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) => {
                  if (
                    state.controls.shiftCreation.value.profession !==
                    grade.userDefinedProperties.profession
                  ) {
                    return updateGroup<IJobListingGradeFormState>({
                      grade: disable,
                    })(grade);
                  }
                  return grade;
                })(grades),
            }),
          })(state);
        }
        break;
    }
    return state;
  }
  function enableRepeatingBooking(state: FormGroupState<IJobListingFormWizardState>) {
    const { isRepeating } = state.controls.shiftScheduler.controls;
    if (isRepeating.isDisabled) {
      state = formStateReducer(state, new EnableAction(isRepeating.id));
    }
    return state;
  }
  function disableRepeatingBooking(state: FormGroupState<IJobListingFormWizardState>) {
    const { isRepeating } = state.controls.shiftScheduler.controls;
    if (isRepeating.value) {
      state = formStateReducer(state, new SetValueAction(isRepeating.id, false));
    }
    if (isRepeating.isEnabled) {
      state = formStateReducer(state, new DisableAction(isRepeating.id));
    }
    return state;
  }
  function handleProfessionSpecialtyChangeForCrossCoveringState(
    state: FormGroupState<IJobListingFormWizardState>,
    _controlId: string,
    newValue: number,
  ) {
    const { shiftCreation } = state.controls;
    const { crossCoveringProfessionSpecialties } = shiftCreation.controls;
    for (const fromGroup of crossCoveringProfessionSpecialties.controls) {
      const selectedProfessionSpecialties = unbox(fromGroup.value.professionSpecialties);
      const professionSpecialtyIndex = selectedProfessionSpecialties.indexOf(newValue);

      if (professionSpecialtyIndex > -1) {
        state = formStateReducer(
          state,
          new SetValueAction(
            fromGroup.controls.professionSpecialties.id,
            box(selectedProfessionSpecialties.filter((x) => x !== newValue)),
          ),
        );
      }
    }
    return state;
  }
  function handleCrossCoveringProfessionChange(
    state: FormGroupState<IJobListingFormWizardState>,
    controlId: string,
    newValue: number,
  ) {
    const currentProfession = getControl<IJobListingFormWizardState, FormControlState<number>>(
      state,
      FORM_ID,
      controlId,
    ).value;
    const isPrimaryProfession = state.value.shiftCreation.profession === currentProfession;
    if (!isPrimaryProfession && !isNil(currentProfession) && currentProfession !== newValue) {
      //ie if previous value exists and gets cancelled now disable its grades
      state = professionGradesReducer(
        state,
        new DisableProfessionGradesMessage({ profession: currentProfession }),
      );
    }
    if (isNil(currentProfession) && !isNil(newValue)) {
      // if new profession
      state = professionGradesReducer(
        state,
        new EnableProfessionGradesMessage({
          profession: newValue,
        }),
      );
    }
    return state;
  }
  type CrossCoveringActionTypes =
    | SetValueAction<string | number | IProfessionSpecialtyFormState[]>
    | RemoveArrayControlAction
    | AddArrayControlAction<IProfessionSpecialtyFormState>;
  /**
   * Responsible for synchronizing professions/grades for cross-covering
   *
   * @param {FormGroupState<IJobListingFormWizardState>} state
   * @param {CrossCoveringActionTypes} action
   * @return {*}
   */
  function crossCoveringReducer(
    state: FormGroupState<IJobListingFormWizardState>,
    action: CrossCoveringActionTypes,
  ) {
    switch (action.type) {
      case SetValueAction.TYPE: {
        if (action.controlId === PROFESSION_SPECIALTY_CONTROL_ID) {
          state = handleProfessionSpecialtyChangeForCrossCoveringState(
            state,
            action.controlId,
            action.value as number,
          );
        }
        if (CROSS_COVERING_PROFESSION_CONTROL_ID_REGEX.test(action.controlId)) {
          state = handleCrossCoveringProfessionChange(state, action.controlId, +action.value);

          state = formStateReducer(
            state,
            new SetValueAction(
              action.controlId.replace('.profession', '.professionSpecialties'),
              box([]),
            ),
          );
        }
        break;
      }
      case RemoveArrayControlAction.TYPE: {
        if (action.controlId === CROSS_COVERING_PROFESSION_SPECIALTIES_FROM_ID) {
          const { crossCoveringProfessionSpecialties } = state.controls.shiftCreation.controls;
          const isLastControlRemoval =
            crossCoveringProfessionSpecialties.controls.length === 1 &&
            crossCoveringProfessionSpecialties.controls[0].id ===
              `${action.controlId}.${action.index}`;

          if (!crossCoveringProfessionSpecialties.controls.length || isLastControlRemoval) {
            state = enableRepeatingBooking(state);
          }
          state = handleCrossCoveringProfessionChange(
            state,
            `${action.controlId}.${action.index}.profession`,
            null,
          );
        }
      }
    }
    return state;
  }
  function crossCoveringAfterUpdateReducer(
    state: FormGroupState<IJobListingFormWizardState>,
    action: CrossCoveringActionTypes,
  ) {
    switch (action.type) {
      case SetValueAction.TYPE:
      case AddArrayControlAction.TYPE:
      case RemoveArrayControlAction.TYPE: {
        if (action.controlId === CROSS_COVERING_PROFESSION_SPECIALTIES_FROM_ID) {
          const { crossCoveringProfessionSpecialties } = state.controls.shiftCreation.controls;
          if (crossCoveringProfessionSpecialties.value.length > 0) {
            state = disableRepeatingBooking(state);
          } else {
            state = enableRepeatingBooking(state);
            state = professionGradesReducer(state, new DisableNonPrimaryProfessionGradesMessage());
          }
          return state;
        }
        break;
      }
    }
    return state;
  }
  return function reducer(
    state: FormGroupState<IJobListingFormWizardState> = INITIAL_FORM_STATE,
    action:
      | InstanceType<(typeof messageMap)[keyof typeof messageMap]>
      | SetValueAction<string | number>
      | RemoveArrayControlAction,
  ) {
    const initialState = state;
    switch (action.type) {
      case InitializeJobListingFormMessage.TYPE:
        {
          const { payload } = action as InstanceType<typeof InitializeJobListingFormMessage>;
          const { listingGradeProfessions } = payload;
          state = updateListingFormState(state, payload.jobListingFormState);
          if (payload.userDefinedProperties) {
            state = setUserDefinedProperties(payload.userDefinedProperties)(state);
          }
          for (let i = 0; i < listingGradeProfessions.length; i++) {
            state = formStateReducer(
              state,
              new SetUserDefinedPropertyAction(
                `${GRADES_FORM_ID}.grades.${i}`,
                'profession',
                listingGradeProfessions[i],
              ),
            );
          }
          if (payload.hospitalSector) {
            state = setUserDefinedProperty(
              'hospitalSectorCode',
              payload.hospitalSector.code,
            )(state);
          }

          if (payload.globalDescription && !state.value.shiftCreation.detailsChange) {
            state = updateGroup<IJobListingFormWizardState>({
              shiftCreation: updateGroup<IShiftCreationFormState>({
                details: (details) => setValue(payload.globalDescription)(details),
              }),
            })(state);
          }

          if (
            payload.globalCostCentreNumber &&
            !state.value.shiftScheduler.costCentreNumberChange
          ) {
            state = updateGroup<IJobListingFormWizardState>({
              shiftScheduler: updateGroup<IShiftSchedulerFormState>({
                costCentreNumber: (costCentreNumber) =>
                  setValue(payload.globalCostCentreNumber)(costCentreNumber),
              }),
            })(state);
          }

          if (payload.globalReasonForVacancy && !state.value.shiftCreation.reasonForVacancyChange) {
            state = updateGroup<IJobListingFormWizardState>({
              shiftCreation: updateGroup<IShiftCreationFormState>({
                reasonForVacancy: (reasonForVacancy) =>
                  setValue(payload.globalReasonForVacancy)(reasonForVacancy),
              }),
            })(state);
          }

          if (payload.fromTemplate) {
            state = updateGroup<IJobListingFormWizardState>({
              shiftCreation: updateGroup<IShiftCreationFormState>({
                details: (details) => markAsDirty(details),
              }),
            })(state);
          } else {
            state = setUserDefinedProperty(
              INITIAL_FORM_STATE_USER_DEFINED_PROPERTY,
              payload.jobListingFormState,
            )(state);
          }
        }
        break;
      case SetValueAction.TYPE:
        {
          if (action.controlId === NON_RESIDENT_ON_CALL_CONTROL_ID) {
            if (!action.value) {
              state = updateGroup<IJobListingFormWizardState>({
                gradesSection: updateGroup<IGradesSectionFormState>({
                  grades: updateArray<IJobListingGradeFormState>([
                    updateGroup<IJobListingGradeFormState>({
                      jobFragments: updateArray<IJobFragmentFormState>(
                        updateGroup<IJobFragmentFormState>({
                          payRate: updateGroup<IPayRate<ITimeBasedPayRateType>>({
                            nonResidentCalloutRate: setValue(null as string),
                            nonResidentCalloutRateCurrency: setValue(null as string),
                          }),
                        }),
                      ),
                    }),
                  ]),
                }),
              })(state);
            }
          }
        }
        break;
      case ResetJobListingFormMessage.TYPE:
        {
          state = updateListingFormState(
            state,
            state.userDefinedProperties[INITIAL_FORM_STATE_USER_DEFINED_PROPERTY],
          );
        }
        break;
      case AddJobListingGradeMessage.TYPE:
        {
          const rateCardRateGrade = getRateCardListingGrade(state, action.payload.grade);
          const gradeState = rateCardRateGrade
            ? getEntityGrades(
                generateGradeTimeFragmentIds(
                  [rateCardRateGrade],
                  state.value.shiftScheduler.timeFragments,
                ),
              )[0]
            : getInitialGradeState(
                action.payload.grade,
                state.value.shiftScheduler.timeFragments,
                action.payload.defaultTimeBasedPayRateType,
              );
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) => addArrayControl<IJobListingGradeFormState>(gradeState)(grades),
            }),
          })(state);
          if (!gradeState.flatRate?.rate) {
            state = reducer(state, new DisableFlatRateMessage({}));
          } else {
            state = reducer(state, new EnableFlatRateMessage({}));
          }
          state = formStateReducer(
            state,
            new SetUserDefinedPropertyAction(
              getLastArrayControl(state.controls.gradesSection.controls.grades).id,
              'profession',
              action.payload.profession,
            ),
          );
        }
        break;
      case ClearJobListingGradeMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                removeArrayControl(
                  state.controls.gradesSection.controls.grades.value.findIndex(
                    (grade) =>
                      grade.grade ===
                      (action as InstanceType<typeof ClearJobListingGradeMessage>).payload.grade,
                  ),
                )(grades),
            }),
          })(state);
        }
        break;

      case AddExtraEmailMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftCreation: updateGroup<IShiftCreationFormState>({
              extraEmails: (extraEmails) =>
                addArrayControl<IEmailFormState>(getNewExtraEmail())(extraEmails),
            }),
          })(state);
        }
        break;

      case DeleteExtraEmailMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftCreation: updateGroup<IShiftCreationFormState>({
              extraEmails: (extraEmails) =>
                removeArrayControl(
                  (action as InstanceType<typeof DeleteExtraEmailMessage>).payload.emailIndex,
                )(extraEmails),
            }),
          })(state);
        }
        break;

      case ClearExtraEmailsMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftCreation: updateGroup<IShiftCreationFormState>({
              extraEmails: ({ id }) => createFormArrayState<IEmailFormState>(id, []),
            }),
          })(state);
        }
        break;

      case RemoveFileMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftCreation: updateGroup<IShiftCreationFormState>({
              file: (file) => setValue('')(file),
            }),
          })(state);
        }
        break;

      case EnableJobListingGradeMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) => {
                  if (action.payload.grade === grade.value.grade) {
                    return updateGroup<IJobListingGradeFormState>({
                      grade: enable,
                    })(grade);
                  }
                  return grade;
                })(grades),
            }),
          })(state);
          if (
            action.payload.skipFlatRate &&
            !state.userDefinedProperties[FORM_UDP.useRateCardRates]
          ) {
            state = reducer(state, new DisableFlatRateMessage({}));
          }
        }
        break;

      case DisableJobListingGradeMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>([
            {
              gradesSection: updateGroup<IGradesSectionFormState>({
                grades: (grades) =>
                  ((gradeArray) => {
                    let index = -1;
                    let gradeIndex = -1;
                    let isValid = true;

                    const newArrayState = updateArray<IJobListingGradeFormState>((grade) => {
                      index++;

                      if (
                        (action as InstanceType<typeof DisableJobListingGradeMessage>).payload
                          .grade === grade.value.grade
                      ) {
                        isValid = grade.isValid;
                        gradeIndex = index;
                        return updateGroup<IJobListingGradeFormState>({
                          grade: disable,
                        })(grade);
                      }
                      return grade;
                    })(gradeArray);

                    return gradeIndex > -1 && !isValid
                      ? removeArrayControl(gradeIndex)(newArrayState)
                      : newArrayState;
                  })(grades),
              }),
            },
          ])(state);
        }
        break;

      case RemoveJobListingGradesMessage.TYPE:
        {
          const { grades: gradesToRemove } = (
            action as InstanceType<typeof RemoveJobListingGradesMessage>
          ).payload;

          state = updateGroup<IJobListingFormWizardState>([
            {
              gradesSection: updateGroup<IGradesSectionFormState>({
                grades: (grades) =>
                  ((gradeArray) => {
                    let index = -1;
                    let newState = gradeArray;
                    updateArray<IJobListingGradeFormState>((grade) => {
                      index++;
                      if (gradesToRemove.indexOf(grade.value.grade) > -1) {
                        newState = removeArrayControl(index)(newState);
                        index--;
                        return grade;
                      }
                    })(gradeArray);
                    return newState;
                  })(grades),
              }),
            },
          ])(state);
        }
        break;

      case ResetProfessionDetailsMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftCreation: updateGroup<IShiftCreationFormState>({
              professionSpecialty: setValue(null as number),
            }),
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              costCentreNumber: setValue(null as string),
            }),
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) => {
                let newState = grades;
                updateArray<IJobListingGradeFormState>((grade) => {
                  newState = removeArrayControl(0)(newState);
                  return grade;
                })(grades);
                return newState;
              },
            }),
          })(state);
        }
        break;

      case ResetCostCentreNumberMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              costCentreNumber: setValue(null as string),
            }),
          })(state);
        }
        break;
      case UpdateJobFragmentRateMessage.TYPE:
        {
          const {
            timeFragmentId,
            rate,
            gradeId,
            nonResidentCalloutRate,
            nonResidentCalloutRateCurrency,
          } = (action as InstanceType<typeof UpdateJobFragmentRateMessage>).payload;
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) => {
                  if (grade.value.grade === gradeId)
                    return updateGroup<IJobListingGradeFormState>({
                      jobFragments: (jobFragments) =>
                        updateArray<IJobFragmentFormState>((jobFragment) => {
                          if (jobFragment.value.timeFragment === timeFragmentId)
                            return updateGroup<IJobFragmentFormState>({
                              payRate: updateGroup<IPayRate<ITimeBasedPayRateType>>({
                                rate: setValue(rate),
                                nonResidentCalloutRate: setValue(nonResidentCalloutRate),
                                nonResidentCalloutRateCurrency: setValue(
                                  nonResidentCalloutRateCurrency,
                                ),
                              }),
                            })(jobFragment);
                          return jobFragment;
                        })(jobFragments),
                    })(grade);
                  return grade;
                })(grades),
            }),
          })(state);
        }
        break;

      case AcceptWarningForGradeRateCeilingMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) => {
                  if (
                    grade.value.grade ===
                    (action as InstanceType<typeof AcceptWarningForGradeRateCeilingMessage>).payload
                      .grade
                  ) {
                    return updateGroup<IJobListingGradeFormState>({
                      jobFragments: (jobFragments) =>
                        updateArray<IJobFragmentFormState>((jobFragment) =>
                          updateGroup<IJobFragmentFormState>({
                            payRate: (payRate) =>
                              updateGroup<IPayRate<ITimeBasedPayRateType>>({
                                rate: (rate) =>
                                  setUserDefinedProperty(
                                    'needsConfirmCeilingViolation',
                                    false,
                                  )(rate),
                              })(payRate),
                          })(jobFragment),
                        )(jobFragments),
                    })(grade);
                  }
                  return grade;
                })(grades),
            }),
          })(state);
        }
        break;

      case ResetGradeJobFragmentsRatesMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) => {
                  if (
                    grade.value.grade ===
                    (action as InstanceType<typeof ResetGradeJobFragmentsRatesMessage>).payload
                      .grade
                  ) {
                    return updateGroup<IJobListingGradeFormState>({
                      jobFragments: (jobFragments) =>
                        updateArray<IJobFragmentFormState>((jobFragment) => {
                          const { ceiling } = (
                            action as InstanceType<typeof ResetGradeJobFragmentsRatesMessage>
                          ).payload;
                          if (!isNil(ceiling)) {
                            return updateGroup<IJobFragmentFormState>({
                              payRate: (payRate) =>
                                updateGroup<IPayRate<ITimeBasedPayRateType>>({
                                  rate: (rate) => setValue<string>(null)(rate),
                                })(payRate),
                            })(jobFragment);
                          }
                          return jobFragment;
                        })(jobFragments),
                    })(grade);
                  }
                  return grade;
                })(grades),
            }),
          })(state);
        }
        break;

      case SetDefaultApplicationDeadlineMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              applicationDeadline: (applicationDeadline) =>
                setValue(
                  (action as InstanceType<typeof SetDefaultApplicationDeadlineMessage>).payload
                    .defaultApplicationDeadline,
                )(applicationDeadline),
            }),
          })(state);
        }
        break;

      case DisableFlatRateMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) =>
                  updateGroup<IJobListingGradeFormState>({
                    flatRate: (flatRate) => {
                      const disabledFlatRate = disable(flatRate);
                      return updateGroup<IFlatRateFormState>({
                        rate: setValue<string | number | null>(null),
                      })(disabledFlatRate);
                    },
                  })(grade),
                )(grades),
            }),
          })(state);
        }
        break;

      case EnableFlatRateMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) =>
                  updateGroup<IJobListingGradeFormState>({
                    flatRate: enable,
                  })(grade),
                )(grades),
            }),
          })(state);
        }
        break;

      case UpdateGradeJobFragmentsMessage.TYPE:
        {
          const { payload } = action as InstanceType<typeof UpdateGradeJobFragmentsMessage>;
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) => {
                  const jobFragments = updateGradeJobFragments(
                    grade.value.jobFragments,
                    state.value.shiftScheduler.timeFragments,
                    payload.payRateType,
                  );
                  const newGrade: IJobListingGradeFormState = { ...grade.value, jobFragments };
                  return setValue(newGrade)(grade);
                })(grades),
            }),
          })(state);
        }
        break;

      case UpdateGradeFlatRatesMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: (grades) =>
                updateArray<IJobListingGradeFormState>((grade) => {
                  if (!grade.value.flatRate) {
                    const newGrade: IJobListingGradeFormState = {
                      ...grade.value,
                      flatRate: cloneDeep(INITIAL_FLAT_RATE),
                    };
                    return setValue(newGrade)(grade);
                  }
                  return grade;
                })(grades),
            }),
          })(state);
        }
        break;

      case AddTimeFragmentMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              timeFragments: (
                timeFragmentState: FormGroupState<{ [id: string]: ITimeFragmentFormState }>,
              ) => {
                const { payload } = action as InstanceType<typeof AddTimeFragmentMessage>;
                const { id, fromTime } = payload;
                const newTimeFragmentState = addGroupControl<{
                  [id: string]: ITimeFragmentFormState;
                }>(
                  id as string,
                  { id, fromTime } as ITimeFragmentFormState,
                )(timeFragmentState);

                return newTimeFragmentState;
              },
            }),
          })(state);
        }
        break;

      case UpdateTimeFragmentMessage.TYPE:
        {
          const { payload: timeFragmentPayload } = action as InstanceType<
            typeof UpdateTimeFragmentMessage
          >;
          state = updateGroup<IJobListingFormWizardState>({
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              timeFragments: (timeFragments) =>
                updateGroup<{ [id: string]: ITimeFragmentFormState }>({
                  [timeFragmentPayload.id]: (timeFragment) =>
                    updateGroup<ITimeFragmentFormState>({
                      fromTime: (fromTime) => setValue(timeFragmentPayload.fromTime)(fromTime),
                    })(timeFragment),
                })(timeFragments),
            }),
          })(state);
        }
        break;

      case RemoveTimeFragmentMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              timeFragments: (
                timeFragmentState: FormGroupState<{ [id: string]: ITimeFragmentFormState }>,
              ) => {
                const { payload } = action as InstanceType<typeof RemoveTimeFragmentMessage>;
                const { id } = payload;
                const newTimeFragmentState = removeGroupControl<{
                  [id: string]: ITimeFragmentFormState;
                }>(id as string)(timeFragmentState);
                return newTimeFragmentState;
              },
            }),
          })(state);
        }
        break;
      case SetExtendedHoursMessage.TYPE:
        {
          const val = (action as InstanceType<typeof SetExtendedHoursMessage>).payload.value;

          state = updateGroup<IJobListingFormWizardState>({
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              extendedHours: (extendedHours) => setValue(val)(extendedHours),
            }),
          })(state);
        }
        break;
      case ChangeRemainingPositionsToFillMessage.TYPE:
        {
          const delta = (action as InstanceType<typeof ChangeRemainingPositionsToFillMessage>)
            .payload.delta;
          let newRemainingPositionsToFill = state.value.shiftScheduler.remainingPositionsToFill;
          const availablePositions = state.value.shiftScheduler.availablePositions;

          newRemainingPositionsToFill = newRemainingPositionsToFill + delta;

          if (
            newRemainingPositionsToFill <= availablePositions &&
            newRemainingPositionsToFill >= 0
          ) {
            state = updateGroup<IJobListingFormWizardState>({
              shiftScheduler: updateGroup<IShiftSchedulerFormState>({
                remainingPositionsToFill: (remainingPositionsToFill) =>
                  setValue(newRemainingPositionsToFill)(remainingPositionsToFill),
              }),
            })(state);
          }
        }
        break;
      case DetailsChangeMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftCreation: updateGroup<IShiftCreationFormState>({
              detailsChange: (detailsChange) => setValue(true)(detailsChange),
            }),
          })(state);
        }
        break;
      case CostCentreNumberChangeMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              costCentreNumberChange: (costCentreNumberChange) =>
                setValue(true)(costCentreNumberChange),
            }),
          })(state);
        }
        break;
      case ReasonForVacancyChangeMessage.TYPE:
        {
          state = updateGroup<IJobListingFormWizardState>({
            shiftCreation: updateGroup<IShiftCreationFormState>({
              reasonForVacancyChange: (reasonForVacancyChange) =>
                setValue(true)(reasonForVacancyChange),
            }),
          })(state);
        }
        break;
      case SetCostCentreNumberMessage.TYPE:
        {
          const number = (action as InstanceType<typeof SetCostCentreNumberMessage>).payload
            .costCentreNumber;
          state = updateGroup<IJobListingFormWizardState>({
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              costCentreNumber: (costCentreNumber) => setValue(number)(costCentreNumber),
            }),
          })(state);
        }
        break;
      case ClearJobListingNotesMessage.TYPE: {
        state = updateGroup<IJobListingFormWizardState>({
          shiftCreation: updateGroup<IShiftCreationFormState>({
            jobListingNotes: (jobListingNotes) => {
              const newState = setValue(jobListingNotes, null);
              return markAsPristine(newState);
            },
          }),
        })(state);
        break;
      }
      case SetRateCardRatesMessage.TYPE: {
        const { payload } = action;
        const { commonApprovedRateGrades, gradesWithDifferentBreakDownExist } = payload;
        state = setApprovedRateListings(state, payload);
        state = setUserDefinedProperty(
          FORM_UDP.rateCardRateGrades,
          commonApprovedRateGrades,
        )(state);
        state = setUserDefinedProperty(
          FORM_UDP.gradesWithDifferentBreakDownExist,
          gradesWithDifferentBreakDownExist,
        )(state);
        state = setUserDefinedProperty(FORM_UDP.rateCardRatesRecalculated, true)(state);
        const rateCardListing = getRateCardListing(state);
        const { grades: existingGrades } = state.value.gradesSection;
        if (rateCardListing) {
          const newGradeState = generateNewGradeState(rateCardListing.grades);

          state = updateGroup<IJobListingFormWizardState>({
            gradesSection: updateGroup<IGradesSectionFormState>({
              grades: setValue(
                newGradeState.gradesSection.grades.filter(({ grade: rateCadGrade }) =>
                  existingGrades.find(({ grade }) => rateCadGrade === grade),
                ) as IJobListingGradeFormState<IJobFragmentFormState<string | number>>[],
              ),
            }),
            shiftScheduler: updateGroup<IShiftSchedulerFormState>({
              timeFragments: setValue(newGradeState.shiftScheduler.timeFragments),
            }),
          })(state);
          const hasFlatRate = state.value.gradesSection.grades.some(
            ({ flatRate }) => flatRate?.rate,
          );
          if (hasFlatRate) {
            state = reducer(state, new EnableFlatRateMessage({}));
          } else {
            state = reducer(state, new DisableFlatRateMessage({}));
          }

          state = removeInsufficientRateConfigurationError(
            updateGroup<IJobListingFormWizardState>({
              gradesSection: removeInsufficientRateConfigurationError,
              shiftScheduler: removeInsufficientRateConfigurationError,
            })(state),
          );
        } else {
          state = addInsufficientRateConfigurationError(
            updateGroup<IJobListingFormWizardState>({
              gradesSection: addInsufficientRateConfigurationError,
              shiftScheduler: addInsufficientRateConfigurationError,
            })(state),
          );
        }
        break;
      }
    }
    state = crossCoveringReducer(state, action as CrossCoveringActionTypes);
    state = formStateReducer(state, action);
    state = crossCoveringAfterUpdateReducer(state, action as CrossCoveringActionTypes);
    if (initialState !== state) {
      state = formValidationReducer(state);
    }
    return state;
  };
}
