import { IFilterValues } from '../../../interfaces/api/filter-views-entity';
import { IJobListingEntity } from '../../../interfaces/api/job-listing-entity';
import { IJobListingSearchFilterFormState } from '../interfaces/job-listing-search-filter-form-state';
import { messageableFactory as actionableFactory } from './search-filter-form.adapter';

export class InitializeJobListingSearchFilterFormMessage extends actionableFactory.create<
  'Initialize JobListing Search Filter Form',
  { jobListingSearchFilterFormState: IJobListingSearchFilterFormState }
>('Initialize JobListing Search Filter Form') {}

export class UpdateSelectedPageMessage extends actionableFactory.create<
  'Update Selected Page Message',
  { selectedPage: number }
>('Update Selected Page Message') {}

export class AddSelectedJobListingMessage extends actionableFactory.create<
  'Add Selected Job Listing Message',
  { id: number }
>('Add Selected Job Listing Message') {}

export class RemoveSelectedJobListingMessage extends actionableFactory.create<
  'Remove Selected JobListing Message',
  { id: number }
>('Remove Selected JobListing Message') {}

export class SelectAllCurrentPageMessage extends actionableFactory.create<
  'Select All Current Page Message',
  { jobListings: IJobListingEntity[] }
>('Select All Current Page Message') {}

export class UnselectAllCurrentPageMessage extends actionableFactory.create<
  'Unselect All Current Page Message',
  { jobListings: IJobListingEntity[] }
>('Unselect All Current Page Message') {}

export class ClearSelectedJobListingListMessage extends actionableFactory.create<
  'Clear Selected JobListing List Message',
  Record<string, never>
>('Clear Selected JobListing List Message') {}

export class AddExpandedJobListingMessage extends actionableFactory.create<
  'Add Expanded JobListing Message',
  { id: number }
>('Add Expanded JobListing Message') {}

export class RemoveExpandedJobListingMessage extends actionableFactory.create<
  'Remove Expanded JobListing Message',
  { id: number }
>('Remove Expanded JobListing Message') {}

export class RemoveAllExpandedJobListingMessage extends actionableFactory.create<
  'Remove All Expanded JobListing Message',
  Record<string, never>
>('Remove All Expanded JobListing Message') {}

export class UpdateSortedFieldMessage extends actionableFactory.create<
  'Update Sorted Field Message',
  { sortedField: string }
>('Update Sorted Field Message') {}

export class ClearNotesMessage extends actionableFactory.create<
  'Clear Notes Message',
  Record<string, never>
>('Clear Notes Message') {}

export class UpdateFilterFormMessage extends actionableFactory.create<
  'Update Filter Form Message',
  { filters: Partial<IFilterValues> }
>('Update Filter Form Message') {}

export class ClearNotifyUsersMessage extends actionableFactory.create<
  'Clear Notify Users Message',
  Record<string, never>
>('Clear Notify Users Message') {}

export class UpdateListingGroupIdentifierMessage extends actionableFactory.create<
  'Update Listing Group Identifier Message',
  { listingGroupIdentifier: string }
>('Update Listing Group Identifier Message') {}

export type JobListingSearchFilterFormMessages =
  | InitializeJobListingSearchFilterFormMessage
  | UpdateSelectedPageMessage
  | AddSelectedJobListingMessage
  | RemoveSelectedJobListingMessage
  | SelectAllCurrentPageMessage
  | UnselectAllCurrentPageMessage
  | ClearSelectedJobListingListMessage
  | AddExpandedJobListingMessage
  | RemoveExpandedJobListingMessage
  | RemoveAllExpandedJobListingMessage
  | UpdateSortedFieldMessage
  | ClearNotesMessage
  | UpdateFilterFormMessage
  | ClearNotifyUsersMessage
  | UpdateListingGroupIdentifierMessage;
