import { createSelector } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';

import { IJobListingSearchFilterFormState, State } from '../interfaces';
import { selectJobListingState } from '../job-listing.selectors';

export const getSearchFilterFormState = (state: State) => state.searchFilterFormState;

const getJobListingsSelectedPage = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.selectedPage;

const getExpandedJobListings = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.expandedJobListings;

const getSearchKeyword = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.searchKeyword;

const getFromDate = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.fromDate;

const getToDate = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.toDate;

const getSortedField = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.sortedField;

const getPublished = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.published;

const getPendingOnly = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.pendingOnly;

const getListingType = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.listingType;

const getJobStatus = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.jobStatus;

const getGrade = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.grade;

const getCascaded = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.cascaded;

const getHasPrematch = (formState: FormGroupState<IJobListingSearchFilterFormState>) =>
  formState.value.hasPreMatchConversations;

export const selectJobListingSearchFilterFormState = createSelector(
  selectJobListingState,
  getSearchFilterFormState,
);

export const selectJobListingsSelectedPage = createSelector(
  selectJobListingSearchFilterFormState,
  getJobListingsSelectedPage,
);

export const selectSelectedJobListings = createSelector(
  selectJobListingSearchFilterFormState,
  (formState) => formState.value.selectedJobListings,
);

export const selectSelectedJobListingsCount = createSelector(
  selectSelectedJobListings,
  (selectedJobListings) => selectedJobListings?.length || 0,
);

export const selectExpandedJobListings = createSelector(
  selectJobListingSearchFilterFormState,
  getExpandedJobListings,
);

export const selectSearchKeyword = createSelector(
  selectJobListingSearchFilterFormState,
  getSearchKeyword,
);

export const selectFromDate = createSelector(selectJobListingSearchFilterFormState, getFromDate);

export const selectToDate = createSelector(selectJobListingSearchFilterFormState, getToDate);

export const selectSortedField = createSelector(
  selectJobListingSearchFilterFormState,
  getSortedField,
);

export const selectPublished = createSelector(selectJobListingSearchFilterFormState, getPublished);

export const selectPendingOnly = createSelector(
  selectJobListingSearchFilterFormState,
  getPendingOnly,
);

export const selectHasPrematch = createSelector(
  selectJobListingSearchFilterFormState,
  getHasPrematch,
);

export const selectShowAllTags = createSelector(
  selectJobListingSearchFilterFormState,
  (form) => form.value.showAllTags,
);

export const selectShowBlocks = createSelector(
  selectJobListingSearchFilterFormState,
  (form) => form.value.showBlocks,
);

export const selectTags = createSelector(
  selectJobListingSearchFilterFormState,
  (form) => form.value.tags,
);

export const selectListingType = createSelector(
  selectJobListingSearchFilterFormState,
  getListingType,
);

export const selectJobStatus = createSelector(selectJobListingSearchFilterFormState, getJobStatus);

export const selectGrade = createSelector(selectJobListingSearchFilterFormState, getGrade);

export const selectCascaded = createSelector(selectJobListingSearchFilterFormState, getCascaded);

export const selectApplicationStatus = createSelector(
  selectJobListingSearchFilterFormState,
  (formState) => formState.value.applicationStatus,
);

export const selectBidStatus = createSelector(
  selectJobListingSearchFilterFormState,
  (formState) => formState.value.bidStatus,
);

export const selectTier = createSelector(
  selectJobListingSearchFilterFormState,
  (formState) => formState.value.tier,
);

export const selectRatesLocked = createSelector(
  selectJobListingSearchFilterFormState,
  (formState) => formState.value.ratesLocked,
);

export const selectEscalated = createSelector(
  selectJobListingSearchFilterFormState,
  (searchFilterFormState) => searchFilterFormState.value.escalated,
);

export const selectNotesValue = createSelector(
  selectJobListingSearchFilterFormState,
  (state) => state.value.notes,
);

export const selectNotifyUsersValue = createSelector(
  selectJobListingSearchFilterFormState,
  (state) => state.value.notifyUsers,
);

export const selectNonResidentOnCall = createSelector(
  selectJobListingSearchFilterFormState,
  (state) => state.value.nonResidentOnCall,
);

export const selectHospital = createSelector(
  selectJobListingSearchFilterFormState,
  (state) => state.value.hospital,
);

export const selectSite = createSelector(
  selectJobListingSearchFilterFormState,
  (state) => state.value.site,
);

export const selectDirectEngagement = createSelector(
  selectJobListingSearchFilterFormState,
  (state) => state.value.directEngagement,
);

export const selectProfession = createSelector(
  selectJobListingSearchFilterFormState,
  (state) => state.value.profession,
);

export const selectSpecialty = createSelector(
  selectJobListingSearchFilterFormState,
  (state) => state.value.specialty,
);

export const selectListingGroupIdentifier = createSelector(
  selectJobListingSearchFilterFormState,
  (state) => state.value.groupIdentifier,
);
