import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isNil, isNumber, isString } from 'lodash-es';
import {
  combineLatest,
  concat,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  share,
  switchMap,
  zip,
} from 'rxjs';

import {
  DisplayButtons,
  IQueryParams,
  PaginatedStateService,
  Query,
  RequestOptions,
} from '@locumsnest/core';
import { ISetValueForResourceMessageConstructor } from '@locumsnest/core/src/lib/adapters/singleton-resource-adapter';
import { Time, UrlHelpers } from '@locumsnest/core/src/lib/helpers';
import { TIME_FORMAT } from '@locumsnest/core/src/lib/types/constants';
import {
  AlertTypeEnum,
  IAlertDetails,
  IAlertDetailsList,
} from '@locumsnest/dashboard-ui/src/lib/interfaces/alerts-details';
import {
  IApplicationList,
  IApplicationListItem,
} from '@locumsnest/dashboard-ui/src/lib/interfaces/application-list';
import { HospitalProfessionConfigurationService } from '@locumsnest/hospital-profession-configuration/src';
import { ProfileAssignmentNumbersService } from '@locumsnest/profile-assignment-numbers';

import { selectAllApplicationStatuses } from '../../application-status/+state/application-status.selectors';
import { ApplicationStatusService } from '../../application-status/+state/application-status.service';
import { ApplicationStatusCodes } from '../../application-status/+state/interfaces';
import { IApplicationService, LoadProfileSettings } from '../../core/services/interfaces';
import { DeclineApplicationReasonService } from '../../decline-application-reason/+state/decline-application-reason.service';
import { ExternalStaffingCandidateBidService } from '../../external-staffing-candidate-bid/+state/external-staffing-candidate-bid.service';
import { GradeService } from '../../grade/+state/grade.service';
import {
  IAdjacentApplicationEntity,
  IApplicationEntity,
  IApplicationParams,
  IApplicationRow,
} from '../../interfaces/api/application-entity';
import { IApplicationStatusEntity } from '../../interfaces/api/application-status-entity';
import { IDeclineApplicationReasonEntity } from '../../interfaces/api/decline-application-reason-entity';
import { IExternalStaffingCandidateBidEntity } from '../../interfaces/api/external-staffing-candidate-bid-entity';
import { IGradeEntity } from '../../interfaces/api/grade-entity';
import { IHospitalProfessionConfigurationEntity } from '../../interfaces/api/hospital-profession-configuration-entity';
import { IJobListingEntity, IRowEnum } from '../../interfaces/api/job-listing-entity';
import { IProfessionSpecialtyEntity } from '../../interfaces/api/profession-specialty-entity';
import { IProfileAssignmentNumbersEntity } from '../../interfaces/api/profile-assignment-numbers-entity';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import { ISubSpecialtyEntity } from '../../interfaces/api/sub-specialty-entity';
import { JobListingService } from '../../job-listing/+state/job-listing.service';
import { selectExpandedJobListings } from '../../job-listing/+state/search-filter-form';
import { ProfileFlagService } from '../../profile-flag/+state/profile-flag.service';
import { IProfileWithStaffBankStatus } from '../../profile/+state/interfaces';
import { ProfileService } from '../../profile/+state/profile.service';
import { StaffBankMembershipRequestService } from '../../staff-bank-membership-request/+state/staff-bank-membership-request.service';
import { StaffBankMembershipService } from '../../staff-bank-membership/+state/staff-bank-membership.service';
import { getApplicationMultiResourceId } from '../util';
import { selectSortedField } from './alert-search-filter-form/alert-search-filter-form.reducer';
import { adjacentApplicationsAdapter, LISTING_INDEX, loadingAdapter } from './application.adapter';
import {
  ApplicationMessageTypes,
  ApplicationPaginationMessages,
  ResetApplicationPaginationMessage,
  UpsertApplicationPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './application.messages';
import { ApplicationPersistenceService } from './application.persistence.service';
import {
  applicationPaginationSelectors,
  loadingStateSelectors,
  selectAllApplications,
  selectAllPendingApplications,
  selectAllPendingApplicationsAlerts,
  selectApplicationEntityState,
  selectApplicationsByJobListingIds,
  selectApplicationState,
} from './application.selectors';
import { DEFAULT_APPLICATION_STAFF_BANK_DEPENDENCIES } from './constants';
import { IProfileDependencies } from './interfaces/index';
import { selectDeclineFormState, selectDeclineKeys } from './list-state/list-state.selectors';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService
  extends PaginatedStateService<
    IApplicationEntity,
    UpsertApplicationPageMessage,
    ResetApplicationPaginationMessage,
    UpsertMultipleMessage
  >
  implements IApplicationService
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static readonly DEFAULT_LOAD_PROFILE_SETTINGS: LoadProfileSettings = {
    loadProfileFlag: true,
    loadProfile: false,
    loadStaffBanks: false,
    loadStaffBankRequests: false,
    loadAssignmentNumbers: false,
    loadCandidateBids: false,
  };
  // eslint-disable-next-line max-len, @typescript-eslint/naming-convention
  protected readonly SetValueForAdjacentApplicationsMessageClass: ISetValueForResourceMessageConstructor<
    string,
    IAdjacentApplicationEntity[]
  > = adjacentApplicationsAdapter.getMessages().SetValueForResource;
  protected readonly adjacentApplicationsSelectors =
    adjacentApplicationsAdapter.getSelectors(selectApplicationState);
  constructor(
    protected store: Store,
    protected persistenceService: ApplicationPersistenceService,
    private jobListingService: JobListingService,
    private profileService: ProfileService,
    private profileFlagService: ProfileFlagService,
    private staffBankMembershipRequestService: StaffBankMembershipRequestService,
    private staffBankMembershipService: StaffBankMembershipService,
    private applicationStatusService: ApplicationStatusService,
    private gradeService: GradeService,
    private profileAssignmentNumbersService: ProfileAssignmentNumbersService,
    private hospitalProfessionConfigurationService: HospitalProfessionConfigurationService,
    private declineApplicationReasonService: DeclineApplicationReasonService,
    private externalStaffingCandidateBidService: ExternalStaffingCandidateBidService,
  ) {
    super();
  }

  get paginationMessages() {
    return ApplicationPaginationMessages;
  }

  get paginationSelectors() {
    return applicationPaginationSelectors;
  }

  get entityStateSelector() {
    return selectApplicationEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  getAll() {
    return this.store.pipe(select(selectAllApplications));
  }

  getAllAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      switchMap(() => this.getAll()),
    );
  }

  getAlertSortedField() {
    return this.store.pipe(select(selectSortedField));
  }

  loadByIds(
    ids: number[],
    loadProfile: LoadProfileSettings = null,
    loadJobListing: boolean = false,
  ) {
    return concat(
      of(new this.loadingMessages.SetLoadingMessage({})),
      this.fetch({ id: ids }, loadProfile, loadJobListing),
      of(new this.loadingMessages.ResetLoadingMessage({})),
    ) as Observable<Action>;
  }

  loadByListingIds(ids: number[], loadProfile: LoadProfileSettings = null) {
    if (!ids.length) {
      return EMPTY;
    }
    return this.loadAllWithDependencies({ listingId: ids }, loadProfile);
  }

  private fetchOne(
    query: string | number,
    loadProfileSettings: LoadProfileSettings,
    loadJobListing: boolean,
  ) {
    return this.persistenceService.retrieve(query).pipe(
      mergeMap((entity) => {
        const actions: Observable<Action>[] = [of(new UpsertOneMessage({ entity }))];

        if (!isNil(loadProfileSettings) && !isNil(entity.profile)) {
          if (loadProfileSettings.loadProfile) {
            actions.push(this.profileService.loadOne(entity.profile));

            if (loadProfileSettings.loadProfileFlag) {
              actions.push(this.profileFlagService.loadByProfileIds([entity.profile]));
            }
          }
          if (loadProfileSettings.loadStaffBanks) {
            actions.push(this.staffBankMembershipService.loadByProfileIds([entity.profile]));
          }
          if (loadProfileSettings.loadStaffBankRequests) {
            actions.push(this.staffBankMembershipRequestService.loadByProfileIds([entity.profile]));
          }
        }
        if (loadJobListing) {
          actions.push(this.jobListingService.loadOne(entity.listing));
        }

        return merge(...actions);
      }),
    );
  }

  loadAllWithDependencies(
    filters: IQueryParams = {},
    loadProfileSettings: LoadProfileSettings = ApplicationService.DEFAULT_LOAD_PROFILE_SETTINGS,
    loadJobListing: boolean = false,
  ) {
    return this.loadAll(filters).pipe(
      mergeMap((action) =>
        merge(of(action), this.postLoad(action, loadProfileSettings, loadJobListing)),
      ),
    );
  }

  private postLoad(
    action: Action,
    loadProfileSettings: LoadProfileSettings = ApplicationService.DEFAULT_LOAD_PROFILE_SETTINGS,
    loadJobListing: boolean = false,
  ) {
    if (action.type !== ApplicationMessageTypes.UPSERT_MULTIPLE) {
      return EMPTY;
    }

    const results = (action as UpsertMultipleMessage).payload.entities;
    const profileIds = results.map((result) => result.profile);
    const listingIds = results.map((result) => result.listing);
    const actions: Observable<Action>[] = [];

    if (!isNil(loadProfileSettings)) {
      if (loadProfileSettings.loadProfile) {
        actions.push(this.profileService.loadByIds(profileIds));

        if (loadProfileSettings.loadProfileFlag) {
          actions.push(this.profileFlagService.loadByProfileIds(profileIds));
        }
      }
      if (loadProfileSettings.loadStaffBanks) {
        actions.push(this.staffBankMembershipService.loadByProfileIds(profileIds));
      }
      if (loadProfileSettings.loadStaffBankRequests) {
        actions.push(this.staffBankMembershipRequestService.loadByProfileIds(profileIds));
      }

      if (loadProfileSettings.loadAssignmentNumbers) {
        const loadAssignmentNumberAction =
          this.profileAssignmentNumbersService.getLoadAssignmentNumbersAction(results);
        if (loadAssignmentNumberAction) {
          actions.push(loadAssignmentNumberAction);
        }
      }

      if (loadProfileSettings.loadCandidateBids) {
        profileIds.forEach((profile) =>
          actions.push(
            this.externalStaffingCandidateBidService.fetchOneForProfileIfNotExists(profile),
          ),
        );
      }
    }
    if (loadJobListing) {
      actions.push(this.jobListingService.loadByIds(listingIds));
    }
    return merge(...actions);
  }

  private fetchMultiple(
    query?: IQueryParams,
    loadProfileSettings: LoadProfileSettings = ApplicationService.DEFAULT_LOAD_PROFILE_SETTINGS,
    loadJobListing: boolean = false,
  ) {
    return this.persistenceService.retrieve(query).pipe(
      mergeMap(({ results }) => {
        const message = new UpsertMultipleMessage({ entities: results });
        return merge(of(message), this.postLoad(message, loadProfileSettings, loadJobListing));
      }),
    );
  }

  fetch(
    query?: Query,
    loadProfileSettings: LoadProfileSettings = ApplicationService.DEFAULT_LOAD_PROFILE_SETTINGS,
    loadJobListing: boolean = false,
  ) {
    if (isString(query) || isNumber(query)) {
      return this.fetchOne(query, loadProfileSettings, loadJobListing);
    }
    return this.fetchMultiple(query, loadProfileSettings, loadJobListing);
  }

  loadOne(
    id: number,
    { jobListingEntity = false, profileEntity = false } = {},
  ): Observable<Action> {
    let loadAction$ = this.fetch(id) as Observable<UpsertOneMessage>;
    loadAction$ = loadAction$.pipe(share());

    const actions$: Observable<Action>[] = [];

    if (!jobListingEntity) {
      return loadAction$;
    }

    if (jobListingEntity) {
      actions$.push(this.postLoadApplicationEntity(loadAction$));
    }

    if (profileEntity) {
      actions$.push(this.postLoadProfileEntity(loadAction$));
    }

    return merge(loadAction$, ...actions$);
  }

  loadAllPagesAndLoadDependencies(
    namespace: string,
    requestOptions: RequestOptions<Record<string, unknown>> = null,
    filters: IQueryParams = {},
    loadJobListing: boolean,
    loadProfile: boolean,
    profileDependencies: IProfileDependencies,
  ) {
    return this.loadAllPages(namespace, requestOptions, filters).pipe(
      mergeMap((action) =>
        this.loadDependencies(action, loadJobListing, loadProfile, profileDependencies),
      ),
    );
  }

  initializePaginationAndLoadDependencies(
    namespace: string,
    applicationParams: IApplicationParams,
    loadJobListing: boolean = true,
    loadProfile: boolean = false,
    profileDependencies: IProfileDependencies = DEFAULT_APPLICATION_STAFF_BANK_DEPENDENCIES,
  ) {
    let filters: IQueryParams = {};
    for (const param in applicationParams) {
      if (applicationParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(filters, param, applicationParams[param]);
      }
    }

    return this.initializePagination(namespace, {}, filters).pipe(
      mergeMap((action) =>
        this.loadDependencies(action, loadJobListing, loadProfile, profileDependencies),
      ),
    );
  }

  loadNextAndLoadDependencies(
    namespace: string,
    applicationParams: IApplicationParams,
    loadJobListing: boolean = true,
    loadProfile: boolean = false,
    profileDependencies: IProfileDependencies = DEFAULT_APPLICATION_STAFF_BANK_DEPENDENCIES,
  ) {
    let filters: IQueryParams = {};

    for (const param in applicationParams) {
      if (applicationParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(filters, param, applicationParams[param]);
      }
    }

    return this.loadNext(namespace, {}, filters).pipe(
      mergeMap((action) =>
        this.loadDependencies(action, loadJobListing, loadProfile, profileDependencies),
      ),
    );
  }

  loadPagesAndDependencies(namespace: string, applicationParams: IApplicationParams) {
    let filters: IQueryParams = {};

    for (const param in applicationParams) {
      if (applicationParams.hasOwnProperty(param)) {
        filters = UrlHelpers.addQueryParams(filters, param, applicationParams[param]);
      }
    }
    return this.loadPages(namespace, {}, filters).pipe(
      mergeMap((action) => this.loadDependencies(action)),
    );
  }

  private loadDependencies(
    action,
    loadJobListing = true,
    loadProfile = true,
    profileDependencies = DEFAULT_APPLICATION_STAFF_BANK_DEPENDENCIES,
  ) {
    const actions: Observable<Action>[] = [of(action)];
    if (action.type === ApplicationMessageTypes.UPSERT_MULTIPLE) {
      const payload = (action as UpsertMultipleMessage).payload;

      if (loadJobListing) {
        const jobListings = payload.entities.map((x) => x.listing);

        if (jobListings.length > 0) actions.push(this.jobListingService.loadByIds(jobListings));
      }

      if (loadProfile) {
        actions.push(
          this.loadProfileDependencies(
            payload.entities.map((x) => x.profile),
            profileDependencies,
          ),
        );
      }

      if (profileDependencies.loadAssignmentNumber) {
        const assignmentNumbersAction =
          this.profileAssignmentNumbersService.getLoadAssignmentNumbersAction(payload.entities);
        if (assignmentNumbersAction) {
          actions.push(assignmentNumbersAction);
        }
      }

      if (profileDependencies.loadBids) {
        payload.entities.forEach((e) =>
          actions.push(
            this.externalStaffingCandidateBidService.fetchOneForProfileIfNotExists(e.profile),
          ),
        );
      }
    }

    return merge(...actions);
  }

  loadProfileDependencies(profiles, { loadStaffBank, loadStaffBankRequests }) {
    const actions: Observable<Action>[] = [];
    if (profiles.length > 0) {
      actions.push(this.profileService.loadByIds(profiles));
      actions.push(this.profileFlagService.loadByProfileIds(profiles));

      if (loadStaffBankRequests) {
        actions.push(this.staffBankMembershipRequestService.loadByProfileIds(profiles));
      }
      if (loadStaffBank) {
        actions.push(this.staffBankMembershipService.loadByProfileIds(profiles));
      }
      return merge(...actions);
    }
    return of<Action>();
  }

  postLoadApplicationEntity(loadAction$: Observable<UpsertOneMessage>): Observable<Action> {
    return loadAction$.pipe(
      map((action) => action.payload.entity),
      mergeMap((application) => this.jobListingService.loadOne(application.listing)),
    );
  }

  postLoadProfileEntity(loadAction$: Observable<UpsertOneMessage>): Observable<Action> {
    return loadAction$.pipe(
      map((action) => action.payload.entity),
      mergeMap((application) => this.profileService.loadOne(application.profile)),
    );
  }

  getAllPendingApplications(): Observable<IApplicationEntity[]> {
    return this.store.pipe(select(selectAllPendingApplications));
  }

  getAllPendingApplicationsAlerts(): Observable<IApplicationEntity[]> {
    return this.store.pipe(select(selectAllPendingApplicationsAlerts));
  }

  getApplicationsByJobListingIds(ids: number[]) {
    return this.store.pipe(select(selectApplicationsByJobListingIds(ids)));
  }
  getPendingApplicationsForListing(id: number) {
    return combineLatest([
      this.getApplicationsByJobListingIds([id]),
      this.applicationStatusService.getPendingStatus(),
    ]).pipe(
      map(([applications, pendingStatus]) =>
        applications.filter(({ bookingStatus: status }) => status === pendingStatus),
      ),
    );
  }

  getConsecutiveApplicationEntities(namespace: string) {
    return this.getConsecutivePageEntities(namespace);
  }

  getOneWithListingAndProfile(
    application: IApplicationEntity,
  ): Observable<
    IApplicationEntity<
      IJobListingEntity<
        Date,
        ISubSpecialtyEntity,
        number,
        IProfessionSpecialtyEntity<ISubSpecialtyEntity, number>
      >,
      IProfileEntity
    >
  > {
    return combineLatest([
      this.jobListingService.getOneWithSubSpecialty(application.listing),
      this.profileService.getOne(application.profile),
    ]).pipe(map(([listing, profile]) => ({ ...application, listing, profile })));
  }

  getOneWithDetails(
    application: IApplicationEntity,
  ): Observable<IApplicationEntity<number, IProfileEntity, IApplicationStatusEntity>> {
    return combineLatest([
      this.applicationStatusService.getOne(application.bookingStatus),
      this.profileService.getOne(application.profile),
    ]).pipe(map(([bookingStatus, profile]) => ({ ...application, bookingStatus, profile })));
  }

  getAllOrEmptyByListingId(listingId: number) {
    return this.getMultipleOrEmptyByIndex(LISTING_INDEX, listingId);
  }

  getAllByListingId(listingId: number) {
    return this.getMultipleByIndex(LISTING_INDEX, listingId);
  }

  getAllByListingIdWithDetails(listingId: number) {
    return this.getAllByListingId(listingId).pipe(
      switchMap((applications) => {
        if (!applications.length) {
          return of<IApplicationEntity<number, IProfileEntity, IApplicationStatusEntity>[]>([]);
        }
        return combineLatest(
          applications.map((application) => this.getOneWithDetails(application)),
        );
      }),
    );
  }

  getJobListingsAfterCurrentPageLoad(namespace) {
    return this.getConsecutivePageEntities(namespace).pipe(
      switchMap((applications: IApplicationEntity[]) => {
        if (!applications.length) {
          return of([]);
        }

        const applicationListingIds = applications.map((application) => application.listing);

        return combineLatest(
          applicationListingIds.map((listingId) => this.jobListingService.getOne(listingId)),
        );
      }),
    );
  }

  getProfilesAfterCurrentPageLoad(namespace) {
    return this.getConsecutivePageEntities(namespace).pipe(
      switchMap((applications: IApplicationEntity[]) => {
        if (!applications.length) {
          return of([]);
        }
        const applicationProfileIds = applications.map((application) => application.profile);
        return zip(
          ...applicationProfileIds.map((profileId) => this.profileService.getOne(profileId)),
        );
      }),
    );
  }

  getPendingApplications(namespace: string) {
    return combineLatest([
      this.getConsecutiveApplicationEntities(namespace),
      this.getTotalCount(namespace),
    ]).pipe(
      switchMap(
        ([applications, totalCount]: [
          IApplicationEntity[],
          number,
        ]): Observable<IApplicationList> => {
          if (applications.length === 0) {
            return of({
              count: totalCount,
              alertCount: 0,
              applications: [],
            });
          }
          return combineLatest(
            applications.map((application) =>
              this.getOneWithListingAndProfile(application).pipe(
                map((applicationWithDetails) => {
                  const pendingApplication: IApplicationListItem = {
                    id: application.id,
                    listing: application.listing,
                    title: applicationWithDetails.listing
                      ? applicationWithDetails.listing.title
                      : '',
                    createdAt: application.createdAt,
                    startDate: applicationWithDetails.listing
                      ? Time.formatDate(applicationWithDetails.listing.startTime)
                      : '',
                    startTime: applicationWithDetails.listing
                      ? Time.formatDate(applicationWithDetails.listing.startTime, TIME_FORMAT)
                      : '',
                    endTime: applicationWithDetails.listing
                      ? Time.formatDate(applicationWithDetails.listing.endTime, TIME_FORMAT)
                      : '',
                    profileName: applicationWithDetails.profile
                      ? applicationWithDetails.profile.firstName +
                        ' ' +
                        applicationWithDetails.profile.lastName
                      : '',
                    department: applicationWithDetails.listing.professionSpecialty.specialty
                      ? applicationWithDetails.listing.professionSpecialty.specialty.title
                      : '',
                  };

                  return pendingApplication;
                }),
              ),
            ),
          ).pipe(
            map((applicationListItems) => {
              const applicationList: IApplicationList = {
                count: totalCount,
                alertCount: 0,
                applications: applicationListItems,
              };

              return applicationList;
            }),
          );
        },
      ),
    );
  }

  getPendingApplicationsAlerts(namespace: string) {
    return combineLatest([
      this.getConsecutiveApplicationEntities(namespace),
      this.getTotalCount(namespace),
    ]).pipe(
      switchMap(
        ([applications, totalCount]: [
          IApplicationEntity[],
          number,
        ]): Observable<IAlertDetailsList> => {
          const applicationsWithCancellation = applications.filter(
            (application) => application.cancellationSeen === false,
          );
          if (applicationsWithCancellation.length === 0) {
            return of({
              totalCount,
              latestCancellationDate: null,
              alertDetails: [],
            });
          }

          return combineLatest(
            applicationsWithCancellation.map((application) =>
              this.getOneWithListingAndProfile(application).pipe(
                map((applicationWithDetails) => {
                  const applicationsAlertDetails: IAlertDetails = {
                    id: applicationWithDetails.id,
                    listing: applicationWithDetails.listing.id,
                    alertDate: applicationWithDetails.cancelledDate,
                    title: applicationWithDetails.listing
                      ? applicationWithDetails.listing.title
                      : '',
                    startDate: Time.formatDate(applicationWithDetails.listing.startTime),
                    startTime: Time.formatDate(
                      applicationWithDetails.listing.startTime,
                      TIME_FORMAT,
                    ),
                    endDate: Time.formatDate(applicationWithDetails.listing.endTime),
                    endTime: Time.formatDate(applicationWithDetails.listing.endTime, TIME_FORMAT),
                    description: '',
                    applicant: applicationWithDetails.profile
                      ? applicationWithDetails.profile.firstName +
                        ' ' +
                        applicationWithDetails.profile.lastName
                      : '',
                    profile: applicationWithDetails.profile
                      ? applicationWithDetails.profile.id
                      : '',
                    isRead: applicationWithDetails.cancellationSeen,
                    status: 'Cancellation',
                    type: AlertTypeEnum.APPLICATION,
                  };

                  return applicationsAlertDetails;
                }),
              ),
            ),
          ).pipe(
            map((applicationAlertListItems) => {
              const alertDetailsList: IAlertDetailsList = {
                totalCount,
                latestCancellationDate:
                  applicationAlertListItems.length > 0
                    ? applicationAlertListItems[0].alertDate
                    : null,
                alertDetails: applicationAlertListItems,
              };

              return alertDetailsList;
            }),
          );
        },
      ),
    );
  }

  addQueryParams(filters: IQueryParams, key: string, value: string): IQueryParams {
    if (value) filters[key] = value;
    return filters;
  }

  markCancellationAsSeen(id: string): Observable<IApplicationEntity> {
    return this.persistenceService.markCancellationAsSeen(id);
  }

  getExpandedApplicationsByJobListingIds() {
    return this.store.pipe(
      select(selectExpandedJobListings),
      switchMap((expandedJobListings) => this.getApplicationsByJobListingIds(expandedJobListings)),
    );
  }

  getDeclinedApplicationIds() {
    return this.store.pipe(select(selectDeclineKeys));
  }

  getDeclinedApplications() {
    return this.store.pipe(select(selectDeclineFormState));
  }

  getApplicationRowsForJobListing(id: number): Observable<IApplicationRow[]> {
    return this.getApplicationRowByJobListingIds(this.getApplicationsByJobListingIds([id]));
  }

  getExpandedApplicationRows(): Observable<IApplicationRow[]> {
    return this.getApplicationRowByJobListingIds(this.getExpandedApplicationsByJobListingIds());
  }

  getAdjacentApplicationDict() {
    return this.store.pipe(select(this.adjacentApplicationsSelectors.selectSubResourceState));
  }
  getApplicationListingTimes(id: number) {
    return this.getOne(id).pipe(
      switchMap(({ listing }) => this.jobListingService.getEmploymentPeriod(listing)),
    );
  }

  getApplicationStartTime(id: number) {
    return this.getApplicationListingTimes(id).pipe(
      map(
        (employmentPeriod) => employmentPeriod.startTime,
        distinctUntilChanged((a, b) => +a === +b),
      ),
    );
  }

  getApplicationProfile(id: number) {
    return this.getOne(id).pipe(
      map(({ profile }) => profile),
      distinctUntilChanged(),
    );
  }

  loadAdjacent(multiResourceId: string, profile: string, startTime: Date) {
    return this.persistenceService.fetchAdjacent(profile, startTime).pipe(
      mergeMap((subResource) =>
        of(
          new this.SetValueForAdjacentApplicationsMessageClass({
            resourceId: multiResourceId,
            subResource: subResource.map((adjacentApplication) => ({
              ...adjacentApplication,
              multiResourceId: getApplicationMultiResourceId(adjacentApplication.applicationId),
            })),
          }),
        ),
      ),
    );
  }

  private getApplicationRowByJobListingIds(applicationEntities$: Observable<IApplicationEntity[]>) {
    return combineLatest([
      applicationEntities$,
      applicationEntities$.pipe(
        map((applications) => applications.map((application) => application.profile)),
        switchMap((registrationNumbers) =>
          this.profileService.getProfilesWithStaffBanks(registrationNumbers),
        ),
      ),
      this.store.pipe(select(selectAllApplicationStatuses)),
      this.declineApplicationReasonService.getAll(),
      this.getDeclinedApplicationIds(),
      this.gradeService.getAll(),
      this.hospitalProfessionConfigurationService.getAllAfterLoading().pipe(
        switchMap((entries) => {
          if (entries.length) return this.profileAssignmentNumbersService.getAll();
          return of([]);
        }),
      ),
      this.hospitalProfessionConfigurationService.getAllAfterLoading(),
      this.externalStaffingCandidateBidService.getAll(),
    ]).pipe(
      map(
        ([
          applications,
          profilesWithStaffBanks,
          applicationStatuses,
          declineApplicationReasons,
          declinedApplications,
          grades,
          assignmentNumbers,
          hospitalProfessionConfiguration,
          candidateBids,
        ]): IApplicationRow[] =>
          this.constructListRows(
            applications,
            profilesWithStaffBanks,
            applicationStatuses,
            declineApplicationReasons,
            declinedApplications,
            grades,
            assignmentNumbers,
            hospitalProfessionConfiguration,
            candidateBids,
          ),
      ),
    );
  }

  private constructListRows(
    applications: IApplicationEntity[],
    profilesWithStaffBanks: IProfileWithStaffBankStatus[],
    applicationStatuses: IApplicationStatusEntity[],
    declineApplicationReasons: IDeclineApplicationReasonEntity[],
    applicationInDeclineState: string[],
    grades: IGradeEntity[],
    assignmentNumbers: IProfileAssignmentNumbersEntity[],
    hospitalProfessionConfiguration: IHospitalProfessionConfigurationEntity[],
    candidateBids: IExternalStaffingCandidateBidEntity[],
  ): IApplicationRow[] {
    return applications.map((application) => {
      const profilesWithStaffBank = profilesWithStaffBanks
        ? profilesWithStaffBanks.find((x) => x.profile.id === application.profile)
        : null;
      const status = applicationStatuses
        ? applicationStatuses.find((x) => x.val === application.bookingStatus)
        : null;
      const grade = grades.find((x) => x.id === application.grade);

      const assignmentNumberWarning =
        this.profileAssignmentNumbersService.getHasAssignmentNumberWarningPerApplication(
          hospitalProfessionConfiguration,
          grade ? grade.profession : null,
          assignmentNumbers,
          application,
        );

      let statusFont: string;
      let displayButtons: DisplayButtons;
      let statusCode = '';
      let declineReason: IDeclineApplicationReasonEntity;
      if (status) statusCode = status.code;

      switch (statusCode) {
        case ApplicationStatusCodes.APPLICATION_RECEIVED:
        case ApplicationStatusCodes.APPROVAL_PENDING_AUTHORIZATION:
        case ApplicationStatusCodes.CANCELLATION_PENDING_AUTHORIZATION:
        case ApplicationStatusCodes.DECLINE_PENDING_AUTHORIZATION:
          statusFont = 'warning';
          break;
        case ApplicationStatusCodes.APPROVED:
        case ApplicationStatusCodes.COMPLETED:
          statusFont = 'success';
          break;
        case ApplicationStatusCodes.DECLINED:
        case ApplicationStatusCodes.EXPIRED:
        case ApplicationStatusCodes.CANCELED:
        case ApplicationStatusCodes.WITHDRAWN:
        case ApplicationStatusCodes.DECLINE_OUT_OF_SYNC:
        case ApplicationStatusCodes.CANCELLATION_OUT_OF_SYNC:
          statusFont = 'error';
          break;
        default:
          statusFont = '';
          break;
      }

      switch (statusCode) {
        case ApplicationStatusCodes.APPROVED:
          displayButtons = DisplayButtons.Decline;
          break;
        case ApplicationStatusCodes.DECLINED:
        case ApplicationStatusCodes.DECLINE_PENDING_AUTHORIZATION:
        case ApplicationStatusCodes.DECLINE_OUT_OF_SYNC:
          displayButtons = DisplayButtons.Approve;
          declineReason = declineApplicationReasons.find((x) => x.id === application.declineReason);
          if (declineReason?.code === 'OTHER')
            declineReason = { ...declineReason, display: application.declineReasonOther };
          break;
        case ApplicationStatusCodes.APPLICATION_RECEIVED:
          displayButtons = DisplayButtons.Both;
          break;
        default:
          displayButtons = DisplayButtons.None;
          break;
      }

      const bidWarning = !!candidateBids.filter((cd) => cd.profile === application.profile).length;

      const applicationRow: IApplicationRow = {
        id: application.id,
        jobListingId: application.listing,
        profileId: application.profile,
        profilePictureUrl: profilesWithStaffBank ? profilesWithStaffBank.profile.photo : null,
        name: profilesWithStaffBank
          ? profilesWithStaffBank.profile.firstName + ' ' + profilesWithStaffBank.profile.lastName
          : null,
        grade,
        statusDisplay: status ? status.display : null,
        declineReason: application.declineReasonOther ? 'Other' : declineReason?.display || null,
        declineReasonOther: application.declineReasonOther
          ? 'Decline reason: ' + application.declineReasonOther
          : null,
        statusFont,
        statusCode,
        statusOrder: status ? status.val : null,
        staffBankStatus: profilesWithStaffBank ? profilesWithStaffBank.staffBankStatus : null,
        staffBankStatusFont: profilesWithStaffBank
          ? profilesWithStaffBank.staffBankStatusFont
          : null,
        reasonForCancellation: application.cancelationDescription
          ? 'Cancellation reason: ' + application.cancelationDescription
          : null,
        declined: applicationInDeclineState?.indexOf(application.id.toString()) > -1,
        displayButton: displayButtons,
        isCancelled: statusCode === ApplicationStatusCodes.CANCELED,
        createdAt: application.createdAt,
        rowType: IRowEnum.APPLICATION,
        processingStatus: 2,
        multiResourceId: getApplicationMultiResourceId(application.id),
        vaccineStatus: profilesWithStaffBank?.profile.compliantWithVaccine,
        hasFlaggedNote: profilesWithStaffBank?.profile.hasFlaggedNote,
        assignmentNumberWarning,
        negotiatedRates: null,
        bidWarning,
      };

      return applicationRow;
    });
  }
}
