import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Boxed, unbox } from 'ngrx-forms';
import {
  combineLatest,
  distinctUntilChanged,
  first,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import { ISearchFilterForm } from '@locumsnest/components/src/lib/molecules/tabs/+state/tabs.tokens';
import { QueryParamsFilterFormService } from '@locumsnest/core';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import {
  booleanField,
  boxedNumberField,
  OptionalField,
  optionalNumberField,
  optionalStringField,
  Serializer,
} from '@locumsnest/core/src/lib/serializers';
import { DATE_TIME_SERVER_ACCEPT_FORMAT } from '@locumsnest/core/src/lib/types/constants';

import { IGlobalFilters } from '../../../core/+state/interfaces/global-filters';
import { IFilterValues } from '../../../interfaces/api/filter-views-entity';
import { IJobListingParams } from '../../../interfaces/api/job-listing-entity';
import { MatchFilterService } from '../../../match/+state/match-filter.service';
import { SiteService } from '../../../site/+state/site.service';
import { StaffingCascadeStatusService } from '../../../staffing-cascade-status/+state/staffing-cascade-status.service';
import { getFilterIdByName, getParamsById, ListFilterOption } from '../../filters/list';
import { JobListingPermissionService } from '../job-listing.permission.service';
import { IJobListingSearchFilterFormState } from '../job-listing.reducer';
import { JobListingService } from '../job-listing.service';
import {
  JOB_LISTING_FILTERS_FORM_INITIAL_VALUES,
  SubmitSearchFilterFormSignal,
  UpdateFilterFormMessage,
} from './';
import { FORM_ID } from './search-filter-form.reducer';
import {
  selectApplicationStatus,
  selectBidStatus,
  selectCascaded,
  selectDirectEngagement,
  selectEscalated,
  selectFromDate,
  selectGrade,
  selectHasPrematch,
  selectHospital,
  selectJobListingSearchFilterFormState,
  selectJobStatus,
  selectListingGroupIdentifier,
  selectListingType,
  selectNonResidentOnCall,
  selectNotesValue,
  selectNotifyUsersValue,
  selectPendingOnly,
  selectProfession,
  selectPublished,
  selectRatesLocked,
  selectSearchKeyword,
  selectSelectedJobListings,
  selectShowAllTags,
  selectShowBlocks,
  selectSite,
  selectSortedField,
  selectSpecialty,
  selectTags,
  selectTier,
  selectToDate,
} from './search-filter-form.selectors';

type FilterValues = [
  string,
  string,
  string,
  string,
  number,
  number,
  number,
  number,
  number,
  boolean,
  number,
  number,
  number,
  Boxed<number[]>,
  number,
  boolean,
  boolean,
  boolean,
  Boxed<number[]>,
  number,
  number,
  number,
  number,
  number,
  number,
  string,
];
const FILTER_KEYS = [
  'sortedField',
  'fromDate',
  'toDate',
  'searchKeyword',
  'grade',
  'applicationStatus',
  'jobStatus',
  'published',
  'cascaded',
  'pendingOnly',
  'listingType',
  'bidStatus',
  'ratesLocked',
  'tier',
  'escalated',
  'hasPreMatchConversations',
  'showAllTags',
  'showBlocks',
  'tags',
  'nonResidentOnCall',
  'hospital',
  'profession',
  'site',
  'directEngagement',
  'specialty',
  'groupIdentifier',
] as const;

@Injectable({
  providedIn: 'root',
})
export class JobListingSearchFilterFormService
  extends QueryParamsFilterFormService<
    IJobListingSearchFilterFormState,
    IGlobalFilters | Record<string, never>,
    IJobListingParams,
    typeof FILTER_KEYS,
    FilterValues,
    IFilterValues
  >
  implements ISearchFilterForm<IFilterValues, IJobListingSearchFilterFormState>
{
  protected readonly filterKeys = FILTER_KEYS;
  protected readonly initialFormState = JOB_LISTING_FILTERS_FORM_INITIAL_VALUES;
  protected readonly namespace = FORM_ID;
  constructor(
    private store: Store,
    protected globalFilterService: MatchFilterService,
    protected permissionService: JobListingPermissionService,
    protected staffingCascadeStatusService: StaffingCascadeStatusService,
    protected jobListingService: JobListingService,
    private microAppService: MicroAppService,
    private siteService: SiteService,
  ) {
    super();
  }

  get serializer() {
    return new Serializer({
      applicationStatus: new OptionalField(optionalNumberField, true),
      bidStatus: new OptionalField(optionalNumberField, false),
      cascaded: optionalNumberField,
      escalated: optionalNumberField,
      fromDate: new OptionalField(optionalStringField, true),
      grade: new OptionalField(optionalNumberField, true),
      jobStatus: optionalNumberField,
      listingType: new OptionalField(optionalNumberField, true),
      pendingOnly: new OptionalField(booleanField, true),
      published: optionalNumberField,
      ratesLocked: optionalNumberField,
      searchKeyword: new OptionalField(optionalStringField, true),
      sortedField: new OptionalField(optionalStringField, true),
      tier: boxedNumberField,
      toDate: new OptionalField(optionalStringField, true),
      hasPreMatchConversations: new OptionalField(booleanField, true),
      showAllTags: new OptionalField(booleanField, true),
      showBlocks: new OptionalField(booleanField, true),
      tags: boxedNumberField,
      nonResidentOnCall: optionalNumberField,
      hospital: optionalNumberField,
      site: optionalNumberField,
      profession: optionalNumberField,
      directEngagement: optionalNumberField,
      specialty: optionalNumberField,
      groupIdentifier: optionalStringField,
    });
  }

  getFormFilters() {
    return combineLatest([
      this.store.pipe(select(selectSortedField)),
      this.store.pipe(select(selectFromDate)),
      this.store.pipe(select(selectToDate)),
      this.store.pipe(select(selectSearchKeyword)),
      this.store.pipe(select(selectGrade)),
      this.store.pipe(select(selectApplicationStatus)),
      this.store.pipe(select(selectJobStatus)),
      this.store.pipe(select(selectPublished)),
      this.store.pipe(select(selectCascaded)),
      this.store.pipe(select(selectPendingOnly)),
      this.store.pipe(select(selectListingType)),
      this.store.pipe(select(selectBidStatus)),
      this.store.pipe(select(selectRatesLocked)),
      this.store.pipe(select(selectTier)),
      this.store.pipe(select(selectEscalated)),
      this.store.pipe(select(selectHasPrematch)),
      this.store.pipe(select(selectShowAllTags)),
      this.store.pipe(select(selectShowBlocks)),
      this.store.pipe(select(selectTags)),
      this.store.pipe(select(selectNonResidentOnCall)),
      this.store.pipe(select(selectHospital)),
      this.store.pipe(select(selectProfession)),
      this.store.pipe(select(selectSite)),
      this.store.pipe(select(selectDirectEngagement)),
      this.store.pipe(select(selectSpecialty)),
      this.store.pipe(select(selectListingGroupIdentifier)),
    ]).pipe(mergeMap((formValues) => this.transformFormFiltersToParams(formValues)));
  }

  deserializeForBackend() {
    return this.getFilteredQueryParams().pipe(
      map((filters) => this.deserialize(filters)),
      map((filters) => ({
        ...filters,
        tier: unbox(filters.tier),
        tags: unbox(filters.tags),
        pendingOnly: filters.pendingOnly || false,
        hasPreMatchConversations: filters.hasPreMatchConversations || false,
      })),
      map((filters: any) => this.pickByNotNull(filters)),
    );
  }

  getFormState() {
    return this.store.pipe(select(selectJobListingSearchFilterFormState));
  }

  getNotesStateValue() {
    return this.store.pipe(select(selectNotesValue));
  }

  getNotifyUsersValue() {
    return this.store.pipe(select(selectNotifyUsersValue));
  }

  getSelectedJobListings() {
    return this.store.pipe(select(selectSelectedJobListings));
  }

  getSelectedHospital() {
    return this.store.pipe(select(selectHospital));
  }

  getSelectedProfession() {
    return this.store.pipe(select(selectProfession));
  }

  getCascadedValue() {
    return this.getFormState().pipe(
      map((state) => state.value.cascaded),
      distinctUntilChanged(),
    );
  }

  getPendingCascadeAuthorization() {
    return combineLatest([
      this.staffingCascadeStatusService.getPendingStatus(),
      this.getCascadedValue(),
    ]).pipe(map(([{ val: pendingValue }, value]) => value === pendingValue));
  }
  loadPublishingOfficersSelectedJobListings() {
    return this.getSelectedJobListings().pipe(
      first(),
      switchMap((listingIds) => this.jobListingService.loadPublishingOfficersFor(listingIds)),
    );
  }

  getListingGroupIdentifier() {
    return this.store.pipe(select(selectListingGroupIdentifier));
  }

  getSelectedJobListingPublishingOfficerUsers() {
    return this.getSelectedJobListings().pipe(
      first(),
      switchMap((selectedJobListings) =>
        combineLatest(
          selectedJobListings.map((jobListing) =>
            this.jobListingService.getJoblistingPublishingOfficerUserId(jobListing).pipe(
              map((publishingOfficerUser) => ({
                jobListing,
                publishingOfficerUser,
              })),
            ),
          ),
        ),
      ),
    );
  }

  getDynamicInitialState(): Observable<IJobListingSearchFilterFormState> {
    return this.microAppService.isAgencyPlatform().pipe(
      first(),
      switchMap((isAgencyPlatform) =>
        isAgencyPlatform
          ? of({ ...this.initialFormState })
          : combineLatest([
              this.permissionService.canCascadeToExternalStaffingProviders().pipe(
                switchMap((canCascade) => {
                  if (canCascade) {
                    return this.staffingCascadeStatusService
                      .getPendingStatusValue()
                      .pipe(map((val) => ({ cascaded: val })));
                  }
                  return of({ cascaded: -2 });
                }),
              ),
              this.jobListingService.getShowRatedLockedFilter().pipe(
                switchMap((showRates) => {
                  if (showRates)
                    return this.permissionService.hasUnlockPermission().pipe(
                      mergeMap((canUnlock) => {
                        if (canUnlock) {
                          return of({
                            ratesLocked: 2,
                            jobStatus: getFilterIdByName('all-jobs', ListFilterOption.Status),
                          });
                        }
                        return of({ ratesLocked: 1 });
                      }),
                    );
                  return of({});
                }),
              ),
            ]).pipe(
              map(([cascaded, ratesLocked]) => ({
                ...this.initialFormState,
                published: getFilterIdByName('yes', ListFilterOption.Published),
                escalated: getFilterIdByName('all', ListFilterOption.Escalated),
                ...cascaded,
                ...ratesLocked,
              })),
            ),
      ),
    );
  }

  getSelectedListingPublishingOfficerReceiverNotes(note: string) {
    return this.getSelectedJobListingPublishingOfficerUsers().pipe(
      map((listingOfficers) =>
        listingOfficers.map(({ jobListing, publishingOfficerUser }) => ({
          jobListing,
          note: {
            notificationReceivers: [publishingOfficerUser],
            noteText: note,
          },
        })),
      ),
    );
  }
  getSelectedJobListingNotes(note: string, notifyPublishingOfficers: boolean) {
    if (notifyPublishingOfficers) {
      return this.getSelectedListingPublishingOfficerReceiverNotes(note);
    }
    return this.getSelectedJobListings().pipe(
      first(),
      map((selectedJobListings) =>
        selectedJobListings.map((jobListing) => ({
          jobListing,
          note: {
            notificationReceivers: [] as number[],
            noteText: note,
          },
        })),
      ),
    );
  }

  getSelectedShiftsToUnpublish(note: string, notifyPublishingOfficers: boolean) {
    if (note) {
      return this.getSelectedJobListingNotes(note, notifyPublishingOfficers);
    }
    return this.getSelectedJobListings().pipe(
      map((ids) => ids.map((jobListing) => ({ jobListing }))),
    );
  }

  transformFormFiltersToParams([
    sortedField,
    fromDate,
    toDate,
    searchKeyword,
    grade,
    applicationStatus,
    jobStatus,
    published,
    cascaded,
    pendingOnly,
    listingType,
    bidStatus,
    ratesLocked,
    tier,
    escalated,
    hasPreMatchConversations,
    showAllTags,
    showBlocks,
    tags,
    nonResidentOnCall,
    hospital,
    profession,
    site,
    directEngagement,
    specialty,
    groupIdentifier,
  ]: FilterValues) {
    return combineLatest([
      this.staffingCascadeStatusService.getPendingStatusValue(),
      hospital ? this.siteService.getSiteIdsByTrustId(hospital) : of(null),
    ]).pipe(
      map(([pendingStatus, hospitalSite]) => {
        // site has priority
        const sites = site ? [site] : hospitalSite;
        return {
          ordering: sortedField,
          adhoc: 'false',
          search: searchKeyword,
          start_time__gt:
            cascaded === pendingStatus
              ? Time.getMoment().format(DATE_TIME_SERVER_ACCEPT_FORMAT)
              : null,
          start_date__gte: fromDate ? Time.formatDbDateFromDatePicker(fromDate) : null,
          start_date__lte: toDate ? Time.formatDbDateFromDatePicker(toDate) : null,
          grades__grade: grade,
          applications__status: applicationStatus,
          published: getParamsById(published, ListFilterOption.Published),
          staffing_cascade__status: cascaded === -2 ? null : cascaded !== -1 ? cascaded : null,
          staffing_cascade__isnull: cascaded === -1 ? 'true' : null,
          job_status: getParamsById(jobStatus, ListFilterOption.Status),
          listingType,
          hasPendingApplications: pendingOnly || null,
          bids__status: bidStatus,
          rates_locked: getParamsById(ratesLocked, ListFilterOption.RatesLocked),
          tier: unbox(tier),
          shift_escalated: getParamsById(escalated, ListFilterOption.Escalated),
          hasPreMatchConversations: hasPreMatchConversations || null,
          showAllTags,
          showBlocks,
          tag: unbox(tags),
          site: sites,
          profession: profession ? [profession] : null,
          profession_specialty: specialty,
          ...getParamsById(directEngagement, ListFilterOption.DirectEngagement),
          ...getParamsById(nonResidentOnCall, ListFilterOption.NonResidentOnCall),
          groupIdentifier,
        };
      }),
    );
  }

  updateFilterFormAction(filters: Partial<IFilterValues>) {
    return of(new UpdateFilterFormMessage({ filters }));
  }

  submitSearchFilterFormAction(namespace: string) {
    return of(new SubmitSearchFilterFormSignal({ namespace }));
  }
}
