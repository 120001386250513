import { FormGroupState } from 'ngrx-forms';

import { IJobListingFormWizardState } from '../interfaces';
import { adapter } from './form.adapter';
import { createJobListingFormMessages } from './form.messages.adapter';

export const FORM_ID = adapter.FORM_ID;
export type State = FormGroupState<IJobListingFormWizardState>;

const formReducer = adapter.createReducer();

export function reducer(
  state: State,
  action: InstanceType<
    ReturnType<typeof createJobListingFormMessages>[keyof ReturnType<
      typeof createJobListingFormMessages
    >]
  >,
) {
  return formReducer(state, action);
}

export const {
  selectJobListingFormWardValue,
  selectJobListingFormRosterValue,
  selectJobListingFormPrimaryProfessionSpecialtyValue,
  selectJobListingFormProfessionSpecialtyValues,
  selectJobListingFormProfessionValue,
  selectJobListingFormNotesValue,
  selectCopyNoteAcrossRepetitionDateFormValue,
  selectJobListingFormSiteValue,
  selectJobListingFormGradeValues,
  selectJobListingFormVacancyReason,
  selectJobListingFormId,
  selectJobListingFromForm,
  selectIsSectionDetailsInvalid,
  selectIsSectionDescriptionInvalid,
  selectIsSectionGradesInvalid,
  selectIsFormInvalid,
  selectRemainingPositionsToFill,
  selectExtendedJobListingFormWizardState,
  selectIsExternalJobListingMode,
  selectJobListingGradeControl,
  selectIsEmploymentPeriodInPast,
  selectIsRepeatingDateInPast,
  selectJobListingFormStartTimeValue,
  selectShiftCreationControlIsInvalid,
  selectShiftSchedulerControlIsInvalid,
  selectGradesSectionControlIsInvalid,
  selectShiftSchedulerControl,
  selectCostCentreNumberValue,
  selectTimeFragmentsControl,
  selectExtendedFormGradesArray,
  selectRateViolationReasonControl,
  selectRateViolationReasonValue,
  selectJobListingFormCostCentreNumberValue,
  selectRepetitionDatesValue,
  selectStartEndRepetition,
  selectJobListingFormCrossCoveringProfessionSpecialtiesValue,
  selectJobListingFormCrossCoveringProfessions,
  selectIsCrossCoveringForm,
  selectJobListingGradesAreValid,
  selectProfessionGradesAreValid,
  selectEnabledJobListingGrades,
  selectJobListingFormNonResidentOnCallValue,
  selectUseRateCardRates,
  selectRateEditingDisabled,
  selectRateCardRateListings,
  selectRateCardGrades,
  selectInsufficientRateCardConfigurationError,
  selectListingPeriodIsValid,
  selectStartTimeIsValid,
  selectEndTimeIsValid,
  selectEndTimeFormControl,
  selectStartTimeFormControl,
  selectCanCalculateApprovedRates,
  selectCanCalculateApprovedRatesWithUpdate,
  selectGradesWithDifferentBreakDownExist,
  selectRateCardRatesRecalculated,
  selectEnabledJobListingGradeIds,
} = adapter.getSelectors();
