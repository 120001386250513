import { countBy, maxBy } from 'lodash-es';

import {
  IJobFragmentRatesEntity,
  IJobListingEntityWithRateDefinitions,
  IJobListingGradeEntity,
} from '../../../interfaces/api/job-listing-entity';
import { IJobListingFormWizardState } from '../job-listing.reducer';

export const discardNonHomogeneousGrades = (
  grades: IJobListingGradeEntity<IJobFragmentRatesEntity>[],
) => {
  if (!grades.length) {
    return grades;
  }
  const homogeneousGrades = grades.map((grade) => ({
    ...grade,
    homogeneityId:
      `| hasFlatRate: ${!!grade.flatRate && grade.flatRate.rate}|` +
      grade.jobFragments
        .map(
          ({ timeFragment: { fromTime, toTime } }) =>
            `${fromTime.toISOString()}${toTime.toISOString()}`,
        )
        .sort()
        .join(','),
  }));
  const dominantHomogeneityId = maxBy(
    Object.entries(countBy(homogeneousGrades, 'homogeneityId')),
    ([_, count]) => count,
  )[0];
  return homogeneousGrades.filter(({ homogeneityId }) => homogeneityId === dominantHomogeneityId);
};

export const copyIdsToNewState = (
  jl: IJobListingEntityWithRateDefinitions<Date, number, number, number>,
  initialFormState: IJobListingFormWizardState,
) => ({
  ...jl,
  grades: jl.grades.map((jlGrade) => {
    const { grades } = initialFormState.gradesSection;
    const initialGrade = grades.find(({ grade }) => jlGrade.grade === grade);
    return {
      ...jlGrade,
      id: initialGrade?.id,
    };
  }),
});

export const fragmentRatesFullyDefined = (
  jobFragments: IJobFragmentRatesEntity[],
  startTime: Date,
  endTime: Date,
) =>
  jobFragments.length &&
  +jobFragments[0].timeFragment.fromTime === +startTime &&
  +jobFragments[jobFragments.length - 1].timeFragment.toTime === +endTime &&
  jobFragments.every(
    ({ timeFragment }, index) =>
      index === 0 || jobFragments[index - 1].timeFragment.toTime === timeFragment.fromTime,
  );
