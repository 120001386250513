import { createSelector } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import { FormControlState, FormGroupState } from 'ngrx-forms';

import { Time } from '@locumsnest/core/src/lib/helpers';

import {
  IBidFragmentWithRates,
  IExternalStaffingCandidateBidEntityWithRates,
  IFee,
  IJobListingEntityWithRates,
} from '../../../interfaces/api/external-staffing-candidate-bid-entity';
import { IJobListingEntity } from '../../../interfaces/api/job-listing-entity';
import { isFlatFee, isPercentageFee } from '../../type-guards';
import { selectExternalStaffingCandidateBidState } from '../external-staffing-candidate-bid.selectors';
import { IBidFragmentFormState } from '../interfaces/external-staffing-candidate-bid-form';
import {
  IConfirmRatesFormState,
  IExternalStaffingCandidateMultiBidFormState,
} from '../interfaces/external-staffing-candidate-multi-bid-form';
import {
  formatProviderFee,
  HOSPITAL_ID_USER_DEFINED_PROPERTY,
  LEGACY_APPROVED_FEE_USER_DEFINED_PROPERTY,
  PER_FRAGMENT_RATES_APPLICABLE_USER_DEFINED_PROPERTY,
  SUPPORTING_DOCUMENT_DECLARATION_USER_DEFINED_PROPERTY,
} from './multi-bid-form.reducer';

export const selectExternalStaffingCandidateMultiBidFormState = createSelector(
  selectExternalStaffingCandidateBidState,
  (state) => state.multiBidFormState,
);

export const selectSelectedListingsFormState = createSelector(
  selectExternalStaffingCandidateMultiBidFormState,
  (form) => form.controls.selectedListingsFormState,
);

const selectCandidateDetailsFormState = createSelector(
  selectExternalStaffingCandidateMultiBidFormState,
  (state) => state.controls.candidateDetailsFormState,
);

export const selectDocumentDeclarationAcceptedControl = createSelector(
  selectCandidateDetailsFormState,
  (form) => form.controls.documentDeclarationAccepted,
);

export const selectCandidateEmailControl = createSelector(
  selectCandidateDetailsFormState,
  (form) => form.controls.candidateEmail,
);

export const selectUserDefinedProperties = createSelector(
  selectExternalStaffingCandidateMultiBidFormState,
  (state) => state.userDefinedProperties,
);

export const selectCandidateDetailsFormUserDefinedProperties = createSelector(
  selectExternalStaffingCandidateMultiBidFormState,
  (state) => state.controls.candidateDetailsFormState.userDefinedProperties,
);

export const selectSupportingDocumentDeclaration = createSelector(
  selectCandidateDetailsFormUserDefinedProperties,
  (userDefinedProperties) =>
    userDefinedProperties[SUPPORTING_DOCUMENT_DECLARATION_USER_DEFINED_PROPERTY],
);

export const selectHasSelectedListing = createSelector(selectSelectedListingsFormState, (form) =>
  form.value.some((listing) => listing.selectedListing),
);

export const selectSelectedListing = createSelector(
  selectSelectedListingsFormState,
  // TODO remove pre select controls 0
  (form) => form.controls.find((listing) => listing.value.selectedListing) || form.controls[0],
);

const selectSelectedListingBidForm = createSelector(
  selectSelectedListing,
  (state) => state.controls.confirmRatesFormState,
);

export const selectUploadFileFormState = createSelector(
  selectCandidateDetailsFormState,
  (formState) => formState.controls.documentUploadFileFormState,
);

export const selectSelectedListingProviderFeeControl = createSelector(
  selectSelectedListingBidForm,
  (formState) => formState.controls.providerFee,
);

export const selectDirectEngagementCandidate = createSelector(
  selectSelectedListingBidForm,
  (formState) => formState.controls.directEngagementCandidate,
);

export const selectGradeControl = createSelector(
  selectSelectedListingBidForm,
  (formState) => formState.controls.grade,
);

export const selectApprovedProposedRateControl = createSelector(
  selectSelectedListingBidForm,
  (formState) => formState.controls.approvedProposedRate,
);

export const selectFlatRateControl = createSelector(
  selectSelectedListingBidForm,
  (formState) => formState.controls.flatRate as FormControlState<string | number | null>,
);

export const selectBidFragmentsControl = createSelector(
  selectSelectedListingBidForm,
  (formState) => formState.controls.bidFragments,
);

export const selectCandidateHasBankDetailsValue = createSelector(
  selectCandidateDetailsFormState,
  (form) => form.value.candidateHasBankDetails,
);

export const selectStaffingProviderControl = createSelector(
  selectCandidateDetailsFormState,
  (form) => form.controls.staffingProvider,
);

export const selectCandidateHasPreferablePaymentMethodValue = createSelector(
  selectCandidateDetailsFormState,
  (form) => form.value.candidateHasPreferablePaymentMethod,
);

export const selectProfileId = createSelector(
  selectCandidateDetailsFormState,
  (formState) => formState.value.profile,
);

export const selectLegacyApprovedFee = createSelector(
  selectUserDefinedProperties,
  (userDefinedProperties) => userDefinedProperties[LEGACY_APPROVED_FEE_USER_DEFINED_PROPERTY],
);

const selectSelectedListingUserDefinedProperties = createSelector(
  selectSelectedListing,
  (state) => state.userDefinedProperties,
);

export const selectSelectedListingNonResidentOnCall = createSelector(
  selectSelectedListing,
  (state) => !!state.value.listing?.nonResidentOnCall,
);

export const selectPerFragmentFeesApplicable = createSelector(
  selectSelectedListingUserDefinedProperties,
  (userDefinedProperties) =>
    userDefinedProperties[PER_FRAGMENT_RATES_APPLICABLE_USER_DEFINED_PROPERTY],
);

export const selectLegacyApprovedFeeExists = createSelector(
  selectLegacyApprovedFee,
  (fee) => !isNil(fee),
);

export const selectHospitalId = createSelector(
  selectUserDefinedProperties,
  (userDefinedProperties) => userDefinedProperties[HOSPITAL_ID_USER_DEFINED_PROPERTY],
);

export const getFragmentsFormState = <T extends IBidFragmentWithRates>(bidFragments: T[]) =>
  bidFragments.map((bidFragment) => ({
    ...bidFragment,
    fromTime: +Time.getMoment(bidFragment.fromTime),
    toTime: +Time.getMoment(bidFragment.toTime),
  }));

export const getConfirmRatesFormState = (
  entityState: IExternalStaffingCandidateBidEntityWithRates,
  initialFormState: IExternalStaffingCandidateMultiBidFormState,
): IConfirmRatesFormState => ({
  ...initialFormState.selectedListingsFormState[0].confirmRatesFormState,
  providerFee: formatProviderFee(entityState.providerFee),
  startTime: entityState.startTime ? Time.formatDateTimePickerFormat(entityState.startTime) : null,
  endTime: entityState.endTime ? Time.formatDateTimePickerFormat(entityState.endTime) : null,
  bidFragments: getFragmentsFormState(entityState.bidFragments),
  flatRate: entityState.flatRate,
});

// export const getCandidateDetailsFormState = (
//   initialFormState: IExternalStaffingCandidateMultiBidFormState,
// ): ICandidateDetailsFormState =>

//   ({
//       ...initialFormState.candidateDetailsFormState,
//       documentUploadFileFormState: INITIAL_UPLOAD_FILE_FORM_STATE,
//   });
//}
//return formState;
//   },
// );

// return {
//   ...initialFormState,
//   ...entityState,
//   candidateDetailsFormState: {
//     ...initialFormState.candidateDetailsFormState,
//     documentUploadFileFormState: INITIAL_UPLOAD_FILE_FORM_STATE,
//   },
//   selectedListingsFormState: updatedSelectedListingsFormState,
// };
//};

export const getTotalHours = (
  formState: FormGroupState<IConfirmRatesFormState>,
  shiftDetails: IJobListingEntity<Date>,
) => {
  let totalHours = 0;

  if (formState.value.approvedProposedRate) {
    const jobListingGrades = shiftDetails?.grades.find((x) => x.grade === formState.value.grade);

    jobListingGrades?.jobFragments.forEach((fragment) => {
      const hours =
        (fragment.timeFragment.toTime.getTime() - fragment.timeFragment.fromTime.getTime()) /
        (1000 * 60 * 60);
      totalHours += hours;
    });
  } else {
    formState.value.bidFragments.forEach((fragment) => {
      const hours = (fragment.toTime - fragment.fromTime) / (1000 * 60 * 60);
      totalHours += hours;
    });
  }
  return totalHours;
};

export const getFragmentsTotal = (
  approvedProposedRate: boolean,
  grade: number,
  bidFragments: IBidFragmentFormState[],
  flatRate: string | number,
  shiftDetails: IJobListingEntityWithRates<Date>,
) => {
  let totalRate = 0;

  if (approvedProposedRate) {
    const jobListingGrade = shiftDetails?.grades.find((x) => x.grade === grade);
    if (!jobListingGrade) {
      return 0;
    }
    if (jobListingGrade.flatRate) {
      totalRate += +jobListingGrade.flatRate.rate;
    }
    jobListingGrade?.jobFragments.forEach((fragment) => {
      const hours =
        (fragment.timeFragment.toTime.getTime() - fragment.timeFragment.fromTime.getTime()) /
        (1000 * 60 * 60);
      totalRate += +fragment.payRate.rate * hours;
    });
  } else {
    bidFragments.forEach((fragment) => {
      const hours = (fragment.toTime - fragment.fromTime) / (1000 * 60 * 60);
      totalRate += +fragment.payRate * hours;
    });
    if (flatRate) {
      totalRate += +flatRate;
    }
  }
  return totalRate;
};

export const getRateAdjustment = (providerFee: IFee<string>, fragmentsTotal: number) => {
  let rateAdjustment = 1;

  if (!providerFee.excluded) {
    if (isPercentageFee(providerFee)) {
      rateAdjustment = 1 - +providerFee.feePercentage / 100;
    }
    if (isFlatFee(providerFee)) {
      const agencyTotal = +providerFee.fee;

      rateAdjustment = 1 - agencyTotal / fragmentsTotal;
    }
  }

  return rateAdjustment;
};

export const getAgencyTake = (
  providerFee: IFee<string>,
  approvedProposedRate: boolean,
  grade: number,
  bidFragments: IBidFragmentFormState[],
  flatRate: string | number,
  shiftDetails: IJobListingEntityWithRates<Date>,
) => {
  if (isPercentageFee(providerFee)) {
    const fee = +providerFee.feePercentage;
    const totalRate = getFragmentsTotal(
      approvedProposedRate,
      grade,
      bidFragments,
      flatRate,
      shiftDetails,
    );
    const agencyTake = (totalRate * fee) / 100;
    return agencyTake;
  }

  if (isFlatFee(providerFee)) {
    return +providerFee.fee;
  }
};

export const getCandidateTake = (
  providerFee: IFee<string>,
  approvedProposedRate: boolean,
  grade: number,
  bidFragments: IBidFragmentFormState[],
  flatRate: string | number,
  shiftDetails: IJobListingEntityWithRates<Date>,
) => {
  const totalRate = getFragmentsTotal(
    approvedProposedRate,
    grade,
    bidFragments,
    flatRate,
    shiftDetails,
  );
  return totalRate * getRateAdjustment(providerFee, totalRate);
};
