import { IBidFragmentWithRates } from '../../../interfaces/api/external-staffing-candidate-bid-entity';
import { IExternalStaffingCandidateMultiBidFormState } from '../interfaces/external-staffing-candidate-multi-bid-form';
import {
  messageableFactory as actionableFactory,
  alertStateAdapter,
} from './multi-bid-form.adapter';

export const {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ResetErrorMessage: ResetErrorAdapterMessage,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeExternalStaffingCandidateMultiBidFormMessage extends actionableFactory.create<
  'Initialize External Staffing Candidate Form',
  {
    externalStaffingCandidateMultiBidFormState?: IExternalStaffingCandidateMultiBidFormState;
    userDefinedProperties?: Record<string, any>;
    candidateDetailsUserDefinedProperties?: Record<string, any>;
  }
>('Initialize External Staffing Candidate Form') {}

export class RemoveDocumentMessage extends actionableFactory.create<'Remove Document', void>(
  'Remove Document',
) {}

export class SetSelectedJobListingMessage extends actionableFactory.create<
  'Set Selected Job Listing Message',
  {
    listingId: number;
  }
>('Set Selected Job Listing Message') {}

export class RemoveSelectedJobListingMessage extends actionableFactory.create<
  'Remove Selected Job Listing Message',
  {
    listingId: number;
  }
>('Remove Selected Job Listing Message') {}

export class UpdateMultiBidNextInvalidListingMessage extends actionableFactory.create<
  'Update Multi Bid Next Invalid Listing Message',
  Record<string, never>
>('Update Multi Bid Next Invalid Listing Message') {}

export class SetMultiBidListingApprovedRates extends actionableFactory.create<
  'Set Multi Bid Listing Approved Rates',
  {
    approvedRates: (IBidFragmentWithRates & {
      fromTime: number;
      toTime: number;
    })[];
    listingFormControlId: string;
  }
>('Set Multi Bid Listing Approved Rates') {}

export type ExternalStaffingCandidateMultiBidFormMessages =
  | InitializeExternalStaffingCandidateMultiBidFormMessage
  | SetSelectedJobListingMessage
  | RemoveSelectedJobListingMessage
  | UpdateMultiBidNextInvalidListingMessage
  | SetMultiBidListingApprovedRates
  | ResetErrorMessage
  | AlertErrorMessage;
