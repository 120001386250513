import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';

import {
  defferSerialization,
  HttpApiPersistenceService,
  ISerializedApiService,
} from '@locumsnest/core/src';
import { dateField, getEntityFields, Serializer } from '@locumsnest/core/src/lib/serializers';

import { IListingIdentifierStatsEntity } from './interfaces';

const ENDPOINT = defferSerialization({ endpoint: 'listingIdentifierStats' });

@Injectable({
  providedIn: 'root',
})
export class ListingIdentifierStatsPersistenceService
  extends HttpApiPersistenceService<LocumsNestEndpointConfig, IListingIdentifierStatsEntity>
  implements ISerializedApiService<'listingIdentifierStats', IListingIdentifierStatsEntity>
{
  override readonly endpoint = ENDPOINT;

  override readonly serializer = new Serializer(
    getEntityFields<IListingIdentifierStatsEntity>(
      {
        minDate: dateField,
        maxDate: dateField,
      },
      {},
    ),
  );
}
