import { get } from 'lodash-es';
import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { maxLength, pattern, required } from 'ngrx-forms/validation';

import {
  EMAIL_REGEX,
  UNSIGNED_INTEGER_STRING_REGEX,
} from '@locumsnest/core/src/lib/types/constants';

import { IProfileSearchFormState } from '../interfaces/profile-search-form-state';
import {
  InitializeProfessionalRegistrationFormMessage,
  ProfileSearchFormMessages,
} from './profile-search-form.messages';
import { hasValidDob, requiredWhenProfession } from './profile-search-form.validators';

export { IProfileSearchFormState } from '../interfaces/profile-search-form-state';
export * from './profile-search-form.selectors';
export const PROFILE_REGISTRATION_SEARCH_FORM_ID = 'PROFILE_REGISTRATION_SEARCH_FORM';
export type State = FormGroupState<IProfileSearchFormState>;

//TODO remove when finish development
export const INITIAL_FORM_STATE = createFormGroupState<IProfileSearchFormState>(
  PROFILE_REGISTRATION_SEARCH_FORM_ID,
  {
    profession: null,
    registrationNumber: '',
    firstName: '',
    lastName: '',
    dob: null,
    email: null,
  },
);

export const formStateReducer = createFormStateReducerWithUpdate<IProfileSearchFormState>(
  updateGroup<IProfileSearchFormState>({
    registrationNumber: (registrationNumber, state) =>
      validate<string>([
        requiredWhenProfession(state, true),
        pattern(UNSIGNED_INTEGER_STRING_REGEX),
      ])(registrationNumber),
    email: (email, state) =>
      validate<string>([requiredWhenProfession(state, false), pattern(EMAIL_REGEX)])(email),
    lastName: validate<string>([required, maxLength(250)]),
    firstName: validate<string>([required, maxLength(250)]),
    dob: (dob, state) => validate<string>(hasValidDob(state))(dob),
  }),
);

export function reducer(state = INITIAL_FORM_STATE, action: ProfileSearchFormMessages) {
  switch (action.type) {
    case InitializeProfessionalRegistrationFormMessage.TYPE: {
      const payload = (action as InitializeProfessionalRegistrationFormMessage).payload;
      state = createFormGroupState<IProfileSearchFormState>(PROFILE_REGISTRATION_SEARCH_FORM_ID, {
        ...INITIAL_FORM_STATE.value,
        ...get(payload, 'profileSearchFormState', INITIAL_FORM_STATE.value),
      });
      break;
    }
  }
  return formStateReducer(state, action);
}
