import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

import { IListingIdentifierStatsEntity } from './interfaces';

const namespace = 'ListingIdentifierStats';

const selectId = (listingIdentifierStats: IListingIdentifierStatsEntity) =>
  listingIdentifierStats.listingGroupIdentifier;

const adapter = createEntityAdapter<IListingIdentifierStatsEntity>({ selectId });
const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);

const initialState = adapter.getInitialState({});

const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
);

export { adapter, initialState, paginationAdapter };
