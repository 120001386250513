import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { assign, get, isNil, isNumber, isString, keyBy } from 'lodash-es';
import { SetValueAction } from 'ngrx-forms';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';

import { StateService } from '@locumsnest/core';
import { Query } from '@locumsnest/core/src';
import { ISetValueForResourceMessageConstructor } from '@locumsnest/core/src/lib/adapters/singleton-resource-adapter';
import { Time } from '@locumsnest/core/src/lib/helpers';
import {
  distinctCollectionUntilChangedByValues,
  filterNotNil,
} from '@locumsnest/core/src/lib/ngrx/operators';

import { IStaffingCascadeService } from '../../core/services/interfaces';
import { InitializeExternalStaffingCandidateBidFormMessage } from '../../external-staffing-candidate-bid/+state/form/form.messages';
import { ExternalStaffingProviderTierService } from '../../external-staffing-provider-tier/+state/external-staffing-provider-tier.service';
import { HospitalService } from '../../hospital/+state/hospital.services';
import { IExternalStaffingProviderTierEntity } from '../../interfaces/api/external-staffing-provider-tier-entity';
import {
  IStaffingCascadeEntity,
  IStaffingCascadeEntityStats,
  IStaffingCascadeTierAction,
  IStaffingCascadeWidgetTierAction,
} from '../../interfaces/api/staffing-cascade-entity';
import { JobListingService } from '../../job-listing/+state/job-listing.service';
import { selectJobListingSearchFilterFormState } from '../../job-listing/+state/search-filter-form';
import { PermissionService } from '../../permission/+state/permission.service';
import { InitializeProfessionalRegistrationFormMessage } from '../../profile/+state/profile-search-form/profile-search-form.messages';
import { StaffingCascadeStatusService } from '../../staffing-cascade-status/+state/staffing-cascade-status.service';
import { StaffingCascadeTimeWindowService } from '../../staffing-cascade-time-window/+state/staffing-cascade-time-window.service';
import { InitializeStaffingCascadeFormMessage } from './form/form.messages';
import { FORM_ID } from './form/form.reducer';
import { statsAdapter } from './staffing-cascade.adapter';
import { UpsertMultipleMessage, UpsertOneMessage } from './staffing-cascade.messages';
import { StaffingCascadePersistenceService } from './staffing-cascade.persistence.service';
import {
  selectAllStaffingCascades,
  selectFormState,
  selectNotes,
  selectStaffingCascade,
  selectStaffingCascadeByJobListingIds,
  selectStaffingCascadeByListingId,
  selectStaffingCascadeIdByListingId,
  selectStaffingCascadesOptions,
  selectStaffingCascadeState,
  selectWidgetSubmitConfirmation,
  selectWidgetUiAlertState,
  selectWidgetUiState,
} from './staffing-cascade.selectors';
import { conditionalErrorHandler } from './widget-ui/widget-ui.adapter';
import {
  InitializeConfirmationDialogMessage,
  InitializeReverseConfirmationDialogMessage,
  InitializeWidgetUIMessage,
  ResetConfirmationDialogMessage,
  ResetReverseConfirmationDialogMessage,
} from './widget-ui/widget-ui.messages';

@Injectable({
  providedIn: 'root',
})
export class StaffingCascadeService
  extends StateService<IStaffingCascadeEntity>
  implements IStaffingCascadeService
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly SetValueForStatsMessageClass: ISetValueForResourceMessageConstructor<
    number,
    IStaffingCascadeEntityStats
  > = statsAdapter.getMessages().SetValueForResource;

  protected readonly selectStats = statsAdapter.getSelectors(selectStaffingCascadeState)
    .selectSubResource;

  protected readonly selectStatsDictionary = statsAdapter.getSelectors(selectStaffingCascadeState)
    .selectSubResourceState;

  constructor(
    protected persistenceService: StaffingCascadePersistenceService,
    private staffingCascadeStatusService: StaffingCascadeStatusService,
    private externalStaffingProviderTierService: ExternalStaffingProviderTierService,
    private jobListingService: JobListingService,
    private staffingCascadeTimeWindowService: StaffingCascadeTimeWindowService,
    private permissionService: PermissionService,
    private hospitalService: HospitalService,
    private store: Store,
  ) {
    super();
  }
  loadStats(resourceId: number) {
    return this.persistenceService
      .loadStats([resourceId])
      .pipe(
        map(
          (subResources) =>
            new this.SetValueForStatsMessageClass({ resourceId, subResource: subResources[0] }),
        ),
      );
  }

  loadStatsIfNotExists(resourceId: number) {
    return this.getStats(resourceId).pipe(
      first(),
      mergeMap((stats) => {
        if (isNil(stats)) {
          return this.persistenceService
            .loadStats([resourceId])
            .pipe(
              map(
                (subResource) => new this.SetValueForStatsMessageClass({ resourceId, subResource }),
              ),
            );
        }
        return of();
      }),
    );
  }

  loadBulkStats(ids: number[]) {
    return this.persistenceService.loadBulkStats(ids).pipe(
      map((subResources) =>
        subResources.map(
          (subResource) =>
            new this.SetValueForStatsMessageClass({
              resourceId: subResource.id,
              subResource,
            }),
        ),
      ),
    );
  }

  getOneOrNone(id: number) {
    return this.store.pipe(select(selectStaffingCascade(id)));
  }

  getStaffingCascadeStatusCode(cascadeId: number): Observable<string> {
    return this.getOneOrNone(cascadeId).pipe(
      switchMap((staffingCascade) => {
        if (staffingCascade) {
          return this.staffingCascadeStatusService.getStaffingCascadeStatusCodeById(
            staffingCascade.status,
          );
        }
        return of(null);
      }),
    );
  }

  getStats(resourceId: number) {
    return this.store.pipe(select(this.selectStats(resourceId)));
  }

  getExternalStaffingBookingCount(resourceId: number) {
    return this.getStats(resourceId).pipe(
      map((stats) => get(stats, 'approvedBidCount', 0)),
      distinctUntilChanged(),
    );
  }

  getStatsDictionary() {
    return this.store.pipe(select(this.selectStatsDictionary));
  }

  getSelectedJoblistingCascadeIsActive() {
    return combineLatest([
      this.jobListingService.getSelectedJobListing().pipe(
        switchMap((jobListing) => this.getForListing(jobListing.id)),
        filter((x) => !!x),
        map((cascade) => cascade.tier),
      ),
      this.jobListingService
        .getCurrentListingHospitalId()
        .pipe(
          switchMap((hospitalId) =>
            this.externalStaffingProviderTierService.getCurrentProviderTierForHospital(hospitalId),
          ),
        ),
      this.jobListingService.getSelectedJobListing().pipe(
        switchMap(({ staffingCascade }) =>
          this.getStats(staffingCascade).pipe(
            filter((stats) => !!stats),
            map(({ isActive }) => isActive),
          ),
        ),
      ),
    ]).pipe(
      map(([cascadeTier, providerTier, isActive]) => isActive && cascadeTier >= providerTier),
    );
  }

  getSelectedListingsCascadeIsActive(listingIds: number[]): Observable<boolean[]> {
    return combineLatest(
      listingIds.map((id) =>
        combineLatest([
          this.getForListing(id),
          this.jobListingService
            .getOne(id)
            .pipe(
              switchMap((listing) =>
                this.jobListingService
                  .getCurrentListingHospitalId(listing)
                  .pipe(
                    switchMap((hospitalId) =>
                      this.externalStaffingProviderTierService.getCurrentProviderTierForHospital(
                        hospitalId,
                      ),
                    ),
                  ),
              ),
            ),
          this.jobListingService.getOne(id).pipe(
            switchMap(({ staffingCascade }) =>
              this.getStats(staffingCascade).pipe(
                filter((stats) => !!stats),
                map(({ isActive }) => isActive),
              ),
            ),
          ),
        ]).pipe(
          map(([cascade, providerTier, isActive]) => isActive && cascade.tier >= providerTier),
        ),
      ),
    );
  }

  create(cascade: IStaffingCascadeEntity) {
    return this.persistenceService
      .create(cascade)
      .pipe(map((staffingCascade) => new UpsertOneMessage({ staffingCascade })));
  }

  requestTier(id: string, notes: string, tier: number) {
    return this.persistenceService
      .requestTier(id, notes, tier)
      .pipe(map((staffingCascade) => new UpsertOneMessage({ staffingCascade })));
  }

  cancelTier(staffingCascadeId: number, tierActionId: number) {
    return this.persistenceService
      .cancelTier(staffingCascadeId, tierActionId)
      .pipe(map((staffingCascade) => new UpsertOneMessage({ staffingCascade })));
  }

  cascadeBulkAuthorize(ids: number[]) {
    return this.persistenceService.cascadeBulkAuthorize(ids);
  }

  cascadeBulkReject(ids: number[]) {
    return this.persistenceService.cascadeBulkReject(ids);
  }

  cascadeBulkCascade(ids: number[], notes: string) {
    return this.persistenceService.cascadeBulkCascade(ids, notes);
  }

  cascadeBulkCancel(ids: number[]) {
    return this.persistenceService.cascadeBulkCancel(ids);
  }

  // handling with conditional error handler as best practice
  // for non breaking streams
  handleWidgetError(state) {
    return conditionalErrorHandler()(state);
  }
  getCascadeFormState() {
    return this.store.pipe(select(selectFormState));
  }
  getNotesFromForm() {
    return this.store.pipe(select(selectNotes));
  }

  getWidgetAlertState() {
    return this.store.pipe(select(selectWidgetUiAlertState));
  }

  initializeWidgetDialog() {
    return of(new InitializeConfirmationDialogMessage({}));
  }

  resetWidgetDialog() {
    return merge(
      of(new ResetConfirmationDialogMessage({})),
      of(new SetValueAction(FORM_ID + '.notes', '')),
    );
  }

  initializeReverseWidgetDialog() {
    return of(new InitializeReverseConfirmationDialogMessage({}));
  }

  resetReverseWidgetDialog() {
    return of(new ResetReverseConfirmationDialogMessage({}));
  }

  initializeEmptyWidget() {
    return merge(
      of(new InitializeExternalStaffingCandidateBidFormMessage({})),
      of(new InitializeStaffingCascadeFormMessage({})),
      of(new InitializeWidgetUIMessage({})),
      of(new InitializeProfessionalRegistrationFormMessage({})),
    );
  }

  getWidgetSubmitConfirmationState() {
    return this.store.pipe(select(selectWidgetSubmitConfirmation));
  }

  getWidgetUiState() {
    return this.store.pipe(select(selectWidgetUiState));
  }

  getForListing(id: number): Observable<IStaffingCascadeEntity> {
    return this.store.pipe(select(selectStaffingCascadeByListingId(id)));
  }

  getStaffingCascadesForListingIds(jobListingIds: number[]): Observable<IStaffingCascadeEntity[]> {
    return this.store.pipe(select(selectStaffingCascadeByJobListingIds(jobListingIds)));
  }

  getForCurrentListing() {
    return this.jobListingService
      .getJobListingFormId()
      .pipe(switchMap((id) => this.getForListing(id)));
  }

  getIdForListing(id: number): Observable<number> {
    return this.store.pipe(select(selectStaffingCascadeIdByListingId(id)));
  }
  getAllByStatus(desiredStatus: number) {
    return this.getAll().pipe(
      map((cascades) => cascades.filter(({ status }) => status === desiredStatus)),
    );
  }
  getApprovedCascades() {
    return this.staffingCascadeStatusService
      .getApprovedStatusValue()
      .pipe(mergeMap((val) => this.getAllByStatus(val)));
  }
  getPendingCascades() {
    return this.staffingCascadeStatusService
      .getPendingStatusValue()
      .pipe(mergeMap((val) => this.getAllByStatus(val)));
  }
  getRejectedCascades() {
    return this.staffingCascadeStatusService
      .getRejectedStatusValue()
      .pipe(mergeMap((val) => this.getAllByStatus(val)));
  }
  getPendingListingIds() {
    return this.getPendingCascades().pipe(
      map((cascades) => cascades.map(({ listing }) => listing)),
      distinctCollectionUntilChangedByValues(),
    );
  }
  getRejectedListingIds() {
    return this.getRejectedCascades().pipe(
      map((cascades) => cascades.map(({ listing }) => listing)),
      distinctCollectionUntilChangedByValues(),
    );
  }
  getApprovedListingIds() {
    return this.getApprovedCascades().pipe(
      map((cascades) => cascades.map(({ listing }) => listing)),
      distinctCollectionUntilChangedByValues(),
    );
  }

  getCascadesBySelectedListingIds() {
    return combineLatest([
      this.store.pipe(select(selectJobListingSearchFilterFormState)),
      this.jobListingService.getAllAfterLoading(),
    ]).pipe(
      map(([searchFilterForm, jobListings]) => {
        const selectedJobListingIds = searchFilterForm.controls.selectedJobListings.value;

        return selectedJobListingIds
          .map(
            (selectedJobListingId) =>
              jobListings.find((jobListing) => jobListing.id === selectedJobListingId)
                .staffingCascade,
          )
          .filter((x) => x !== null);
      }),
    );
  }

  loadByJobListingIds(ids: number[]) {
    return this.fetch({
      listing: ids,
    });
  }

  getOneWithJobListing(cascadeId: number) {
    return this.getOneOrNone(cascadeId).pipe(
      filter((x) => !isNil(x)),
      switchMap((staffingCascade) =>
        this.jobListingService
          .getOneWithSubSpecialty(staffingCascade.listing)
          .pipe(map((listing) => ({ ...staffingCascade, listing }))),
      ),
    );
  }

  getAll() {
    return this.store.pipe(select(selectAllStaffingCascades));
  }

  getAllOptions() {
    return this.store.pipe(select(selectStaffingCascadesOptions));
  }

  getCurrentTierActionByJoblistingId(jobListingId: number, lastActionable = false) {
    return this.getForListing(jobListingId).pipe(
      filterNotNil(),
      switchMap((cascade) => this.getLastTierAction(cascade, lastActionable)),
    );
  }

  getLastPendingTierNotesForListing(listingId: number): Observable<Record<number, string>> {
    return this.getForListing(listingId).pipe(
      mergeMap((cascade) => {
        if (get(cascade, ['tierActions', 'length'], 0)) {
          return this.getLastTierAction(cascade);
        }
        return of(null);
      }),
      map((action) => get(action, 'notes', '')),
      distinctUntilChanged(),
      map((notes) => ({
        [listingId]: notes,
      })),
    );
  }

  getLastPendingTierNotesForListingList(listingIds: number[]): Observable<Record<number, string>> {
    if (!listingIds.length) {
      return of({});
    }
    return combineLatest(listingIds.map((id) => this.getLastPendingTierNotesForListing(id))).pipe(
      map((listingNotes) => assign({}, ...listingNotes)),
    );
  }

  getCurrentCascadeLastTierAction(lastActionable = false) {
    return this.getForCurrentListing().pipe(
      switchMap((cascade) => this.getLastTierAction(cascade, lastActionable)),
    );
  }

  satisfiesStaffingCascadeTimeWindow() {
    return this.jobListingService.getJobListingFormId().pipe(
      mergeMap((id) =>
        this.jobListingService.getJobListingById(id).pipe(
          filter((listing) => !!listing),
          mergeMap((jobListing) =>
            this.staffingCascadeTimeWindowService.getValidityForJobListing(jobListing),
          ),
        ),
      ),
    );
  }

  isCascaded(): Observable<boolean> {
    return combineLatest([
      this.getForCurrentListing(),
      this.staffingCascadeStatusService.getCancelledStatusValue(),
    ]).pipe(
      map(
        ([joblisting, cancelledStatus]) =>
          !!(joblisting?.id && joblisting?.status !== cancelledStatus),
      ),
      distinctUntilChanged(),
    );
  }

  hasCascadePermission() {
    return this.permissionService.hasCurrentPermission('add_staffingcascade');
  }

  hasBulkCascadePermission() {
    return combineLatest([
      this.hasCascadePermission(),
      this.hospitalService.getHospitalDetails(),
    ]).pipe(
      map(([cascadePermission, hospital]) =>
        !isNil(hospital) ? hospital.usesAgency && cascadePermission : false,
      ),
    );
  }

  hasAuthorizeCascadePermission() {
    return this.permissionService.hasCurrentPermission('cascade_to_external_staffing_providers');
  }

  hasExternalStaffingPermission() {
    return this.permissionService.hasCurrentPermission('add_externalstaffingcandidate');
  }

  canRequestCascadeOnRejectedOne(): Observable<boolean> {
    return combineLatest([
      this.satisfiesStaffingCascadeTimeWindow(),
      this.hasCascadePermission(),
      this.getCurrentCascadeLastTierAction(),
      this.staffingCascadeStatusService.getRejectedStatusValue(),
    ]).pipe(
      map(
        ([satisfiesTimeWindow, hasCascadePermission, lastTierAction, rejectedStatus]) =>
          satisfiesTimeWindow && hasCascadePermission && lastTierAction?.status === rejectedStatus,
      ),
    );
  }

  canCascade() {
    return combineLatest([
      this.isCascaded(),
      this.satisfiesStaffingCascadeTimeWindow(),
      this.hasCascadePermission(),
      this.externalStaffingProviderTierService.getMaxTierId(),
      this.getCurrentCascadeLastTierAction(),
      this.staffingCascadeStatusService.getApprovedStatusValue(),
      this.jobListingService.formListingHasPendingApplications(),
    ]).pipe(
      map(
        ([
          isCascaded,
          satisfiesTimeWindow,
          hasCascadePermission,
          maxTierId,
          lastTierAction,
          approvedStatusValue,
          hasPendingApplications,
        ]) =>
          !hasPendingApplications &&
          satisfiesTimeWindow &&
          hasCascadePermission &&
          (!isCascaded ||
            (lastTierAction?.tier !== maxTierId && lastTierAction?.status === approvedStatusValue)),
      ),
    );
  }

  fetch(query: Query) {
    //@todo generalize this and move it to base service
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((staffingCascade) => new UpsertOneMessage({ staffingCascade })));
    return this.persistenceService.retrieve(query).pipe(
      map(({ results }) => new UpsertMultipleMessage({ staffingCascades: results })),
      catchError(() => of<Action>()),
    );
  }

  getNextTier(tiers: IExternalStaffingProviderTierEntity[], currentTier: number) {
    const nextOrder =
      get(
        tiers.find((tier) => tier.id === currentTier),
        'order',
      ) + 1;

    if (tiers.find((tier) => tier.order === nextOrder)) {
      return nextOrder;
    }

    return null;
  }

  staffingCascadeTierActions(): Observable<IStaffingCascadeWidgetTierAction[]> {
    return combineLatest([
      this.staffingCascadeStatusService.getCancelledStatusValue(),
      this.staffingCascadeStatusService.getRejectedStatusValue(),
      this.staffingCascadeStatusService.getAll(),
      this.externalStaffingProviderTierService.getAll(),
      this.getForCurrentListing(),
    ]).pipe(
      map(
        ([
          cancelStatusId,
          rejectStatusId,
          staffingCascadeStatuses,
          externalStaffingProviderTiers,
          staffingCascade,
        ]) => {
          const tierActions: IStaffingCascadeWidgetTierAction[] = [];

          if (staffingCascade) {
            if (staffingCascade.status === cancelStatusId) return tierActions;

            for (const tierAction of staffingCascade.tierActions) {
              // cancelled tiers are not added
              if (tierAction.status === cancelStatusId) break;
              // old rejected tiers are not added if staffing cascade is not rejected
              if (tierAction.status === rejectStatusId && staffingCascade.status !== rejectStatusId)
                break;

              const status = staffingCascadeStatuses.find(
                (currentStatus) => get(currentStatus, 'val') === tierAction.status,
              );

              const canRevert =
                (tierAction.status === staffingCascade.status &&
                  tierAction.tier === staffingCascade.tier) ||
                (tierAction.tier === staffingCascade.tier &&
                  staffingCascade.status === rejectStatusId);

              const tAction: IStaffingCascadeWidgetTierAction = {
                ...tierAction,
                status,
                tier: get(
                  externalStaffingProviderTiers.find((tier) => tier.id === tierAction.tier),
                  'order',
                ),
                nextTier: this.getNextTier(externalStaffingProviderTiers, tierAction.tier),
                canRevert,
                authorizedAt: !isNil(tierAction.authorizedAt)
                  ? Time.formatDate(tierAction.authorizedAt)
                  : null,
                createdAt: !isNil(tierAction.createdAt)
                  ? Time.formatDate(tierAction.createdAt)
                  : null,
              };

              tierActions.push(tAction);

              // Add only the first rejected action if status cascade is rejected
              if (
                staffingCascade.status === rejectStatusId &&
                tierAction.tier > staffingCascade.tier
              )
                break;
            }
          }

          return tierActions;
        },
      ),
    );
  }

  private reverseOrderCascadeTiers(
    tiers: IExternalStaffingProviderTierEntity[],
    staffingCascade: IStaffingCascadeEntity,
  ) {
    const tiersDict = keyBy(tiers, 'id');
    if (isNil(staffingCascade)) {
      return [];
    }
    return [...staffingCascade.tierActions].sort((a, b) => {
      if (tiersDict[a.tier].order === tiersDict[b.tier].order) {
        return tiersDict[a.tier].createdAt > tiersDict[b.tier].createdAt ? -1 : 1;
      }
      return tiersDict[a.tier].order > tiersDict[b.tier].order ? -1 : 1;
    });
  }

  private getTierActionsReverseOrderedByTier(cascade: IStaffingCascadeEntity) {
    return this.externalStaffingProviderTierService
      .getAllAfterLoading()
      .pipe(map((tiers) => this.reverseOrderCascadeTiers(tiers, cascade)));
  }

  private getLastTierAction(cascade: IStaffingCascadeEntity, lastActionable = false) {
    return combineLatest([
      this.staffingCascadeStatusService.getCancelledStatusValue(),
      this.staffingCascadeStatusService.getRejectedStatusValue(),
      this.getTierActionsReverseOrderedByTier(cascade),
    ]).pipe(
      map(([cancelStatusId, rejectedStatusId, actions]) => {
        if (!actions.length || cascade.status === cancelStatusId) return null;

        let lastAction: IStaffingCascadeTierAction;
        for (const action of actions) {
          // go to next action if canceled
          if (action.status === cancelStatusId) continue;
          // ** tier actions are in descenting order (tier 3 ,tier 2 ...)
          // if cascade is rejected we get the first tierAction that is at same tier as cascade.
          // Used on reverse to get the action than can be reversed (not the rejected one)
          // only valid if lastActionable = true
          if (
            cascade.status === rejectedStatusId &&
            cascade.tier === action.tier &&
            lastActionable
          ) {
            lastAction = action;
            break;
          }
          // ** tier actions are in descenting order (tier 3 ,tier 2 ...)
          // if cascade is rejected we get the the first reject action found
          // if we want the last actionable we must get it from the
          // above statement (skip if found here)
          if (action.status === rejectedStatusId && cascade.status === rejectedStatusId) {
            if (lastActionable) continue;
            lastAction = action;
            break;
          }
          // get the tierAction matching cascade status (not rejected ones)
          if (action.status === cascade.status) {
            lastAction = action;
            break;
          }

          lastAction = action;
        }

        return lastAction;
      }),
      distinctUntilKeyChanged('id'),
    );
  }
}
