import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy, isNil } from 'lodash-es';

import { IApprovedRateEntity } from '../../interfaces/api/approved-rate-entity';
import { loadingAdapter, paginationAdapter } from './approved-rate.adapter';
import { featureKey, selectAll } from './approved-rate.reducer';
import {
  IApprovedRateEntityState,
  IApprovedRateFeatureState,
  IApprovedRateSharedState,
} from './interfaces';

export const getEntityState = (state: { entityState: IApprovedRateEntityState }) =>
  state.entityState;

export const getByGradeAndSpecialty =
  (gradeId: number, specialtyId: number, nonResidentOnCall: boolean, startTime: Date) =>
  (rates: IApprovedRateEntity[]) =>
    rates.filter(
      (rate) =>
        rate.grade === gradeId &&
        rate.specialties.includes(specialtyId) &&
        rate.onCall === !!nonResidentOnCall &&
        startTime > rate.validFrom &&
        (isNil(rate.validUntil) || startTime < rate.validUntil),
    );

export const getByGradeForSpecialty =
  (specialtyId: number, nonResidentOnCall, startTime: Date) => (rates: IApprovedRateEntity[]) =>
    groupBy(
      rates.filter(
        (rate) =>
          rate.specialties.includes(specialtyId) &&
          rate.onCall === !!nonResidentOnCall &&
          startTime > rate.validFrom &&
          (isNil(rate.validUntil) || startTime < rate.validUntil),
      ),
      (rate) => rate.grade,
    );

export const selectApprovedRateState = createFeatureSelector<
  IApprovedRateFeatureState & IApprovedRateSharedState
>(featureKey);

export const selectApprovedRateEntityState = createSelector(
  selectApprovedRateState,
  getEntityState,
);

export const selectAllApprovedRates = createSelector(selectApprovedRateEntityState, selectAll);

export const selectByGradeAndSpecialty = (
  gradeId,
  specialtyId,
  nonResidentOnCall: boolean,
  startTime: Date,
) =>
  createSelector(
    selectAllApprovedRates,
    getByGradeAndSpecialty(gradeId, specialtyId, nonResidentOnCall, startTime),
  );

export const selectByGradeForSpecialty = (
  specialtyId: number,
  nonResidentOnCall: boolean,
  startTime: Date,
) =>
  createSelector(
    selectAllApprovedRates,
    getByGradeForSpecialty(specialtyId, nonResidentOnCall, startTime),
  );

export const approvedRatePaginationSelectors = paginationAdapter.paginationSelectors(
  selectApprovedRateEntityState,
  loadingAdapter,
  'pagination',
  'entityState',
  false,
);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectApprovedRateState);
