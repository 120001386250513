import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

import { PrefillCostCentreNumberSignal } from '../../../time-sheet/+state/form/form.signals';

export function createJobListingFormSignals<T extends string>(
  actionableFactory: SignalableFactory<T>,
) {
  class InitializeJobListingFormSignal extends actionableFactory.create<
    'Initialize JobListingForm',
    Record<string, unknown>
  >('Initialize JobListingForm') {}

  // class LoadJobListingFormFromExternalListingSignal extends actionableFactory.create<
  //   'Load ExternalListing',
  //   { id: number }
  // >('Load ExternalListing') {}

  class LoadJobListingTemplateSignal extends actionableFactory.create<
    'Load JobListing Template',
    { namespace: string; templateId: number }
  >('Load JobListing Template') {}

  class DeleteJobListingTemplateSignal extends actionableFactory.create<
    'Delete JobListing Template',
    { templateId: number }
  >('Delete JobListing Template') {}

  class ToggleJobListingGradeSignal extends actionableFactory.create<
    'Toggle Job Listing Grade',
    { grade: number; profession: number }
  >('Toggle Job Listing Grade') {}

  class ClearJobListingGradeSignal extends actionableFactory.create<
    'Clear Job Listing Grade',
    { grade: number }
  >('Clear Job Listing Grade') {}
  class ResetGradeJobFragmentsRatesSignal extends actionableFactory.create<
    'Reset Grade JobFragments Rates',
    { grade: number; ceiling: number }
  >('Reset Grade JobFragments Rates') {}

  class ResetCostCentreNumberSignal extends actionableFactory.create<
    'Reset Cost Centre Number',
    Record<string, never>
  >('Reset Cost Centre Number') {}

  class AcceptApprovedRateViolationSignal extends actionableFactory.create<
    'Accept Approved Rate Violation Signal',
    { grade: number; gradeIndex: number }
  >('Accept Approved Rate Violation Signal') {}

  class AddExtraEmailSignal extends actionableFactory.create<
    'Add Extra Email',
    Record<string, unknown>
  >('Add Extra Email') {}

  class DeleteExtraEmailSignal extends actionableFactory.create<
    'Delete Extra Email',
    { emailIndex: number }
  >('Delete Extra Email') {}

  class ClearExtraEmailsSignal extends actionableFactory.create<
    'Clear Extra Emails',
    Record<string, unknown>
  >('Clear Extra Emails') {}

  class RemoveFileSignal extends actionableFactory.create<'Remove File', { fileName: string }>(
    'Remove File',
  ) {}

  class SetDefaultApplicationDeadlineSignal extends actionableFactory.create<
    'Set Default Application Deadline',
    Record<string, unknown>
  >('Set Default Application Deadline') {}

  class ReplaceFlatRateSignal extends actionableFactory.create<
    'Replace Flat Rate',
    { payRateType: number }
  >('Replace Flat Rate') {}

  class UpdateGradeJobFragments extends actionableFactory.create<
    'Update Grade Job Fragments',
    { payRateType: number }
  >('Update Grade Job Fragments') {}

  class ChangeEmploymentPeriodSignal extends actionableFactory.create<
    'Change Employment',
    Record<string, never>
  >('Change Employment') {}

  class AddTimeFragmentSignal extends actionableFactory.create<
    'Add Time Fragment',
    { payRateType?: number }
  >('Add Time Fragment') {}
  class ReplaceTimeBasedRateSignal extends actionableFactory.create<
    'Replace Time Based Rate',
    { timeFragment: number }
  >('Replace Time Based Rate') {}

  class RemoveTimeFragmentSignal extends actionableFactory.create<
    'Remove Time Fragment',
    { timeFragment: number }
  >('Remove Time Fragment') {}

  class SubmitFormSignal extends actionableFactory.create<'Submit Form', Record<string, unknown>>(
    'Submit Form',
  ) {}

  class UpdateFormSignal extends actionableFactory.create<'Update Form', Record<string, unknown>>(
    'Update Form',
  ) {}

  class AddListingNotesSignal extends actionableFactory.create<
    'Add Listing Notes Signal',
    { alert?: boolean }
  >('Add Listing Notes Signal') {}

  class SaveFormAsTemplateSignal extends actionableFactory.create<
    'Save Form as Template',
    { templateName: string }
  >('Save Form as Template') {}

  class UpdateExistingTemplateSignal extends actionableFactory.create<
    'Update Existing Template',
    { templateName: string; templateId: number }
  >('Update Existing Template') {}

  class CascadeSignal extends actionableFactory.create<'Cascade Signal', Record<string, unknown>>(
    'Cascade Signal',
  ) {}

  class RevertCascadeSignal extends actionableFactory.create<
    'Revert Cascade Signal',
    Record<string, never>
  >('Revert Cascade Signal') {}

  class InitializeCascadeWidgetConfirmationSignal extends actionableFactory.create<
    'Initialize Cascade Widget Confirmation',
    Record<string, unknown>
  >('Initialize Cascade Widget Confirmation') {}

  class ResetCascadeWidgetConfirmationSignal extends actionableFactory.create<
    'Reset Cascade Widget Confirmation',
    Record<string, unknown>
  >('Reset Cascade Widget Confirmation') {}

  class InitializeReverseCascadeWidgetConfirmationSignal extends actionableFactory.create<
    'Initialize Reverse Cascade Widget Confirmation',
    Record<string, unknown>
  >('Initialize Reverse Cascade Widget Confirmation') {}

  class ResetReverseCascadeWidgetConfirmationSignal extends actionableFactory.create<
    'Reset Reverse Cascade Widget Confirmation',
    Record<string, unknown>
  >('Reset Reverse Cascade Widget Confirmation') {}

  class PublishJobListingSignal extends actionableFactory.create<
    'Publish Job Listing Signal',
    Record<string, unknown>
  >('Publish Job Listing Signal') {}

  class UnpublishJobListingSignal extends actionableFactory.create<
    'Unpublish Job Listing Signal',
    Record<string, unknown>
  >('Unpublish Job Listing Signal') {}

  class DeclineAllAndUnpublishSignal extends actionableFactory.create<
    'Decline All And Unpublish Signal',
    Record<string, unknown>
  >('Decline All And Unpublish Signal') {}

  class IgnoreWarningsAndMarkInSyncSignal extends actionableFactory.create<
    'Ignore Warnings And Mark In Sync Signal',
    Record<string, unknown>
  >('Ignore Warnings And Mark In Sync Signal') {}

  class SubmitForAuthorizationJobListingSignal extends actionableFactory.create<
    'Submit For Authorization Job Listing Signal',
    Record<string, unknown>
  >('Submit For Authorization Job Listing Signal') {}

  class DetailsChangeSignal extends actionableFactory.create<
    'Details Change Job Listing Signal',
    Record<string, unknown>
  >('Details Change Job Listing Signal') {}

  class CostCentreNumberChangeSignal extends actionableFactory.create<
    'Cost Centre Number Change Job Listing Signal',
    Record<string, unknown>
  >('Cost Centre Number Change Job Listing Signal') {}

  class ReasonForVacancyChangeSignal extends actionableFactory.create<
    'Reason for Vacancy Change Job Listing Signal',
    Record<string, unknown>
  >('Reason for Vacancy Change Job Listing Signal') {}

  class SetCostCentreNumberSignal extends actionableFactory.create<
    'Set Cost Centre Number',
    { namespace: string }
  >('Set Cost Centre Number') {}

  class LockShiftRatesSignal extends actionableFactory.create<
    'Lock Shift Rates Signal',
    Record<string, unknown>
  >('Lock Shift Rates Signal') {}

  class UnlockShiftRatesSignal extends actionableFactory.create<
    'Unlock Shift Rates Signal',
    Record<string, unknown>
  >('Unlock Shift Rates Signal') {}

  class ToggleRecentActivitySignal extends actionableFactory.create<
    'Toggle Recent Activity Signal',
    Record<'value', boolean>
  >('Toggle Recent Activity Signal') {}

  class AddCrossCoveringProfessionSpecialtySignal extends actionableFactory.create<
    'Add Cross Covering Profession Specialty Signal',
    void
  >('Add Cross Covering Profession Specialty Signal') {}
  class RemoveCrossCoveringProfessionSpecialtySignal extends actionableFactory.create<
    'Remove Cross Covering Profession Specialty Signal',
    string
  >('Remove Cross Covering Profession Specialty Signal') {}

  class ClearCrossCoveringProfessionSpecialtiesSignal extends actionableFactory.create<
    'Clear Cross Covering Profession Specialty Signal',
    void
  >('Clear Cross Covering Profession Specialty Signal') {}
  class InitializeRatesSignal extends actionableFactory.create<
    'Initialize Rates',
    { step: number }
  >('Initialize Rates') {}
  return {
    CascadeSignal,
    RevertCascadeSignal,
    InitializeJobListingFormSignal,
    LoadJobListingTemplateSignal,
    DeleteJobListingTemplateSignal,
    ToggleJobListingGradeSignal,
    ClearJobListingGradeSignal,
    ResetGradeJobFragmentsRatesSignal,
    ResetCostCentreNumberSignal,
    AcceptApprovedRateViolationSignal,
    AddCrossCoveringProfessionSpecialtySignal,
    RemoveCrossCoveringProfessionSpecialtySignal,
    ClearCrossCoveringProfessionSpecialtiesSignal,
    AddExtraEmailSignal,
    DeleteExtraEmailSignal,
    RemoveFileSignal,
    SetDefaultApplicationDeadlineSignal,
    ReplaceFlatRateSignal,
    UpdateGradeJobFragments,
    ChangeEmploymentPeriodSignal,
    AddTimeFragmentSignal,
    ReplaceTimeBasedRateSignal,
    RemoveTimeFragmentSignal,
    SubmitFormSignal,
    UpdateFormSignal,
    SaveFormAsTemplateSignal,
    UpdateExistingTemplateSignal,
    SetCostCentreNumberSignal,
    PrefillCostCentreNumberSignal,
    InitializeCascadeWidgetConfirmationSignal,
    ResetCascadeWidgetConfirmationSignal,
    InitializeReverseCascadeWidgetConfirmationSignal,
    ResetReverseCascadeWidgetConfirmationSignal,
    PublishJobListingSignal,
    UnpublishJobListingSignal,
    DeclineAllAndUnpublishSignal,
    IgnoreWarningsAndMarkInSyncSignal,
    SubmitForAuthorizationJobListingSignal,
    DetailsChangeSignal,
    LockShiftRatesSignal,
    UnlockShiftRatesSignal,
    ClearExtraEmailsSignal,
    CostCentreNumberChangeSignal,
    ReasonForVacancyChangeSignal,
    ToggleRecentActivitySignal,
    AddListingNotesSignal,
    InitializeRatesSignal,
  };
}
