/* eslint-disable max-len */
import { get, maxBy, sumBy } from 'lodash-es';
import {
  createFormGroupState,
  FormControlState,
  FormGroupState,
  formStateReducer,
  markAsTouched,
  removeArrayControl,
  SetUserDefinedPropertyAction,
  setValue,
  SetValueAction,
  updateArray,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { pattern, required } from 'ngrx-forms/validation';

import { MarkMultipleAsDirtyAction } from '@locumsnest/core/src';
import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { formatMoney, isNumeric } from '@locumsnest/core/src/lib/helpers/util';
import {
  applyPlural,
  applyVerboseName,
} from '@locumsnest/core/src/lib/ngrx/helpers/ngrx-forms/error-messages.helper';
import { setUserDefinedProperties } from '@locumsnest/core/src/lib/ngrx/helpers/ngrx-forms/util';
import { EMAIL_REGEX } from '@locumsnest/core/src/lib/types/constants';

import { IUploadFileFormState } from '../../../profile/+state/profile.reducer';
import { INITIAL_UPLOAD_FILE_FORM_STATE } from '../../../profile/+state/ui';
import { isExpirationDateInFuture } from '../../../profile/+state/ui/ui.validators';
import { ResourceTypes } from '../../constants';
import { IBidFragmentFormState } from '../interfaces/external-staffing-candidate-bid-form';
import {
  ICandidateDetailsFormState,
  IConfirmRatesFormState,
  IExternalStaffingCandidateMultiBidFormState,
  ISelectedListingsFormState,
} from '../interfaces/external-staffing-candidate-multi-bid-form';
import { DEFAULT_CURRENCY } from './../../../core/constants';
import {
  IBidFragmentWithRates,
  IFee,
} from './../../../interfaces/api/external-staffing-candidate-bid-entity';
import { alertStateAdapter } from './multi-bid-form.adapter';
import {
  ExternalStaffingCandidateMultiBidFormMessages,
  InitializeExternalStaffingCandidateMultiBidFormMessage,
  RemoveDocumentMessage,
  RemoveSelectedJobListingMessage,
  SetMultiBidListingApprovedRates,
  SetSelectedJobListingMessage,
  UpdateMultiBidNextInvalidListingMessage,
} from './multi-bid-form.messages';
import { validateFee } from './multi-bid-form.validators';

export const FORM_ID = 'EXTERNAL_CANDIDATE_MULTI_BID_FORM';
export const CANDIDATE_DETAILS_FORM_ID =
  'EXTERNAL_CANDIDATE_MULTI_BID_FORM.candidateDetailsFormState';
export const BID_FRAGMENTS_CONTROL_ID = `${FORM_ID}.bidFragments`;
export const PROVIDER_FEE_APPROVED_CONTROL_ID = `${FORM_ID}.providerFee.approvedRate`;
export const PROVIDER_FEE_PERCENTAGE_CONTROL_ID = FORM_ID + '.providerFee.feePercentage';
export const PROVIDER_FEE_CONTROL_ID = FORM_ID + '.providerFee.fee';
export const APPROVED_RATES = `${FORM_ID}.providerFee.approvedRate`;
export const FRAGMENT_AGENCY_FEE_FORM_CONTROL_REGEX = new RegExp(
  `^${BID_FRAGMENTS_CONTROL_ID}.\\d+.agencyFee$`.replace(/\./g, '\\.'),
);
const agencyFeeRegex = new RegExp(
  `^${FORM_ID}.selectedListingsFormState.([0-9]|[1-9][0-9]|100).confirmRatesFormState.bidFragments.([0-9]|[1-9][0-9]|100).agencyFee`,
);
const approvedRateRegex = new RegExp(
  `^${FORM_ID}.selectedListingsFormState.([0-9]|[1-9][0-9]|100).confirmRatesFormState.providerFee.approvedRate`,
);
export type State = FormGroupState<IExternalStaffingCandidateMultiBidFormState>;
export const INITIAL_MULTI_BID_FORM_ENTITY_STATE: IExternalStaffingCandidateMultiBidFormState = {
  candidateDetailsFormState: {
    candidateEmail: '',
    documentUploadFileFormState: INITIAL_UPLOAD_FILE_FORM_STATE,
    bankAccountNumber: null,
    sortCode: '',
    nameOnAccount: '',
    documentDeclarationAccepted: null,
    profile: null,
    candidateHasBankDetails: false,
    candidateHasPreferablePaymentMethod: false,
    staffingProvider: null,
  },
  selectedListingsFormState: [
    {
      confirmRatesFormState: {
        providerFee: {
          fee: null,
          feePercentage: '',
          feeCurrency: DEFAULT_CURRENCY,
          excluded: true,
          approvedRate: true,
          resourcetype: ResourceTypes.flat,
        },
        grade: null,
        approvedProposedRate: true,
        directEngagementCandidate: false,
        startTime: null,
        endTime: null,
        staffingCascade: null,
        bidFragments: [],
        flatRate: null,
        flatRateCurrency: null,
      },
      selectedListing: false,
      hasAlert: false,
      listing: null,
    },
  ],
};
export const INITIAL_FORM_STATE = createFormGroupState<IExternalStaffingCandidateMultiBidFormState>(
  FORM_ID,
  INITIAL_MULTI_BID_FORM_ENTITY_STATE,
);
export const LEGACY_APPROVED_FEE_USER_DEFINED_PROPERTY = 'legacyApprovedProviderFee';
export const SUPPORTING_DOCUMENT_DECLARATION_USER_DEFINED_PROPERTY =
  'supportingDocumentDeclaration';
export const HOSPITAL_ID_USER_DEFINED_PROPERTY = 'hospitalId';
export const APPROVED_RATES_USER_DEFINED_PROPERTY = 'approvedRates';
export const PER_FRAGMENT_RATES_APPLICABLE_USER_DEFINED_PROPERTY = 'perFragmentFeesApplicable';
export const DIRECT_ENGAGEMENT_ENFORCED_USER_DEFINED_PROPERTY = 'directEngagementLocked';
export const ACTIVE_CANDIDATE_DOCUMENT_COUNT_USER_DEFINED_PROPERTY = 'activeCandidateDocumentCount';
const getActiveCandidateDocumentCount = (state: FormGroupState<ICandidateDetailsFormState>) =>
  state.userDefinedProperties[ACTIVE_CANDIDATE_DOCUMENT_COUNT_USER_DEFINED_PROPERTY];
const getApprovedRates = (state: FormGroupState<IExternalStaffingCandidateMultiBidFormState>) =>
  state.userDefinedProperties[APPROVED_RATES_USER_DEFINED_PROPERTY];
const getDirectEngagementEnforced = (
  state: FormGroupState<IExternalStaffingCandidateMultiBidFormState>,
) => state.userDefinedProperties[DIRECT_ENGAGEMENT_ENFORCED_USER_DEFINED_PROPERTY];
const getSupportingDocumentDeclaration = (state: FormGroupState<ICandidateDetailsFormState>) =>
  state.userDefinedProperties[SUPPORTING_DOCUMENT_DECLARATION_USER_DEFINED_PROPERTY];
export const getLegacyApprovedFeeUserDefinedProperty = (
  state: FormGroupState<IExternalStaffingCandidateMultiBidFormState>,
) => state.userDefinedProperties[LEGACY_APPROVED_FEE_USER_DEFINED_PROPERTY];

export const getHasAlert = (state: FormGroupState<IConfirmRatesFormState>) =>
  (state.controls.grade.isTouched && state.isInvalid) ||
  (!state.value.providerFee.approvedRate && state.controls.bidFragments.isInvalid);

export const calculateFragmentBasedFee = (
  jobFragments: { agencyFee?: string | null; fromTime: number | Date; toTime: number | Date }[],
) => {
  if (jobFragments.every((f) => isNumeric(f?.agencyFee))) {
    return parseFloat(
      sumBy(jobFragments, (f) => +f.agencyFee * Time.getDuration(f.fromTime, f.toTime)).toFixed(2),
    );
  }
  return null;
};
export const formatProviderFee = (providerFee: IFee) => ({
  ...providerFee,
  fee: formatMoney(providerFee.fee),
});

export const calculateApprovedFlatRate = (bidFragments: { flatRate?: string | number | null }[]) =>
  +(maxBy(bidFragments, (jf) => +(jf.flatRate || 0))?.flatRate || 0) || null;

const updateProviderFee = (
  state: FormGroupState<IExternalStaffingCandidateMultiBidFormState>,
  providerFee: IFee,
  listingFormControlId: string,
) =>
  formStateReducer(
    state,
    new SetValueAction(`${listingFormControlId}.confirmRatesFormState.providerFee`, providerFee),
  );

const getSelectedListingControl = <T>(
  state: FormGroupState<IExternalStaffingCandidateMultiBidFormState>,
  action: SetValueAction<T>,
) => {
  const selectedListingControlId = action.controlId.split('.confirmRatesFormState')[0];
  const selectedListingControl = state.controls.selectedListingsFormState.controls.find(
    (c) => c.id === selectedListingControlId,
  );

  return selectedListingControl;
};

const getSelectedControlBidApprovedRate = <T>(
  state: FormGroupState<IExternalStaffingCandidateMultiBidFormState>,
  action: SetValueAction<T>,
  controlName: string,
) => {
  const controlId = action.controlId.replace(`.${controlName}`, '');
  const selectedListingControl = getSelectedListingControl(state, action);
  const selectedControlBidApprovedRate =
    selectedListingControl.controls.confirmRatesFormState.controls.bidFragments.controls.find(
      (c) => c.id === controlId,
    ).value.approvedRate;

  return selectedControlBidApprovedRate;
};

const setFee = (
  state: FormGroupState<IExternalStaffingCandidateMultiBidFormState>,
  listing: FormGroupState<ISelectedListingsFormState>,
) => {
  const providerFee = {
    feeCurrency: DEFAULT_CURRENCY,
    ...listing.value.confirmRatesFormState.providerFee,
    fee: calculateFragmentBasedFee(listing.value.confirmRatesFormState.bidFragments),
    resourcetype: ResourceTypes.flat as string,
  };

  return updateProviderFee(state, providerFee, listing.id);
};

const updateFormControl = <T>(
  state: FormGroupState<IExternalStaffingCandidateMultiBidFormState>,
  action: SetValueAction<T>,
  controlName: string,
) => {
  const selectedControlBidApprovedRate = getSelectedControlBidApprovedRate(
    state,
    action,
    controlName,
  );

  const ids = [];

  state.controls.selectedListingsFormState.controls.forEach((c) => {
    c.controls.confirmRatesFormState.controls.bidFragments.controls.forEach((cc) => {
      if (cc.value.approvedRate === selectedControlBidApprovedRate) {
        ids.push(cc.id);
      }
    });
  });

  ids.forEach((id) => {
    state = formStateReducer(state, new SetValueAction(`${id}.${controlName}`, action.value));
  });

  return state;
};

const setApprovedFee = (
  providerFee: IFee = null,
  bidFragments: IBidFragmentWithRates[] | IBidFragmentFormState[],
  legacyRateFallback = false,
  legacyApprovedFee: IFee,
  approvedRate = true,
) => {
  const calculatedApprovedRate = calculateFragmentBasedFee(bidFragments);

  if (calculatedApprovedRate) {
    providerFee = {
      feeCurrency: DEFAULT_CURRENCY,
      ...providerFee,
      fee: calculatedApprovedRate,
      approvedRate,
      excluded: true as boolean,
      resourcetype: ResourceTypes.flat as string,
    };
  }
  if (!calculatedApprovedRate && legacyRateFallback && legacyApprovedFee) {
    providerFee = legacyApprovedFee;
  }

  if (providerFee) {
    providerFee = formatProviderFee(providerFee);
  }
  return providerFee;
};
// eslint-disable-next-line max-len
// const updatedArray = updateArrayWithFilter<string>((s, idx) => s.value === '0' && idx === 0, setValue('1'))(array);

const validateDocumentUploadFileForm = updateGroup<IUploadFileFormState>({
  file: validate<string>([required]),
  type: validate<number>([required]),
  expirationDate: (expirationDate, state) =>
    validate<Date>([isExpirationDateInFuture(state)])(expirationDate),
  title: validate<string>([required]),
});
// const validateProviderFee = updateGroup<IFee<string>>({
//   resourcetype: validate<string>([
//     required,
//     pattern(/^(ExternalStaffingProviderFlatFee|ExternalStaffingProviderPercentageFee)$/),
//   ]),
//   feePercentage: (feePercentage, state) =>
//     validate<string>([validateAgencyPercentageFee(state)])(feePercentage),
//   fee: (fee, state) => validate<string>([validateAgencyFlatFee(state)])(fee),
// });

const validateDeclaration = (
  documentDeclarationAccepted: FormControlState<boolean>,
  state: FormGroupState<ICandidateDetailsFormState>,
) =>
  validate<boolean>([
    (value: boolean) => {
      if (getSupportingDocumentDeclaration(state)) {
        return {
          ...applyVerboseName(required(value || null), 'Document declaration acceptance'),
          ...(value
            ? applyPlural(
                applyVerboseName(
                  required(getActiveCandidateDocumentCount(state) || null),
                  'Supporting document(s)  with an expiry date after the job' +
                    ` listing's start date`,
                ),
              )
            : {}),
        };
      }
      return null;
    },
  ])(documentDeclarationAccepted);

const validateCandidateDetailsReducer = updateGroup<ICandidateDetailsFormState>({
  candidateEmail: validate<string>([required, pattern(EMAIL_REGEX)]),
  profile: validate<string>([required]),
  documentUploadFileFormState: validateDocumentUploadFileForm,
  documentDeclarationAccepted: validateDeclaration,
});

const validateSelectedListingsFormState = updateArray<ISelectedListingsFormState>((state) =>
  updateGroup<ISelectedListingsFormState>({
    confirmRatesFormState: (confirmRatesFormState) =>
      updateGroup<IConfirmRatesFormState>(
        {
          bidFragments: updateArray<IBidFragmentFormState>([
            updateGroup<IBidFragmentFormState>({
              agencyFee: validate([
                (agencyFee) =>
                  validateFee(agencyFee, confirmRatesFormState.value.providerFee.approvedRate),
              ]),
              nonResidentCalloutAgencyFee: validate([
                (nonResidentCalloutAgencyFee) =>
                  validateFee(
                    nonResidentCalloutAgencyFee,
                    confirmRatesFormState.value.providerFee.approvedRate,
                  ),
              ]),
              payRate: validate([
                (payRate) => validateFee(payRate, confirmRatesFormState.value.approvedProposedRate),
              ]),
              nonResidentCalloutRate: validate([
                (nonResidentCalloutRate) =>
                  validateFee(
                    nonResidentCalloutRate,
                    confirmRatesFormState.value.approvedProposedRate,
                  ),
              ]),
            }),
          ]),
          grade: validate([required]),
        },
        {
          bidFragments: validate([required]),
        },
      )(confirmRatesFormState),
  })(state),
);

const formValidationReducer = (
  formState: FormGroupState<IExternalStaffingCandidateMultiBidFormState>,
) =>
  updateGroup<IExternalStaffingCandidateMultiBidFormState>({
    candidateDetailsFormState: validateCandidateDetailsReducer,
    selectedListingsFormState: validateSelectedListingsFormState,
  })(formState);

export const alertStateReducer = alertStateAdapter.createReducer();

export function formReducer(
  state = INITIAL_FORM_STATE,
  action:
    | ExternalStaffingCandidateMultiBidFormMessages
    | RemoveDocumentMessage
    | SetValueAction<IUploadFileFormState | string | number | boolean | IBidFragmentWithRates[]>
    | SetUserDefinedPropertyAction
    | MarkMultipleAsDirtyAction,
) {
  switch (action.type) {
    case InitializeExternalStaffingCandidateMultiBidFormMessage.TYPE:
      {
        const { payload } = action as InitializeExternalStaffingCandidateMultiBidFormMessage;

        if (payload.externalStaffingCandidateMultiBidFormState) {
          const formState: Partial<IExternalStaffingCandidateMultiBidFormState> = get(
            action,
            'payload.externalStaffingCandidateMultiBidFormState',
            INITIAL_FORM_STATE.value,
          );
          state = createFormGroupState<IExternalStaffingCandidateMultiBidFormState>(
            FORM_ID,
            formState as IExternalStaffingCandidateMultiBidFormState,
          );
          if (action.payload.userDefinedProperties) {
            state = setUserDefinedProperties(action.payload.userDefinedProperties)(state);
          }
          if (action.payload.candidateDetailsUserDefinedProperties !== undefined) {
            state = {
              ...state,
              controls: {
                ...state.controls,
                candidateDetailsFormState: setUserDefinedProperties(
                  action.payload.candidateDetailsUserDefinedProperties,
                )(state.controls.candidateDetailsFormState),
              },
            };
          }
        } else {
          state = createFormGroupState<IExternalStaffingCandidateMultiBidFormState>(FORM_ID, {
            ...INITIAL_FORM_STATE.value,
          });
        }
      }
      break;
    case RemoveDocumentMessage.TYPE:
      {
        action = new SetValueAction(
          FORM_ID + '.documentUploadFileFormState',
          INITIAL_UPLOAD_FILE_FORM_STATE,
        );
      }
      break;
    case SetSelectedJobListingMessage.TYPE:
      {
        const { payload } = action as InstanceType<typeof SetSelectedJobListingMessage>;

        state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
          selectedListingsFormState: (selectedListingsFormState) =>
            updateArray<ISelectedListingsFormState>((listingFormState) =>
              updateGroup<ISelectedListingsFormState>({
                selectedListing: (val) =>
                  setValue(listingFormState.value.listing.id === payload.listingId && !val.value)(
                    val,
                  ),
              })(listingFormState),
            )(selectedListingsFormState),
        })(state);
      }
      break;
    case UpdateMultiBidNextInvalidListingMessage.TYPE:
      {
        state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
          selectedListingsFormState: (selectedListingsFormState) =>
            updateArray<ISelectedListingsFormState>((listingFormState) => {
              const currentIndex = state.value.selectedListingsFormState.findIndex(
                (formState) => formState.selectedListing,
              );

              let listingWithAlert = state.controls.selectedListingsFormState.controls.find(
                (formState) => getHasAlert(formState.controls.confirmRatesFormState),
              );

              if (currentIndex !== -1) {
                const nextListingWithAlert = state.controls.selectedListingsFormState.controls.find(
                  (formState, index) =>
                    getHasAlert(formState.controls.confirmRatesFormState) && index > currentIndex,
                );

                if (nextListingWithAlert) {
                  listingWithAlert = nextListingWithAlert;
                }
              }

              if (!listingWithAlert) {
                listingFormState = markAsTouched(listingFormState);
                return listingFormState;
              }

              return updateGroup<ISelectedListingsFormState>({
                selectedListing: setValue(
                  listingFormState.value.listing.id === listingWithAlert.value.listing.id,
                ),
              })(listingFormState);
            })(selectedListingsFormState),
        })(state);
      }
      break;
    case RemoveSelectedJobListingMessage.TYPE:
      {
        const { payload } = action as InstanceType<typeof RemoveSelectedJobListingMessage>;
        const listingIndex = state.controls.selectedListingsFormState.value.findIndex(
          (listing) => listing.listing.id === payload.listingId,
        );
        state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
          selectedListingsFormState: removeArrayControl(listingIndex),
        })(state);
      }
      break;

    case SetValueAction.TYPE: {
      const splitAction = action.controlId.split('.');
      if (splitAction[0] === FORM_ID) {
        const hasSelected = state.value.selectedListingsFormState.some((v) => v.selectedListing);
        const controlName = splitAction.at(-1);
        switch (controlName) {
          case 'directEngagementCandidate': {
            if (!hasSelected) {
              state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
                selectedListingsFormState: updateArray<ISelectedListingsFormState>([
                  updateGroup<ISelectedListingsFormState>({
                    confirmRatesFormState: updateGroup<IConfirmRatesFormState>({
                      directEngagementCandidate: setValue(action.value as boolean),
                      grade: setValue(null as number),
                    }),
                  }),
                ]),
              })(state);
            } else {
              state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
                selectedListingsFormState: updateArray<ISelectedListingsFormState>([
                  updateGroup<ISelectedListingsFormState>({
                    confirmRatesFormState: updateGroup<IConfirmRatesFormState>({
                      grade: setValue(null as number),
                    }),
                  }),
                ]),
              })(state);
            }

            break;
          }

          case 'grade': {
            if (!hasSelected) {
              state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
                selectedListingsFormState: updateArray<ISelectedListingsFormState>([
                  updateGroup<ISelectedListingsFormState>({
                    confirmRatesFormState: updateGroup<IConfirmRatesFormState>({
                      grade: (grade) => {
                        grade = markAsTouched(grade);
                        return setValue((action as SetValueAction<number>).value)(grade);
                      },
                    }),
                  }),
                ]),
              })(state);
            }
            break;
          }

          case 'approvedProposedRate': {
            if (!hasSelected) {
              state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
                selectedListingsFormState: updateArray<ISelectedListingsFormState>([
                  updateGroup<ISelectedListingsFormState>({
                    confirmRatesFormState: updateGroup<IConfirmRatesFormState>({
                      approvedProposedRate: setValue(action.value as boolean),
                    }),
                  }),
                ]),
              })(state);
            }
            break;
          }

          case 'approvedRate': {
            if (!hasSelected) {
              state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
                selectedListingsFormState: updateArray<ISelectedListingsFormState>([
                  updateGroup<ISelectedListingsFormState>({
                    confirmRatesFormState: updateGroup<IConfirmRatesFormState>({
                      providerFee: updateGroup<IFee<string>>({
                        approvedRate: setValue(action.value as boolean),
                      }),
                    }),
                  }),
                ]),
              })(state);
            }
            break;
          }

          case 'flatRate': {
            if (!hasSelected) {
              state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
                selectedListingsFormState: updateArray<ISelectedListingsFormState>([
                  updateGroup<ISelectedListingsFormState>({
                    confirmRatesFormState: updateGroup<IConfirmRatesFormState>({
                      flatRate: setValue(action.value as number | string),
                    }),
                  }),
                ]),
              })(state);
            }
            break;
          }

          case 'agencyFee': {
            state = updateFormControl(state, action, controlName);
            const selectedControlBidApprovedRate = getSelectedControlBidApprovedRate(
              state,
              action,
              controlName,
            );

            const ids = [];

            state.controls.selectedListingsFormState.controls.forEach((c) => {
              c.controls.confirmRatesFormState.controls.bidFragments.controls.forEach((cc) => {
                if (
                  cc.value.approvedRate === selectedControlBidApprovedRate &&
                  !c.value.confirmRatesFormState.providerFee.approvedRate
                ) {
                  ids.push(c);
                }
              });
            });

            ids.forEach((id) => {
              state = setFee(state, id);
            });

            break;
          }

          case 'payRate': {
            state = updateFormControl(state, action, controlName);
            break;
          }

          case 'nonResidentCalloutRate': {
            state = updateFormControl(state, action, controlName);
            break;
          }

          // case PROVIDER_FEE_PERCENTAGE_CONTROL_ID:
          // case PROVIDER_FEE_CONTROL_ID: {
          //   if (action.controlId === PROVIDER_FEE_CONTROL_ID) {
          //     action = {
          //       ...action,
          //       value: action.value,
          //     };
          //   }
          //   const setValueAction = action as SetValueAction<string>;
          //   const selectedBidFormState = state.controls.selectedListingsFormState.controls.find(
          //     (formState) => formState.value.selectedListing === true,
          //   );
          //   const providerFee = {
          //     fee: setValueAction.value,
          //     feePercentage: setValueAction.value,
          //     ...selectedBidFormState.value.confirmRatesFormState.providerFee,
          //   };
          // state = updateProviderFee(state, providerFee);
          //   state = markAsDirty(state);
          //   break;
          // }
        }
      }
      break;
    }

    case SetMultiBidListingApprovedRates.TYPE: {
      const { approvedRates, listingFormControlId } = action.payload;

      state = formStateReducer(
        state,
        new SetUserDefinedPropertyAction(
          listingFormControlId,
          PER_FRAGMENT_RATES_APPLICABLE_USER_DEFINED_PROPERTY,
          approvedRates.every((ar) => ar.agencyFee),
        ),
      );

      state = formStateReducer(
        state,
        new SetUserDefinedPropertyAction(
          listingFormControlId,
          APPROVED_RATES_USER_DEFINED_PROPERTY,
          approvedRates,
        ),
      );

      const selected = state.controls.selectedListingsFormState.controls.find(
        (control) => control.id === listingFormControlId,
      );

      if (selected.value.confirmRatesFormState.approvedProposedRate) {
        state = formStateReducer(
          state,
          new SetValueAction(
            `${listingFormControlId}.confirmRatesFormState.bidFragments`,
            approvedRates,
          ),
        );

        state = formStateReducer(
          state,
          new SetValueAction(
            `${listingFormControlId}.confirmRatesFormState.flatRate`,
            calculateApprovedFlatRate(approvedRates),
          ),
        );
      }

      if (selected.value.confirmRatesFormState.providerFee.approvedRate || approvedRates.length) {
        const legacyApprovedFee: IFee = getLegacyApprovedFeeUserDefinedProperty(state);
        const approvedFee = setApprovedFee(
          selected.value.confirmRatesFormState.providerFee,
          approvedRates,
          true,
          legacyApprovedFee,
        );

        state = formStateReducer(
          state,
          new SetValueAction(
            `${listingFormControlId}.confirmRatesFormState.providerFee`,
            approvedFee,
          ),
        );
      }

      break;
    }
  }

  state = formStateReducer(state, action);
  state = formValidationReducer(state);

  // const selectedBidFormState = state.controls.selectedListingsFormState.controls.find(
  //   (formState) => formState.value.selectedListing === true,
  // );

  // post update handling
  switch (action.type) {
    case SetValueAction.TYPE:
      if (agencyFeeRegex.test(action.controlId)) {
        const id = action.controlId.split('.confirmRatesFormState')[0];

        const selectedListing = state.controls.selectedListingsFormState.controls.find(
          (l) => l.id === id,
        );

        state = setFee(state, selectedListing);
      }

      // TODO break into smaller functions
      if (approvedRateRegex.test(action.controlId)) {
        const id = action.controlId.split('.confirmRatesFormState')[0];
        const legacyApprovedFee: IFee = getLegacyApprovedFeeUserDefinedProperty(state);
        const selectedListing = state.controls.selectedListingsFormState.controls.find(
          (l) => l.id === id,
        );

        const approvedRates = action.value
          ? selectedListing.userDefinedProperties['approvedRates']
          : selectedListing.value.confirmRatesFormState.bidFragments;

        const approvedFee = setApprovedFee(
          selectedListing.value.confirmRatesFormState.providerFee,
          approvedRates,
          true,
          legacyApprovedFee,
          action.value as boolean,
        );

        state = formStateReducer(
          state,
          new SetValueAction(`${id}.confirmRatesFormState.providerFee`, approvedFee),
        );
      }
      break;

    case SetMultiBidListingApprovedRates.TYPE: {
      const { approvedRates, listingFormControlId } = action.payload;

      if (!approvedRates.length) {
        state = formStateReducer(
          state,
          new SetValueAction(`${listingFormControlId}.confirmRatesFormState.grade`, null),
        );
      }
    }
  }

  // if (
  //   action.controlId === FORM_ID &&
  //   action.name === DIRECT_ENGAGEMENT_ENFORCED_USER_DEFINED_PROPERTY
  // ) {
  //   state = updateGroup<IExternalStaffingCandidateMultiBidFormState>({
  //     directEngagementCandidate: (controlState) => {
  //       if ((action as SetUserDefinedPropertyAction).value) {
  //         return disable(setValue(controlState, true));
  //       } else {
  //         return enable(controlState);
  //       }
  //     },
  //   })(state);
  //}
  // break;
  // }
  // state = validationReducer(state);

  return {
    ...state,
    alertState: alertStateReducer(
      (
        state as FormGroupState<IExternalStaffingCandidateMultiBidFormState> & {
          alertState: IAlertState;
        }
      ).alertState,
      action,
    ),
  };
}
